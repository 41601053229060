import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// @ts-ignore
import GraphIcon from "@/assets/img/graph-yellow.svg";

/**
 * @param {object} props
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {boolean} props.selected
 * @param {(energyProgram:import("@/_services").EnergyProgram) => any} props.onSelect
 * @param {boolean} [props.hasCustomModel]
 * @returns {JSX.Element}
 */
export const EnergyProgramItem = ({
  energyProgram,
  selected,
  onSelect,
  hasCustomModel
}) => {
  const wrapperClass =
    "border p-2 rounded-md shadow-md w-full cursor-pointer flex justify-between items center";

  const selectedClass = classNames(
    wrapperClass,
    "bg-en-yellow-200 border-en-yellow-500"
  );

  const normalClass = classNames(
    wrapperClass,
    "bg-white border-white border-en-gray-300",
    "hover:bg-en-yellow-200 hover:border-en-yellow-500"
  );

  return (
    <div
      className={selected ? selectedClass : normalClass}
      onClick={() => onSelect(energyProgram)}
    >
      <span>
        <b>{energyProgram.programAdministrator} -</b> {energyProgram.nickname}
      </span>
      {hasCustomModel && <img src={GraphIcon} className="w-8" />}
    </div>
  );
};

EnergyProgramItem.props = {
  energyProgram: PropTypes.object,
  selected: PropTypes.bool,
  onSelect: PropTypes.func
};
