import { Button, Checkbox, SplitButtonDropdown } from "@enpowered/ui";
import classNames from "classnames";
import React from "react";
import { DateTime, Duration } from "luxon";
import { getTimeRangeFromIso } from "@/_utils/datetime.utils";
import {
  getAvailableActions,
  PROGRAM_STATUSES
} from "@/_utils/energy-programs.utils";

/**
 *
 * @param {object} props
 * @param {import("@/_services").ProgramEvent} props.programEvent
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {(programEvents:import("@/_services").ProgramEvent[], status: 'MAYBE' | 'NO_CALL' | 'CALLED' | 'CANCELLED') => void} props.onTransition
 * @param {(programEvent: import("@/_services").ProgramEvent) => any} props.toggleSelectEvent
 * @param {boolean} props.checked
 * @param {boolean} [props.readOnly]
 * @param {(programEvent: import("@/_services").ProgramEvent) => void} props.onEdit
 * @returns {JSX.Element}
 */

export const DREventItem = ({
  programEvent,
  energyProgram,
  onTransition,
  onEdit,
  toggleSelectEvent,
  checked,
  readOnly
}) => {
  const activationWindow = getTimeRangeFromIso(
    programEvent.eventIntervals[0].timestamp,
    DateTime.fromISO(programEvent.eventIntervals[0].timestamp)
      .plus(
        Duration.fromISO(
          /** @type {import("@/_services").DRInterval}*/ (programEvent
            .eventIntervals[0]).duration
        )
      )
      .toISO(),
    energyProgram.timezone
  );

  return (
    <div className="py-2 first:pt-0">
      <div
        className={classNames(
          "border rounded-md  shadow-md gap-4 items-center p-2 grid",
          {
            "border-en-gray-200": !checked,
            "bg-en-yellow-200 border-en-yellow-500": checked
          }
        )}
        style={{
          gridTemplateColumns: "auto 1fr 1fr 1fr auto"
        }}
      >
        <div className="flex items-center">
          <Checkbox
            size="small"
            checked={checked}
            onChange={() =>
              toggleSelectEvent && toggleSelectEvent(programEvent)
            }
            name="select_dr_event"
            disabled={readOnly}
          />
        </div>
        <div className="flex flex-col flex-grow">
          <span className="text-sm">Zone</span>
          <span className="font-bold">{programEvent.drZone.split("-")[1]}</span>
        </div>

        <div className="flex flex-col flex-grow">
          <span className="text-sm">
            {programEvent.status === "CALLED"
              ? "Activation Window"
              : "Time Window"}
          </span>
          <span className="font-bold">{activationWindow}</span>
        </div>

        <div className="flex justify-end">
          {programEvent.status === "MAYBE" && (
            <Button
              size="narrow"
              theme="dark"
              onClick={() => onEdit && onEdit(programEvent)}
            >
              Edit
            </Button>
          )}
        </div>

        {getAvailableActions(programEvent.status).length > 0 && !readOnly ? (
          <div className="flex justify-end items-center">
            <SplitButtonDropdown
              className="font-bold text-sm block"
              confirm
              // @ts-ignore
              onConfirm={status => onTransition([programEvent], status)}
              // @ts-ignore
              getOptionLabel={opt => opt}
              // @ts-ignore
              options={getAvailableActions(programEvent.status)}
              onClick={() => {}}
            />
          </div>
        ) : (
          <div className="flex justify-end items-center">
            <div className="flex-grow-0 justify-end items-center">
              <span className="py-2 px-4 rounded font-bold text-xs whitespace-nowrap text-center bg-en-yellow-200">
                {PROGRAM_STATUSES[programEvent.status]?.text}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
