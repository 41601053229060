import { assert, sleep } from "../../../common";

/**
 *
 * @param {string} scope
 * @param {string} module
 * @returns {() => Promise<{ default: { mount: (containerRef: string|HTMLElement, props: Partial<import("../../../types").MicrofrontendMountProps>) => () => any, unmount: (containerRef: string|HTMLElement) => any } }>}
 */
export function loadComponent(scope, module) {
  return async () => {
    if (typeof window === "undefined") {
      return;
    }
    // Initializes the share scope. This fills it with known provided modules from this build and all remotes
    // @ts-ignore
    // eslint-disable-next-line no-undef
    await __webpack_init_sharing__("default");

    /** @type {{ init: () => Promise<any> }} */
    const container = /** @type {{ [key: string]: any }} */ (window)[scope]; // or get the container somewhere else
    // Initialize the container, it may provide shared modules
    try {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      await container.init(__webpack_share_scopes__.default);
    } catch (err) {
      console.warn(err, { scope, module, container });
    }
    const factory = await Promise.race([
      /** @type {{ [key: string]: any }} */ (window)[scope]?.get(module),
      sleep(300).then(() =>
        Promise.reject(
          new Error(
            `No module ${module} was found in window.${scope} container`
          )
        )
      ),
    ]);
    const Module =
      typeof factory === "function"
        ? factory()
        : Promise.reject(
            new Error(
              `No module ${module} was found in window.${scope} container`
            )
          );
    return Module;
  };
}

/**
 *
 * @param {string} id
 * @param {string} src
 * @returns
 */
export const loadScript = (id, src) => {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[id="${id}"]`)) {
      /** @type {HTMLScriptElement} */
      const script = assert(
        document.querySelector(`script[id="${id}"]`),
        `script must exist: [id=${id}]`
      );
      return resolve(script);
    }
    const script = document.createElement("script");
    script.id = id;
    script.src = src;
    script.type = "text/javascript";
    script.async = true;
    script.onload = () => resolve(script);
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

/**
 *
 * @param {{ entry: string, scope: string, module: string }} mf
 */
export const loadMicrofrontend = async ({ entry, scope, module }) =>
  loadScript(`mf-${scope.toLowerCase()}-entry`, entry)
    .then(() => loadComponent(scope, module)())
    .then((exported) => exported.default)
    .then(({ mount, unmount }) => {
      return {
        mount,
        unmount,
      };
    })
    .catch(
      /** @param {Error} error */
      (error) => Promise.reject(error)
    );
