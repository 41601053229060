import { Button } from "@enpowered/ui";
import PropTypes from "prop-types";
import React from "react";

/**
 *
 * @param {object} props
 * @param {string} props.accountName
 * @param {import("@/_services").Site} props.accountSite
 * @param {(e: MouseEvent) => void} props.onClickEditButton
 * @returns {JSX.Element}
 */
export const SiteInfoPanel = ({
  accountName,
  accountSite,
  onClickEditButton
}) => {
  return (
    <div className="bg-white p-3 mb-4 w-full border-solid border-2 rounded-md border-en-gray-100">
      <p className="text-xl font-bold">Organization</p>
      <p className="pl-2">{accountName}</p>

      <p className="text-xl font-bold mt-4">Site Name</p>
      <p className="pl-2">{accountSite.siteInfo?.name}</p>

      <p className="text-xl font-bold mt-4">Address</p>
      <div className="flex justify-start flex-wrap pl-2">
        <div className="w-full mt-2">
          <p className="text-sm text-en-gray-500">Address</p>
          <p>{accountSite.siteInfo.location?.streetAddress || "--"}</p>
        </div>
        <div className="w-1/2 mt-2">
          <p className="text-sm text-en-gray-500">Postal Code</p>
          <p>{accountSite.siteInfo.location?.postalCode || "--"}</p>
        </div>
        <div className="w-1/2 mt-2">
          <p className="text-sm text-en-gray-500">City</p>
          <p>{accountSite.siteInfo.location?.city || "--"}</p>
        </div>
        <div className="w-1/2 mt-2">
          <p className="text-sm text-en-gray-500">Unit</p>
          <p>{accountSite.siteInfo.location?.unit || "--"}</p>
        </div>
        <div className="w-1/2 mt-2">
          <p className="text-sm text-en-gray-500">State/Region</p>
          <p>{accountSite.siteInfo.location?.state || "--"}</p>
        </div>
        <div className="w-full mt-2">
          <p className="text-sm text-en-gray-500">Country</p>
          <p>{accountSite.siteInfo.location?.country || "--"}</p>
        </div>
      </div>
      <div className="mt-4 flex justify-end">
        <Button theme="dark" onClick={onClickEditButton}>
          Edit
        </Button>
      </div>
    </div>
  );
};

SiteInfoPanel.propTypes = {
  accountName: PropTypes.string.isRequired,
  accountSite: PropTypes.object.isRequired,
  onClickEditButton: PropTypes.func
};
