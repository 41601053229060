import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Layout } from "@/_components/Layout";
import { SearchInput } from "@/_components/SearchInput";
import { PortalUsersBody } from "@/users/components/PortalUsersBody";

/**
 *
 * @returns {JSX.Element}
 */
export const UsersPage = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const { portalUserId } = useParams();

  return (
    <Layout pageTitle="Users">
      <>
        <div className="h-full flex flex-col overflow-y-scroll">
          <div className="w-full flex flex-row justify-between">
            <div className="flex flex-col mt-4 mb-4 ml-2 mr-auto">
              <SearchInput
                placeholder="Search for an user"
                value={searchTerm}
                onChange={setSearchTerm}
              />
            </div>
          </div>
          <div className="max-h-full min-h-0 p-2 flex">
            <PortalUsersBody
              filterTerm={searchTerm}
              portalUserId={portalUserId}
            />
          </div>
        </div>
      </>
    </Layout>
  );
};
