import React from "react";

/**
 * @template {{}} [TProps={}]
 * @template {(Error & { info?: any })} [TError=(Error & { info?: any })]
 * @typedef {JSX.Element | React.FC<{ error: TError, retry?: () => void } & TProps>} MicrofrontendErrorFallback
 */

/**
 * @template {(Error & { info?: any })} TError
 * @extends {React.Component<{ Fallback: MicrofrontendErrorFallback<{}, TError> }>}
 */
export class ErrorBoundary extends React.Component {
  /**
   *
   * @param {{ Fallback: MicrofrontendErrorFallback<{}, TError> }} props
   */
  constructor(props) {
    super(props);
    /** @type {{ error: TError | null, errorInfo: any }} */
    this.state = { error: null, errorInfo: null };
  }

  /**
   * 
   * @param {TError} error 
   * @param {any} errorInfo 
   */
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo && this.state.error) {
      const error = this.state.error;
      const info = this.state.errorInfo;
      if (error) {
        error["info"] = info;
      }
      const Fallback = this.props.Fallback;
      // Error path
      return typeof Fallback === "function" ? (
        <Fallback error={error} />
      ) : (
        Fallback
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
