/**
 *
 * @param {object} props
 * @param {boolean} props.beginAtZero
 * @param {number} props.xMaxTicksLimit
 * @param {number} props.yMaxTicksLimit
 * @param {boolean} props.displayLegend
 * @param {any[]} props.annotations
 * @param {string} props.timezone
 * @returns {import("chart.js").ChartOptions<any>}
 */
export const programManagementPreviewChartOptions = ({
  beginAtZero,
  xMaxTicksLimit,
  yMaxTicksLimit,
  displayLegend,
  annotations,
  timezone
}) => ({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: "time",
      time: {
        displayFormats: {
          hour: "T"
        },
        tooltipFormat: "LLL d, H:mm"
      },
      ticks: {
        color: "black",
        font: {
          size: 16
        },
        maxTicksLimit: xMaxTicksLimit,
        maxRotation: 0,
        major: {
          enabled: true
        }
      },
      grid: {
        borderColor: "black"
      },
      adapters: {
        date: {
          setZone: true,
          zone: timezone
        }
      }
    },
    y: {
      beginAtZero: beginAtZero,
      grid: {
        borderColor: "black"
      },
      title: {
        color: "black",
        font: {
          size: 18
        },
        display: true,
        text: "Usage"
      },
      ticks: {
        color: "black",
        font: {
          size: 16
        },
        maxTicksLimit: yMaxTicksLimit
      }
    }
  },
  layout: {
    padding: 20
  },
  plugins: {
    legend: {
      display: displayLegend,
      position: "bottom",
      onClick: (_click, legendItem, legend) => {
        const index = legend.legendItems.indexOf(legendItem);
        if (legend.chart.isDatasetVisible(index)) {
          legend.chart.hide(index);
        } else {
          legend.chart.show(index);
        }
      },
      labels: {
        padding: 30,
        color: "black",
        font: {
          size: 16
        },
        boxHeight: 6,
        boxWidth: 18,
        usePointStyle: true,
        generateLabels: chart =>
          chart.data.datasets.map((dataset, index) => {
            const isCircle = dataset.label.includes("Realtime");
            return {
              text: dataset.label,
              fillStyle: dataset.backgroundColor,
              strokeStyle: dataset.backgroundColor,
              pointStyle: isCircle ? "circle" : "rect",
              hidden: !chart.isDatasetVisible(index)
            };
          })
      }
    },
    annotation: {
      common: {
        drawTime: "beforeDraw"
      },
      annotations: annotations
    }
  }
});
