import { AutocompleteInput, Button, Select, TextInput } from "@enpowered/ui";
import classNames from "classnames";
import { Duration } from "luxon";
import PropTypes from "prop-types";
import React from "react";

// @ts-ignore
import ClockSVG from "@/assets/img/clock.svg";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Modal from "@/_components/Modal";

const DRValidationSchema = Yup.object({
  drZone: Yup.object()
    .nullable()
    .required("Associated Zone is required.")
});

const CPValidationSchema = Yup.object({
  maximumResponseDuration: Yup.number()
    .moreThan(0, "Maximum response duration is required.")
    .required("Maximum response duration is required."),
  preferredResponseDuration: Yup.number()
    .moreThan(0, "Preferred response duration is required.")
    .required("Preferred response duration is required."),
  responseBudgetCategory: Yup.string().required("Number of Calls is required.")
});

const responseBudgetCategory = ["MINIMUM", "BALANCED", "MAXIMUM"];

/**
 *
 * @param {AddSiteConfigParams} props
 * @returns {JSX.Element}
 */
export const CPSiteConfigForm = ({
  onSubmit,
  onCancel,
  energyProgram,
  siteConfig,
  isLoading,
  error
}) => {
  const cpSiteConfig = /** @type {import("@/_services").CPProgramResponseConfig} */ (siteConfig);

  const fieldClass = classNames(
    "pt-2 sm:mb-0 appearance-none rounded w-full text-gray-700 leading-tight",
    {
      "mb-2": true
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(CPValidationSchema),
    defaultValues: {
      maximumResponseDuration: !cpSiteConfig?.maximumResponseDuration
        ? 0
        : +Duration.fromISO(cpSiteConfig.maximumResponseDuration).toFormat(
            "hh"
          ),
      preferredResponseDuration: !cpSiteConfig?.preferredResponseDuration
        ? 0
        : +Duration.fromISO(cpSiteConfig.preferredResponseDuration).toFormat(
            "hh"
          ),
      responseBudgetCategory: cpSiteConfig?.responseBudgetCategory || ""
    }
  });

  const submitChanges = data => {
    onSubmit({
      programId: energyProgram.programId,
      maximumResponseDuration: Duration.fromISOTime(
        data.maximumResponseDuration.length > 1
          ? data.maximumResponseDuration
          : "0" + data.maximumResponseDuration
      ).toString(),
      preferredResponseDuration: Duration.fromISOTime(
        data.preferredResponseDuration.length > 1
          ? data.preferredResponseDuration
          : "0" + data.preferredResponseDuration
      ).toString(),
      responseBudgetCategory: data.responseBudgetCategory
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="text-xl">
          <b>{!cpSiteConfig ? "Adding" : "Editing"} - </b>{" "}
          {`${energyProgram.system} ${energyProgram.nickname}`}
        </h2>
        <a
          className="flex justify-center items-center rounded-full bg-en-gray-300 w-6 h-6 text-white"
          href={process.env.REACT_APP_RESPONSE_CONFIG_HELP_LINK || ""}
          target="_blank"
          rel="help external noreferrer"
        >
          ?
        </a>
      </div>
      <form onSubmit={handleSubmit(submitChanges)}>
        <div className="mt-4 flex gap-4 w-full">
          <div className="relative w-full">
            <img
              src={ClockSVG}
              style={{ position: "absolute", top: 30, right: 3 }}
            />
            <label className="text-sm whitespace-nowrap">
              Preferred Response Duration
            </label>
            <div>
              <TextInput
                className={fieldClass}
                type="number"
                valid={!errors.preferredResponseDuration}
                {...register("preferredResponseDuration")}
              />
              {!!errors.preferredResponseDuration && (
                <span className="text-xs text-en-red">
                  {errors.preferredResponseDuration.message}
                </span>
              )}
            </div>
          </div>

          <div className="relative w-full">
            <img
              src={ClockSVG}
              style={{ position: "absolute", top: 30, right: 3 }}
            />
            <label className="text-sm whitespace-nowrap">
              Maximum Response Duration
            </label>

            <div>
              <TextInput
                className={fieldClass}
                type="number"
                valid={!errors.maximumResponseDuration}
                {...register("maximumResponseDuration")}
              />
              {!!errors.maximumResponseDuration && (
                <span className="text-xs text-en-red">
                  {errors.maximumResponseDuration.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="w-1/2 pr-2 mt-4">
          <label className="text-sm whitespace-nowrap">Number of Calls</label>
          <div className="py-2">
            <Select
              // @ts-ignore
              style={{ height: 32.38 }}
              className="w-full"
              valid={!errors.responseBudgetCategory}
              {...register("responseBudgetCategory")}
            >
              <option value="" disabled></option>
              {responseBudgetCategory.map(level => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </Select>
            {!!errors.responseBudgetCategory && (
              <span className="text-xs text-en-red">
                {errors.responseBudgetCategory.message}
              </span>
            )}
          </div>
        </div>

        {!!error && (
          <label className="block text-sm py-2 text-center text-red-800">
            {error?.data.description}
          </label>
        )}

        <div className="flex justify-end gap-4 mt-4">
          <Button
            theme="transparent"
            onClick={() => onCancel()}
            disabled={isLoading}
            type="button"
          >
            Cancel
          </Button>
          <Button type="submit" theme="dark" disabled={isLoading}>
            {isLoading ? (
              <span>
                <i className="fa mx-4 fa-circle-notch fa-pulse text-white" />
              </span>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

/**
 *
 * @param {AddSiteConfigParams} props
 * @returns {JSX.Element}
 */
const DRSiteConfigForm = ({
  onSubmit,
  onCancel,
  energyProgram,
  siteConfig,
  isLoading,
  // eslint-disable-next-line no-unused-vars
  error
}) => {
  const drSiteConfig = /** @type {import("@/_services").DRProgramResponseConfig} */ (siteConfig);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(DRValidationSchema),
    defaultValues: {
      drZone: !drSiteConfig
        ? null
        : energyProgram?.drZones.find(
            zone => zone.value === drSiteConfig?.drZone
          ) || null
    }
  });

  const submitChanges = ({ drZone }) => {
    onSubmit &&
      onSubmit({
        programId: energyProgram.programId,
        drZone: drZone.value
      });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="text-xl">
          <b>{!drSiteConfig ? "Adding" : "Editing"} - </b>{" "}
          {`${energyProgram.system} ${energyProgram.nickname}`}
        </h2>
      </div>
      <form onSubmit={handleSubmit(submitChanges)}>
        <div className="w-1/2 pr-2 mt-4">
          <label className="text-sm whitespace-nowrap">Associated Zone</label>
          <div className="py-2">
            <Controller
              name="drZone"
              control={control}
              render={({ field: { name, value } }) => (
                <AutocompleteInput
                  id="drZone"
                  placeholder=""
                  autocompleteData={energyProgram.drZones || []}
                  name={name}
                  value={value}
                  onSelected={option => {
                    setValue("drZone", option);
                  }}
                  valid={true}
                  onBlur={() => {}}
                />
              )}
            />
          </div>
          {!!errors?.drZone && (
            <span className="text-xs text-en-red">
              {
                // @ts-ignore
                errors.drZone.message
              }
            </span>
          )}
        </div>
        <div className="flex justify-end gap-4 mt-4">
          <Button
            theme="transparent"
            onClick={() => onCancel()}
            disabled={isLoading}
            type="button"
          >
            Cancel
          </Button>
          <Button type="submit" theme="dark" disabled={isLoading}>
            {isLoading ? (
              <span>
                <i className="fa mx-4 fa-circle-notch fa-pulse text-white" />
              </span>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

/**
 *
 * @param {AddSiteConfigParams & {isOpen: boolean}} props
 * @returns {JSX.Element}
 */
export const DetailSiteConfigFormModal = ({ isOpen, ...props }) => {
  return (
    <Modal isOpen={isOpen} autoScroll={false} size="lg">
      <div>
        {isOpen && (
          <>
            {props.energyProgram.programType === "DEMAND_RESPONSE" ? (
              <DRSiteConfigForm {...props} />
            ) : props.energyProgram.programType === "COINCIDENT_PEAK" ? (
              <CPSiteConfigForm {...props} />
            ) : null}
          </>
        )}
      </div>
    </Modal>
  );
};

const proptypes = {
  energyProgram: PropTypes.object,
  siteConfig: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  error: PropTypes.object,
  isLoading: PropTypes.bool,
  drZones: PropTypes.array
};

DetailSiteConfigFormModal.propTypes = {
  isOpen: PropTypes.bool,
  ...proptypes
};

DRSiteConfigForm.propTypes = proptypes;
CPSiteConfigForm.propTypes = proptypes;

/**
 * @typedef {object} AddSiteConfigParams
 * @property {import("@/_services").EnergyProgram} props.energyProgram
 * @property {import("@/_services").SiteConfig} props.siteConfig
 * @property {(siteConfig: import("@/_services").SiteConfig) => void} props.onSubmit
 * @property {Function} props.onCancel
 * @property {object} props.error
 * @property {boolean} props.isLoading
 */
