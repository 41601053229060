import { AltLayout, Button, sleep } from "@enpowered/ui";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// @ts-ignore
import notFoundGify from "../assets/img/404-whoops.gif";
import { useLogout } from "@/_hooks";
import { UserContext } from "@/_contexts/user";
import { menuNav } from "@/App.routes";

const NotFound = () => {
  const [showContent, setShowContent] = useState(false);
  const logout = useLogout();
  const { user } = useContext(UserContext);
  const isLoggedIn = !!user;
  const navigate = useNavigate();

  useEffect(() => {
    sleep(2000).then(() => {
      if (!isLoggedIn) {
        navigate("/login");
      } else {
        setShowContent(true);
      }
    });
  }, []);
  return (
    <AltLayout
      pageTitle=""
      logout={logout}
      userName={user?.firstName || null}
      Link={props => <Link {...props} />}
      isUrlActive={() => false}
      items={
        user
          ? menuNav.map(item => ({
              name: item.text,
              url: item.url,
              Icon: item.icon,
              disabled: item.disabled
            }))
          : []
      }
    >
      <div className="spin-when-empty">
        {showContent ? (
          <div className="text-center py-8">
            <img
              src={notFoundGify}
              className="inline-block"
              alt="Not Found Animation"
            />

            <div className="py-8">
              {isLoggedIn ? (
                <Button
                  as={Link}
                  // @ts-ignore
                  to="/"
                >
                  Back to Home
                </Button>
              ) : (
                <Button
                  as={Link}
                  // @ts-ignore
                  to="/login"
                >
                  Sign in
                </Button>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </AltLayout>
  );
};

export default NotFound;
