import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createOrgProfile,
  enumerateOrgProfiles,
  enumerateUserProfiles
} from "../_services";

/**
 *
 * @param {object} params
 * @param {string} [params.orgProfileId]
 * @param {number} [params.itemsPerPage]
 * @returns {import("react-query").UseQueryResult<{items: import("../_services").OrgProfile[], cursor?:string}, object>}
 */
export const useEnumerateOrgProfiles = params =>
  useQuery(["org-profiles", params], () => enumerateOrgProfiles(params));

/**
 *
 * @returns {import("react-query").UseQueryResult<import("../_services").OrgProfile[], object>}
 */
export const useEnumerateAllOrgProfiles = () =>
  useQuery(["org-profiles", "all"], async () => {
    let cursor = null;
    let orgs = [];
    const params = { itemsPerPage: 100 };
    do {
      const response = await enumerateOrgProfiles(cursor ? { cursor } : params);
      orgs = [...orgs, ...response.items];
      cursor = response.cursor;
    } while (cursor);

    return orgs;
  });
/**
 *
 * @param {(orgProfile: import("../_services").OrgProfile) => void} success
 * @returns {import("react-query").UseMutationResult<{orgProfileId: string}, unknown, import("../_services").OrgProfile, object>}
 */
export const useCreateOrgProfile = success => {
  const queryClient = useQueryClient();

  return useMutation(
    /**
     * @param {import("../_services").OrgProfile} orgProfile
     **/
    orgProfile => createOrgProfile(orgProfile),
    {
      /**
       * @param {{orgProfileId: string}} result
       * @param {import("../_services").OrgProfile} orgProfile
       * */
      onSuccess: ({ orgProfileId }, orgProfile) => {
        queryClient.invalidateQueries(["org-profiles"]);

        success &&
          success({
            ...orgProfile,
            orgProfileId
          });
      }
    }
  );
};

/**
 *
 * @param {object} params
 * @param {string} [params.userId]
 * @param {string} [params.orgProfileId]
 * @param {string} [params.permissions]
 * @param {number} [params.itemsPerPage]
 * @returns {import("react-query").UseQueryResult<import("../_services").UserProfile[], object>}
 */
export const useEnumerateUserProfiles = params =>
  useQuery(["user", params], async () => {
    let cursor = null;
    let users = [];

    do {
      const response = await enumerateUserProfiles(
        cursor ? { cursor } : params
      );
      users = [...users, ...response.items];
      cursor = response.cursor;
    } while (cursor);

    return users;
  });
