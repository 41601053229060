import { CognitoUserPool } from "amazon-cognito-identity-js";

export const logout = () => {
  localStorage.clear();
  window.location.href = `${document.querySelector("base")?.href || "/"}login`;
};

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
});

export const getCognitoUser = () => userPool.getCurrentUser();

export const getCognitoSession = () =>
  new Promise((resolve, reject) => {
    if (!getCognitoUser()) {
      reject({ message: "User not logged in" });
    }

    getCognitoUser().getSession((err, session) => {
      if (err) reject(err);
      resolve(session);
    });
  });

export const getIdToken = async () => {
  try {
    const session = await getCognitoSession();
    if (!session) return null;
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.error(error);
    logout();
  }
};

export const getRefreshToken = async () => {
  try {
    const session = await getCognitoSession();
    if (!session) return null;
    return session.getRefreshToken();
  } catch (error) {
    console.error(error);
    logout();
  }
};

export const refreshSession = () =>
  new Promise((resolve, reject) =>
    getRefreshToken()
      .then(regreshToken =>
        getCognitoUser().refreshSession(regreshToken, (err, session) => {
          if (err) reject(err);

          resolve(session);
        })
      )
      .catch(reject)
  );
