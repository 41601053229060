import { getWindow } from "./window.util";

/**
 * The Microfrontend Controller is the engine room for a Microfrontend.
 * It contains the `mount` and `unmount` functions, as well as other variables for managing the microfrontend.
 *
 * Usually, a Microfrontend's controller is located in `window.enpowered[scope][module]`
 * 
 * If no controller exists for the scope<>module pair, then a new placeholder controller will be created in the appropriate location
 *
 * @param {string} scope
 * @param {string} module
 * @returns {MicrofrontendController}
 */
export const getMicrofrontendController = (scope, module) => {
  if (typeof window === "undefined") {
    console.warn(
      "Microfrontend cannot be registered without a global window scope"
    );
    // @ts-ignore
    return { mount: () => {}, unmount: () => {}, instances: 0 };
  }
  const $window = getWindow();
  $window.enpowered = $window.enpowered || {};
  $window.enpowered[scope] = $window.enpowered[scope] || {};
  $window.enpowered[scope][module] = $window.enpowered[scope][module] || {
    mount: () => {
      console.warn(`No ${scope} mount fn exists`);
      return () => {};
    },
    unmount: () => console.warn(`No ${scope} unmount fn exists`),
    instances: 0,
    scope,
    module,
  };
  return $window.enpowered[scope][module];
};

/**
 * @typedef {object} MicrofrontendController
 * @property {(containerRef: string|HTMLElement, props: Partial<import("../../../types").MicrofrontendMountProps>) => () => any} mount
 * @property {(containerRef: string|HTMLElement) => any} unmount
 * @property {number} instances
 * @property {string} scope
 * @property {string} module
 * @property {{ increment: () => void, decrement: () => void, hasZeroInstances: () => boolean }} [tracker]
 */
