import React from "react";

/**
 *
 * @param {object} props
 * @param {string} [props.placeholder]
 * @param {string} [props.value]
 * @param {React.Dispatch<string>} [props.onChange]
 * @returns {JSX.Element}
 */
export const SearchInput = ({ placeholder, value, onChange }) => {
  return (
    <div className="ml-2 relative">
      <span
        className="fas fa-search text-gray-700 mr-1 absolute"
        style={{ left: 12, top: 14 }}
      />
      <input
        className="border-2 border-gray-400 rounded pl-8 pr-1 py-2"
        type="search"
        placeholder={placeholder}
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};
