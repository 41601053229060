import { Button, Modal, Spinner, TextInput } from "@enpowered/ui";
import React, { useState } from "react";

/**
 *
 * @param {object} props
 * @param {() => void} props.onClose
 * @param {boolean} props.isOpen
 * @param {() => void} props.onConfirm
 * @param {boolean} [props.isLoading]
 * @returns
 */
export const ConfirmCancelModal = ({
  onClose,
  isOpen,
  onConfirm,
  isLoading = false
}) => {
  const confirmText = "cancel";
  const [value, setValue] = useState("");

  return (
    <Modal
      isOpen={isOpen}
      title="Cancel Notification?"
      size="md"
      className="[&_.modal-container]:w-[500px]"
      onClose={onClose}
    >
      <div className="flex flex-col gap-4">
        <p>Are you sure you want to cancel this notification?</p>
        <p>
          Type <span className="font-bold">{confirmText}</span> to confirm
        </p>
        <TextInput
          placeholder="cancel"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        <div className="flex justify-end gap-4">
          <Button
            size="narrow"
            theme="dark"
            disabled={value !== confirmText || isLoading}
            onClick={onConfirm}
          >
            {isLoading ? <Spinner /> : "Yes"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
