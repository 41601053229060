import { assert } from "../../common";

/**
 * @returns {Promise<?{ accessToken: string, idToken: string, refreshToken: string }>}
 */
export async function getSecurityTokensForInternalPortal() {
  const apiRootUrl = process.env.REACT_APP_API_ROOT;
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    return {
      accessToken: assert(
        localStorage.getItem("accessToken"),
        "localStorage.accessToken must exist"
      ),
      idToken: assert(
        localStorage.getItem("idToken"),
        "localStorage.idToken must exist"
      ),
      refreshToken: assert(
        localStorage.getItem("refreshToken"),
        "localStorage.refreshToken must exist"
      ),
    };
  }
  const tokens = await retrieveSecurityTokensFromQueryForInternalPortal();
  if (tokens) {
    return tokens;
  }
  const href = location.href;
  location.href = `${apiRootUrl}/auth/oauth/google?redirectUrl=${encodeURIComponent(
    href
  )}`;
  return null;
}

/**
 * @returns {Promise<?{ accessToken: string, idToken: string, refreshToken: string }>}
 */
export async function retrieveSecurityTokensFromQueryForInternalPortal() {
  const payload = new URLSearchParams(location.search).get("payload");
  if (payload) {
    const jsonText = atob(payload);
    /** @type {{ access_token: string, id_token: string, refresh_token: string }} */
    const tokens = JSON.parse(jsonText);
    if (tokens) {
      localStorage.setItem("accessToken", tokens.id_token); // necessary because we authenticate with idToken in the internal-portal
      localStorage.setItem("idToken", tokens.id_token);
      localStorage.setItem("refreshToken", tokens.refresh_token);
      return {
        accessToken: tokens.id_token, // necessary because we authenticate with idToken in the internal-portal
        idToken: tokens.id_token,
        refreshToken: tokens.refresh_token,
      };
    }
  }
  return null;
}
