import classNames from "classnames";
import React from "react";

/**
 *
 * @param {object} props
 * @param {string} props.text
 * @param {boolean} [props.error]
 * @returns {JSX.Element}
 */
export const DeliveryStatusChip = ({ text, error = false }) => {
  return (
    <div
      className={classNames(
        "text-white font-normal rounded-2xl text-sm px-4 py-2",
        {
          "bg-red-600": error,
          "bg-green-600": !error
        }
      )}
    >
      {text}
    </div>
  );
};
