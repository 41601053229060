import { Spinner } from "@enpowered/ui";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import PropTypes from "prop-types";
import React, { useMemo, useRef, useState } from "react";

import { IconButton } from "@/_components/IconButton";
import { useEnumerateRecipientProgramNotifications } from "@/_hooks";

import { NotificationModal } from "./NotificationModal";

/**
 * @param {object} props
 * @param {import("@/_services").ProgramNotification} props.notification
 * @returns {JSX.Element}
 */

export const NotificationRecipientsList = ({ notification }) => {
  const tableContainerRef = useRef(null);
  const [notificationModal, setNotificationModal] = useState(
    /** @type {{title: string; content: string; destination: string; status: string; message: string;}} */ (null)
  );

  /** @type {import("@tanstack/react-table").ColumnDef<any, any>[]} */
  const columns = useMemo(
    () => [
      {
        header: "Name",
        cell: ({ row }) => row.original.recipient?.name || "--"
      },
      {
        header: "Email",
        cell: ({ row }) => row.original.recipient?.email || "--",
        size: 350
      },
      {
        header: "Phone Number",
        cell: ({ row }) => row.original.recipient?.phoneNumber || "--"
      },
      {
        header: "Email Status",
        cell: ({ row }) => (
          <div className="w-full flex items-center gap-2">
            <span className="text-sm">
              {row.original.deliveryStatus?.email}
            </span>
            {row.original.messages?.email && (
              <IconButton
                icon="fa-eye"
                onClick={() =>
                  setNotificationModal({
                    destination: row.original.recipient?.email,
                    title: row.original.messages.email.subject,
                    content: row.original.messages.email.content,
                    status: row.original.deliveryStatus?.email,
                    message: row.original.deliveryStatus?.emailStatusDescription
                  })
                }
              />
            )}
          </div>
        )
      },
      {
        header: "SMS Status",
        cell: ({ row }) => (
          <div className="w-full flex items-center gap-4">
            <span className="text-sm">{row.original.deliveryStatus?.sms}</span>
            {row.original.messages?.sms && (
              <IconButton
                icon="fa-eye"
                onClick={() =>
                  setNotificationModal({
                    title: "SMS Notification",
                    destination: row.original.recipient?.phoneNumber,
                    content: row.original.messages.sms.content,
                    status: row.original.deliveryStatus?.sms,
                    message: null
                  })
                }
              />
            )}
          </div>
        )
      }
    ],
    []
  );

  const {
    data: recipientProgramNotifications = [],
    isLoading
  } = useEnumerateRecipientProgramNotifications({
    notificationId: notification.programNotificationId,
    itemsPerPage: 100
  });

  const table = useReactTable({
    data: recipientProgramNotifications,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    debugTable: false
  });

  return (
    <>
      {isLoading && (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      )}

      <div className="relative flex items-center justify-center w-full h-full first:mt-0 last:mb-0">
        {!isLoading && !recipientProgramNotifications.length && (
          <p>No data found</p>
        )}
        {!isLoading && !!recipientProgramNotifications.length && (
          <div className="w-full h-full absolute p-4" ref={tableContainerRef}>
            <table
              className="flex flex-col pr-4 overflow-auto h-full min-h-full"
              // style={{ maxHeight: "calc(100vh - 150px)" }}
            >
              <thead className="grid sticky top-0 z-10">
                {table.getHeaderGroups().map(headerGroup => (
                  <tr
                    key={headerGroup.id}
                    className="flex w-full bg-en-gray-800 text-white"
                  >
                    {headerGroup.headers.map(header => {
                      return (
                        <th
                          key={header.id}
                          className="flex w-full p-2"
                          style={{
                            width: header.getSize()
                          }}
                        >
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : "",
                              onClick: header.column.getToggleSortingHandler()
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: " 🔼",
                              desc: " 🔽"
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr
                    key={row.id}
                    className="flex items-center border-b hover:bg-en-yellow-300"
                  >
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td
                          key={cell.id}
                          className="flex w-full p-2 shrink-0"
                          style={{
                            width: cell.column.getSize()
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {notificationModal && (
        <NotificationModal
          notification={notificationModal}
          isOpen={!!notificationModal}
          onClose={() => setNotificationModal(null)}
        />
      )}
    </>
  );
};

NotificationRecipientsList.props = {
  notification: PropTypes.object
};
