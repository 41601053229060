import { IconButton } from "./IconButton";
// import "./EditableField.css";

import { TextInput } from "@enpowered/ui";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

/**
 *
 * @param {object} props
 * @param {string} props.value
 * @param {string} props.placeholder
 * @param {string | JSX.Element} [props.postfix]
 * @param {(value: string) => any} props.onEditComplete
 * @param {boolean} [props.useLocale]
 * @param {any} [props.className]
 * @param {(props: any ) => JSX.Element} [props.Label]
 * @param {import("@enpowered/ui").TextInput} [props.TextInput]
 * @param {any} [props.children]
 * @param {any} [props.showEditIcon]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.readOnly]
 * @returns {JSX.Element}
 */
export const EditableField = ({
  value,
  placeholder,
  postfix,
  onEditComplete,
  useLocale,
  className,
  children,
  showEditIcon,
  Label,
  TextInput,
  disabled,
  readOnly
}) => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const editableFieldInput = useRef(null);
  const componentRef = useRef(null);

  // If the value prop changes we need to update the state to match
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    // Cancel edit if the user clicks outside the component
    const handleClickOutside = e => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setInputValue(value);
        setIsInEditMode(false);
      }
    };

    if (isInEditMode) {
      editableFieldInput?.current?.focus && editableFieldInput.current.focus();
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      if (isInEditMode) {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, [isInEditMode]);

  const onEditClicked = () => {
    if (!readOnly) setIsInEditMode(true);
  };

  const onSubmitClicked = () => {
    if (editableFieldInput?.current?.value.length === 0) {
      setInputValue(value);
    }
    setIsInEditMode(false);
    onEditComplete && onEditComplete(editableFieldInput?.current?.value);
  };

  const onChange = event => {
    setInputValue(event.target.value);
  };

  const onKeyDown = event => {
    switch (event.keyCode) {
      case 13:
        onSubmitClicked();
        break;
      case 27:
        setInputValue(value);
        setIsInEditMode(false);
        break;
    }
  };

  const renderInput = () => {
    if (children) {
      const input = React.cloneElement(children, {
        ref: editableFieldInput,
        onChange,
        onKeyDown,
        value: inputValue
      });

      return (
        <>
          {input}
          {postfix || ""}
          {showEditIcon && (
            <IconButton icon={"fa-check-square"} onClick={onSubmitClicked} />
          )}
        </>
      );
    } else {
      return (
        <div
          className="grid items-center border border-en-yellow-700 gap-4 rounded-md"
          style={{ gridTemplateColumns: "1fr auto" }}
        >
          <TextInput
            ref={editableFieldInput}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            defaultValue={inputValue}
          />
          <div
            className="font-bold text-xs flex justify-end cursor-pointer p-2 items-center"
            onClick={onSubmitClicked}
          >
            <IconButton
              className="bg-en-yellow-700 text-white flex justify-center items-center rounded-md p-2"
              icon="fa-check"
              onClick={() => {
                setIsInEditMode(false);
                onEditComplete &&
                  onEditComplete(editableFieldInput?.current?.value);
              }}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className={`${className}`} ref={componentRef}>
      {isInEditMode ? (
        renderInput()
      ) : (
        <>
          <Label
            value={inputValue}
            onClick={onEditClicked}
            useLocale={useLocale}
            postfix={postfix}
            disabled={disabled}
          />
          {showEditIcon && (
            <div
              className="font-bold text-xs cursor-pointer"
              onClick={() => !disabled && onEditClicked && onEditClicked()}
            >
              <IconButton icon="fa-pen" value="Edit" onClick={() => {}} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

EditableField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  postfix: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onEditComplete: PropTypes.func,
  useLocale: PropTypes.bool,
  className: PropTypes.string,
  TextInput: PropTypes.any,
  Label: PropTypes.func,
  children: PropTypes.element,
  showEditandSubmitIcons: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
};

EditableField.defaultProps = {
  showEditandSubmitIcons: true,
  TextInput: React.forwardRef(function CustomInput(props, ref) {
    return (
      <TextInput
        {...props}
        ref={ref}
        // className={"w-24"}
        Input={React.forwardRef(function Input(props2, ref) {
          return (
            <input
              ref={ref}
              {...props2}
              className="border-r border-en-yellow-700 w-full px-2 outline-none rounded-l-md"
            />
          );
        })}
      />
    );
  }),
  Label: ({ value, useLocale, disabled, onClick, postfix }) => (
    <span onClick={() => !disabled && onClick && onClick()}>{`${
      useLocale
        ? // @ts-ignore
          isNaN(value)
          ? value.toLocaleString()
          : parseInt(value).toLocaleString()
        : value
    } ${postfix || ""}`}</span>
  )
};

// React.forwardRef(function CustomInput(({ reference, placeholder, onChange, onKeyDown, value }, ref) {
//   )}))
