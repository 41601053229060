import { useEnumerateOrganizationProgramNotifications } from "@/_hooks";
import { RecipientsTable } from "@/delivery-reports/components/RecipientsTable";
import { Button, Spinner } from "@enpowered/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { DateTime, Duration } from "luxon";
import React, { Fragment, useMemo, useState } from "react";

const RESPONSE_ACTION = {
  CP_CONFIDENCE_ADEQUATE: "CP Confidence Adequate",
  CP_CONFIDENCE_INADEQUATE: "CP Confidence Inadequate",
  CP_CANCELLED: "CP Cancelled",
  NO_CALL: "No Call",
  DR: "DR",
  DR_TEST: "DR Test",
  DR_TEST_CANCELLED: "DR Test Cancelled",
  STANDBY: "Standby"
};

/**
 *
 * @param {object} props
 * @param {string} props.notificationId
 * @param {Partial<import("@/delivery-reports/DeliveryReportsPage").DeliveryReportsFilter>} props.filters
 * @returns {JSX.Element}
 */
export const OrganizationsTable = ({ notificationId, filters }) => {
  const [sorting, setSorting] = useState([]);
  const {
    data: organizations = [],
    isLoading
  } = useEnumerateOrganizationProgramNotifications({
    notificationId,
    status: filters?.organizations?.status || undefined,
    rpnStatus: filters?.recipients?.status || undefined,
    orgName: filters?.organizations?.name || undefined,
    email: filters?.recipients?.email || undefined,
    phoneNumber: filters?.recipients?.phone || undefined,
    name: filters?.recipients?.name || undefined
  });

  const columns = useMemo(
    /**
     * @returns {import("@tanstack/react-table").ColumnDef<import("@/_services").OrganizationProgramNotification, any>[]}
     */
    () => [
      {
        header: "",
        id: "show-recipients-icon",
        size: 20,
        cell: ({ row }) => (
          <FontAwesomeIcon
            icon={row.getIsExpanded() ? "caret-down" : "caret-right"}
            onClick={row.getToggleExpandedHandler()}
          />
        )
      },
      {
        id: "organization-name",
        header: "Organization",
        cell: ({ row }) => row.original.organizationName
      },
      {
        header: "Status",
        cell: ({ row }) =>
          row.original.receivedRecipient ? "Notified" : "Unnotified",
        size: 100
      },
      {
        header: "Recipients",
        cell: ({ row }) =>
          `${row.original.receivedRecipient}/${row.original.totalRecipient}`,
        size: 100
      },
      {
        header: "Sites",
        cell: ({ row }) => {
          const siteConfig = row.original.siteConfig;
          const siteName = row.original.siteName;
          // @ts-ignore
          const isCP = !!siteConfig.responseBudgetCategory;

          const configText = isCP
            ? // @ts-ignore
              `${siteConfig.responseBudgetCategory} - P${Duration.fromISO(
                // @ts-ignore
                siteConfig.preferredResponseDuration
              ).toFormat("h")} - M${Duration.fromISO(
                // @ts-ignore
                siteConfig.maximumResponseDuration
              ).toFormat("h")}`
            : // @ts-ignore
              siteConfig.drZone;

          return (
            <div className="flex flex-col">
              <span className="font-semibold">{configText}</span>
              <span className="text-sm ">{siteName}</span>
            </div>
          );
        },
        size: 300
      },
      {
        header: "Site Call",
        cell: ({ row }) => {
          const siteCall = row.original.siteCall;
          if (!siteCall) return null;
          const from = DateTime.fromISO(siteCall.responseWindowFrom).toFormat(
            "hh:mm a"
          );
          const to = DateTime.fromISO(siteCall.responseWindowUntil).toFormat(
            "hh:mm a"
          );

          return (
            <div className="flex flex-col">
              <span className="font-semibold">{`${from} to ${to}`}</span>
              <span>{`${siteCall.responseAction} - ${
                RESPONSE_ACTION[siteCall.responseMode]
              }`}</span>
            </div>
          );
        },
        size: 300
      },
      {
        header: "",
        id: "show-recipients-labels",
        size: 200,
        cell: ({ row }) => (
          <Button
            className="w-full"
            theme="dark"
            size="narrow"
            onClick={row.getToggleExpandedHandler()}
          >
            {row.getIsExpanded() ? "Hide Recipients" : "Show Recipients"}
          </Button>
        )
      }
    ],
    []
  );

  const table = useReactTable({
    data: organizations,
    columns,
    manualSorting: true,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting
    }
  });

  // const tableContainerRef = useRef(null);

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center p-4">
        <Spinner />
      </div>
    );
  }

  return (
    <table
      className="flex flex-col"
      // style={{ maxHeight: "calc(100vh - 150px)" }}
    >
      <thead className="grid sticky top-[54px] z-[29]">
        {table.getHeaderGroups().map(headerGroup => (
          <tr
            key={headerGroup.id}
            className="flex w-full bg-en-gray-800 text-white"
          >
            {headerGroup.headers.map(header => {
              return (
                <th
                  key={header.id}
                  className="flex w-full p-2"
                  style={{
                    width: header.getSize()
                  }}
                >
                  <div
                    {...{
                      className: header.column.getCanSort()
                        ? "cursor-pointer select-none"
                        : "",
                      onClick: header.column.getToggleSortingHandler()
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: " 🔼",
                      desc: " 🔽"
                    }[header.column.getIsSorted()] ?? null}
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <Fragment key={row.id}>
            <tr className="flex items-center border-b hover:bg-en-yellow-300">
              {row.getVisibleCells().map(cell => {
                return (
                  <td
                    key={cell.id}
                    className="flex w-full p-2 shrink-0"
                    style={{
                      width: cell.column.getSize()
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
            {row.getIsExpanded() && (
              <tr className="w-full bg-en-gray-100 flex">
                <td className="w-full" colSpan={row.getVisibleCells().length}>
                  <div
                    key={`${notificationId}-${row.original.organizationId}`}
                    className="w-full p-4"
                  >
                    <RecipientsTable
                      filters={filters}
                      organizationId={row.original.organizationId}
                      programNotificationId={notificationId}
                    />
                  </div>
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};
