import React, { useEffect, useState } from "react";
import { DropdownButton, PlusCircle } from "@enpowered/ui";
import { DateTime } from "luxon";
import { CPEventForm } from "./components/CPEventForm";
import { EventGroup } from "./components/EventGroup";
import { DREvents } from "./components/DREvents";
import {
  useAssertMultipleProgramEvents,
  useAssertProgramEvent,
  useEnumerateProgramEvents,
  useQueryEnergyPrograms
} from "@/_hooks";
import { Layout } from "@/_components/Layout";
import { NavLink } from "react-router-dom";

const ACTION = {
  NONE: 0,
  ADD_PROGRAM_EVENT: 1,
  EDIT_PROGRAM_EVENT: 2
};

/**
 * @returns {JSX.Element}
 */
export const EventsPage = () => {
  const [openGroup, setOpenGroup] = useState([]);
  const [checkedProgramEvents, setCheckedProgramEvents] = useState(
    /** @type {import("@/_services").ProgramEvent[]}*/ ([])
  );
  const [selectedProgramEvents, setSelectedProgramEvents] = useState(
    /** @type {import("@/_services").ProgramEvent[]}*/ ([])
  );
  const [selectedEnergyProgram, setSelectedEnergyProgram] = useState(null);
  const [canNotify, setCanNotify] = useState(false);
  const [currentAction, setCurrentAction] = useState(ACTION.NONE);

  // Get active energy programs
  const {
    data: activeEnergyProgramsData = { items: [] },
    isLoading: isActiveEnergyProgramsLoading
  } = useQueryEnergyPrograms({ isActive: true, itemsPerPage: 30 });

  const { items: activeEnergyPrograms = [] } = activeEnergyProgramsData;
  /** @param {string} energyProgramId*/
  const getEnergyProgramById = energyProgramId =>
    activeEnergyPrograms.find(({ programId }) => programId === energyProgramId);

  // Assert program event
  const {
    mutate: assertProgramEvent,
    isLoading: isAssertProgramEventLoading,
    error: assertProgramEventError
  } = useAssertProgramEvent(programEvent => {
    setSelectedProgramEvents([programEvent]);
    setCurrentAction(ACTION.EDIT_PROGRAM_EVENT);
    setCheckedProgramEvents([]);
  });

  // Assert multiple program events
  const {
    mutate: assertMultipleProgramEvents
    // isLoading: isAssertMultipleProgramEventsLoading,
    // error: assertMultipleProgramEventsError
  } = useAssertMultipleProgramEvents(newProgramEvents => {
    const date = DateTime.fromISO(
      newProgramEvents[0].eventIntervals[0].timestamp
    )
      .startOf("day")
      .toMillis();

    const ids = newProgramEvents.map(({ programEventId }) => programEventId);
    const selected = programEvents.filter(
      ({ programEventId, drZone, eventIntervals: [{ timestamp }] }) =>
        !!drZone &&
        date ===
          DateTime.fromISO(timestamp)
            .startOf("day")
            .toMillis() &&
        !ids.includes(programEventId)
    );
    setSelectedProgramEvents([...selected, ...newProgramEvents]);
    setCurrentAction(ACTION.EDIT_PROGRAM_EVENT);
  });

  // Get existing program events
  const {
    data: programEvents = [],
    isLoading: isProgramEventsLoading
  } = useEnumerateProgramEvents({
    from: DateTime.now().toFormat("yyyy-LL-dd")
  });

  const onToggleGroup = programId => {
    if (openGroup.indexOf(programId) === -1)
      setOpenGroup([...openGroup, programId]);
    else setOpenGroup(openGroup.filter(id => id !== programId));
  };

  /** @param {import("@/_services").ProgramEvent[]} programEvents */
  const onSelectProgramEvent = programEvents => {
    const energyProgram = getEnergyProgramById(
      programEvents[0].energyProgramId
    );

    setSelectedProgramEvents(programEvents);
    setSelectedEnergyProgram(energyProgram);
    if (energyProgram.programType === "DEMAND_RESPONSE") {
      setCheckedProgramEvents([]);
    }

    setCurrentAction(ACTION.EDIT_PROGRAM_EVENT);
  };

  /**
   *
   * @param {import("@/_services").ProgramEvent[]} programEvents
   * @param {boolean} checked
   */
  const onCheckProgramEvents = (programEvents, checked) => {
    const energyProgram = getEnergyProgramById(
      programEvents[0].energyProgramId
    );
    if (checkedProgramEvents.length === 0) {
      setSelectedProgramEvents(programEvents);
      setSelectedEnergyProgram(energyProgram);
      setCurrentAction(ACTION.EDIT_PROGRAM_EVENT);
    }

    if (checked)
      setCheckedProgramEvents(
        [...checkedProgramEvents, ...programEvents].sort((a, b) => {
          const timeA = DateTime.fromISO(
            a.eventIntervals[0].timestamp
          ).toMillis();
          const timeB = DateTime.fromISO(
            b.eventIntervals[0].timestamp
          ).toMillis();
          return timeA - timeB;
        })
      );
    else
      setCheckedProgramEvents(
        checkedProgramEvents.filter(
          e =>
            !programEvents
              .map(({ programEventId }) => programEventId)
              .includes(e.programEventId)
        )
      );
  };

  useEffect(() => {
    if (checkedProgramEvents.length === 0) {
      setCanNotify(false);
      return;
    }

    setCanNotify(
      (checkedProgramEvents.length > 1 &&
        checkedProgramEvents.every(
          event =>
            event?.energyProgramId ===
              checkedProgramEvents[0]?.energyProgramId &&
            event?.status === "MAYBE"
        )) ||
        checkedProgramEvents.length === 1
    );
  }, [checkedProgramEvents, currentAction]);

  const isLoading = isActiveEnergyProgramsLoading || isProgramEventsLoading;

  return (
    <Layout pageTitle="Events">
      <div className="w-full h-full px-20 py-4">
        <div className="flex justify-between items-center">
          <h1 className="font-bold text-3xl">Events</h1>
          <DropdownButton
            className="w-[12rem]"
            options={activeEnergyPrograms.map(energyProgram => ({
              id: energyProgram.programId,
              label: `${energyProgram.programAdministrator} - ${energyProgram.nickname}`,
              energyProgram
            }))}
            onChange={
              // @ts-ignore
              ({ energyProgram }) => {
                setCurrentAction(ACTION.ADD_PROGRAM_EVENT);
                setSelectedEnergyProgram(energyProgram);
                setSelectedProgramEvents([]);
              }
            }
            title="Add Event"
            getOptionLabel={opt => opt.label}
            icon={<PlusCircle />}
          />
        </div>
        {isLoading ? (
          <div className="spin-when-empty"></div>
        ) : (
          <div
            className="bg-white flex justify-start items-start mt-4 shadow-md rounded"
            style={{ height: "calc(100% - 5rem)" }}
          >
            <div
              className="h-full flex-shrink-0 p-4 flex gap-4 flex-col border-en-gray-100 border-r-2 overflow-y-auto max-h-full"
              style={{ width: 500 }}
            >
              {activeEnergyPrograms &&
                activeEnergyPrograms.map(energyProgram => (
                  <EventGroup
                    key={energyProgram.programId}
                    energyProgram={energyProgram}
                    programEvents={programEvents.filter(
                      event => event.energyProgramId === energyProgram.programId
                    )}
                    onToggle={onToggleGroup}
                    isOpen={
                      !!openGroup.find(id => id === energyProgram.programId)
                    }
                    onSelectProgramEvent={onSelectProgramEvent}
                    checkedProgramEvents={checkedProgramEvents}
                    onCheckProgramEvents={onCheckProgramEvents}
                    selectedProgramEvents={selectedProgramEvents}
                  />
                ))}
            </div>
            <div className="flex-grow flex flex-col h-full max-h-full">
              {currentAction === ACTION.NONE && (
                <>
                  {!programEvents.length ? (
                    <div className="text-center text-xl text-en-gray-800 font-bold mt-8">
                      To get started, click ‘+ Add Event’ above
                    </div>
                  ) : (
                    <div className="text-center text-xl text-en-gray-300 font-bold mt-8">
                      Click an event on the left,
                      <br />
                      or add event to show the details here
                    </div>
                  )}
                </>
              )}

              {[ACTION.ADD_PROGRAM_EVENT, ACTION.EDIT_PROGRAM_EVENT].includes(
                currentAction
              ) && (
                <>
                  {selectedEnergyProgram?.programType === "COINCIDENT_PEAK" && (
                    <CPEventForm
                      key={`${selectedEnergyProgram.programId}-${
                        selectedProgramEvents[0]?.programEventId
                      }-${selectedProgramEvents[0]?.__version || 0}`}
                      energyProgram={selectedEnergyProgram}
                      programEvent={selectedProgramEvents[0]}
                      assertProgramEvent={assertProgramEvent}
                      isLoading={isAssertProgramEventLoading}
                      error={assertProgramEventError}
                      onCancel={() => {
                        setCurrentAction(ACTION.NONE);
                        setSelectedEnergyProgram(null);
                      }}
                    />
                  )}

                  {selectedEnergyProgram?.programType === "DEMAND_RESPONSE" && (
                    <DREvents
                      key={`${selectedEnergyProgram.programId}-${
                        selectedProgramEvents[0]?.programEventId
                      }-${selectedProgramEvents[0]?.__version || 0}`}
                      programEvents={selectedProgramEvents}
                      energyProgram={selectedEnergyProgram}
                      assertProgramEvents={assertMultipleProgramEvents}
                    />
                  )}
                </>
              )}

              <div className="flex justify-end p-4 flex-grow items-end">
                {canNotify && (
                  <NavLink
                    to={`/notifications/create?programEvents=${checkedProgramEvents
                      .map(({ programEventId }) => programEventId)
                      .join(",")}`}
                    className="ml-4 font-bold px-4 rounded focus:outline-none hover:shadow-md py-1 text-white bg-en-gray-700"
                  >
                    Send Notification
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
