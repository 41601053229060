import * as React from "react";

import { cn } from "@/_utils/cn";

/**
 * @typedef {React.InputHTMLAttributes<HTMLInputElement>} InputProps
 */
const Input = React.forwardRef((
  /** @type {InputProps}*/ { className, type, ...props },
  ref
) => {
  return (
    <input
      type={type}
      className={cn(
        "flex h-10 w-full rounded-md border border-input border-en-gray-400 focus:border-en-yellow-700 outline-none bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = "Input";

export { Input };
