import { AutocompleteInput, Button } from "@enpowered/ui";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// @ts-ignore
import SearchSVG from "@/assets/img/search.svg";
import Modal from "@/_components/Modal";

/**
 *
 * @param {object} props
 * @param {(programId: string) => void} props.onSubmit
 * @param {(e: MouseEvent) => void} props.onCancel
 * @param {boolean} props.isLoading
 * @param {object} props.account
 * @param {{id: string, label: string}[]} props.options
 * @returns {JSX.Element}
 */
export const AddSiteConfigForm = ({
  onSubmit,
  onCancel,
  isLoading,
  options = []
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isValidForm, setIsValidForm] = useState(true);

  useEffect(() => {
    setIsValidForm(true);
  }, [selectedOption]);

  const submitChanges = () => {
    if (!selectedOption) {
      setIsValidForm(false);
      return;
    }
    onSubmit(selectedOption.id);
  };

  return (
    <div>
      <h2 className="text-xl font-bold">Add Program</h2>

      <form onSubmit={submitChanges}>
        <div className="mt-4">
          <AutocompleteInput
            placeholder="Enter program name"
            className="pl-10"
            autocompleteData={options}
            suffix={null}
            prefix={
              <img
                src={SearchSVG}
                alt="Search program"
                className="absolute"
                style={{ top: 8, left: ".5rem" }}
              ></img>
            }
            valid={isValidForm}
            onSelected={setSelectedOption}
            onBlur={() => {}}
          />
          {!isValidForm && (
            <label className="block text-xs text-en-red">
              Select a program
            </label>
          )}
        </div>

        <div className="flex justify-end gap-4 mt-4">
          <Button theme="transparent" onClick={onCancel} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            type="submit"
            theme="dark"
            disabled={isLoading || !selectedOption}
          >
            {isLoading ? (
              <span>
                <i className="fa mx-4 fa-circle-notch fa-pulse text-white" />
              </span>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

AddSiteConfigForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  account: PropTypes.object,
  options: PropTypes.array
};

export const AddSiteConfigFormModal = ({ isOpen, ...props }) => {
  return (
    <Modal isOpen={isOpen} autoScroll={false}>
      <div>
        {isOpen && (
          // @ts-ignore
          <AddSiteConfigForm {...props} />
        )}
      </div>
    </Modal>
  );
};

AddSiteConfigFormModal.propTypes = {
  isOpen: PropTypes.bool,
  ...AddSiteConfigForm.propTypes
};
