import { assert } from "../../common";

const mfServerUrl =
  process.env.REACT_APP_MF_SERVER_ROOT || `http://localhost:32123`;

/**
 * @param {{ email: string, password: string }} [credentials]
 * @returns {Promise<{ accessToken: string, idToken: string, refreshToken: string }>}
 */
export async function getSecurityTokensForExternalPortal(credentials) {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    return {
      accessToken: assert(
        localStorage.getItem("accessToken"),
        "localStorage.accessToken must exist"
      ),
      idToken: assert(
        localStorage.getItem("idToken"),
        "localStorage.idToken must exist"
      ),
      refreshToken: assert(
        localStorage.getItem("refreshToken"),
        "localStorage.refreshToken must exist"
      ),
    };
  }

  const username = credentials?.email;
  const password = credentials?.password;

  return fetch(`${mfServerUrl}/auth/login`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      password,
    }),
  })
    .then((res) =>
      res.ok ? res.json() : Promise.reject(new Error(res.statusText))
    )
    .then(
      /** @param {{ tokens: { accessToken: string, idToken: string, refreshToken: string } }} data */
      (data) => {
        const tokens = data.tokens;

        localStorage.setItem("accessToken", tokens.accessToken);
        localStorage.setItem("idToken", tokens.idToken);
        localStorage.setItem("refreshToken", tokens.refreshToken);

        return tokens;
      }
    );
}
