import omit from "lodash/omit.js";
import _ from "lodash";
import { authedFetch } from "@/_utils/authedFetch";

const prefix = "com.enpowered.program-operations";
const METHOD = {
  queryEnergyPrograms: `${prefix}:queryEnergyPrograms`,
  enumerateSites: `${prefix}:enumerateSites`,
  activateSite: `${prefix}:activateSite`,
  deactivateSite: `${prefix}:deactivateSite`,
  updateSite: `${prefix}:updateSite`,
  assertProgramEvent: `${prefix}:assertProgramEvent`,
  enumerateProgramEvents: `${prefix}:enumerateProgramEvents`,
  enumerateMarketDatasets: `${prefix}:enumerateMarketDatasets`,
  configureProgramNotification: `${prefix}:configureProgramNotification`,
  cancelProgramNotification: `${prefix}:cancelProgramNotification`,
  enumerateProgramNotifications: `${prefix}:enumerateProgramNotifications`,
  enumerateRecipientProgramNotifications: `${prefix}:enumerateRecipientProgramNotifications`,
  enumerateNotificationRecipients: `${prefix}:enumerateNotificationRecipients`,
  configureNotificationRecipient: `${prefix}:configureNotificationRecipient`,
  deleteNotificationRecipient: `${prefix}:deleteNotificationRecipient`,
  enumerateProgramNotificationsV3: `${prefix}:enumerateProgramNotificationsV3`,
  enumerateOrganizationProgramNotificationsV2: `${prefix}:enumerateOrganizationProgramNotificationsV2`,
  enumerateRecipientProgramNotificationsV3: `${prefix}:enumerateRecipientProgramNotificationsV3`
};

const apiRoot = `${process.env.REACT_APP_API_ROOT}/program-operations/jsonrpc`;

/**
 * Query Energy Programs
 * @param {object} data
 * @param {string} [data.programId]
 * @param {boolean | 'ANY'} [data.isActive]
 * @param {string} [data.system]
 * @param {string} [data.region]
 * @param {string} [data.programScope]
 * @param {number} [data.itemsPerPage]
 * @param {string} [data.cursor]
 * @returns {Promise<{items: EnergyProgram[], cursor?:string}>}
 */
export const queryEnergyPrograms = data =>
  callJsonRPC(METHOD.queryEnergyPrograms, data).then(({ items, cursor }) => ({
    items: items.map(item => omit(item, ["__eventCount", "__version"])),
    cursor
  }));

/**
 * Activate Site
 * @param {Site} data
 * @returns {Promise<object>}
 */
export const activateSite = data => callJsonRPC(METHOD.activateSite, data);

export const deactivateSite = data => callJsonRPC(METHOD.deactivateSite, data);

/**
 *
 * @param {Site} data
 * @returns {Promise<object>}
 */
export const updateSite = data => callJsonRPC(METHOD.updateSite, data);

/**
 *
 * @param {object} params
 * @param {string} [params.id]
 * @param {string} [params.owner]
 * @returns {Promise<Site[]>}
 */
export const enumerateSites = params =>
  callJsonRPC(METHOD.enumerateSites, params).then(({ items }) => items);

/**
 * Assert Program Event
 * @param {Partial<ProgramEvent>} params
 * @returns {Promise<{
 *  attributes: Partial<ProgramEvent>,
 *  modifiedAttributes: Partial<ProgramEvent>,
 *  programEventId: string
 * }>}
 */
export const assertProgramEvent = params =>
  callJsonRPC(
    METHOD.assertProgramEvent,
    _.omit(params, ["__eventCount", "__version"])
  );

/**
 * Assert Multiple Program Events
 * @param {ProgramEvent[]} programEvents
 * @returns {Promise<{
 *  attributes: Partial<ProgramEvent>,
 *  modifiedAttributes: Partial<ProgramEvent>,
 *  programEventId: string
 * }[]>}
 */
export const assertMultipleProgramEvents = programEvents =>
  Promise.all(
    programEvents.map(programEvent =>
      callJsonRPC(
        METHOD.assertProgramEvent,
        _.omit(programEvent, ["__eventCount", "__version"])
      )
    )
  );

/**
 * Enumerate Program Events
 * @param {object} params
 * @param {string} [params.id]
 * @param {string} [params.eventDate]
 * @param {string} [params.from]
 * @param {string} [params.cursor]
 * @returns {Promise<{items: ProgramEvent[]; cursor?:string}>}
 */
export const enumerateProgramEvents = params =>
  callJsonRPC(METHOD.enumerateProgramEvents, params);

/**
 * @param {object} params
 * @param {string} [params.id]
 * @param {string} [params.date]
 * @param {string} [params.cursor]
 * @returns {Promise<{items: MarketDataset[]; cursor?:string;}>}
 */
export const enumerateMarketDatasets = params =>
  callJsonRPC(METHOD.enumerateMarketDatasets, params);

/**
 * @param {ProgramNotification} params
 * @returns {Promise<any>}
 */
export const configureProgramNotification = params =>
  callJsonRPC(METHOD.configureProgramNotification, params);

/**
 * @param {{notificationId: string}} params
 * @returns {Promise<any>}
 */
export const cancelProgramNotification = params =>
  callJsonRPC(METHOD.cancelProgramNotification, params);

/**
 * @param {object} params
 * @param {string} [params.date]
 * @param {string} [params.cursor]
 * @returns {Promise<{items:ProgramNotification[]; cursor?:string}>}
 */
export const enumerateProgramNotifications = params =>
  callJsonRPC(METHOD.enumerateProgramNotifications, params);

/**
 * @typedef {object} RecipientProgramNotificationParams
 * @property {string} [id]
 * @property {string} [email]
 * @property {string} [phoneNumber]
 * @property {string} [recipientId]
 * @property {string} [notificationId]
 * @property {string} [cursor]
 * @property {number} [itemsPerPage]
 *
 * @param {RecipientProgramNotificationParams} params
 * @returns {Promise<{items:RecipientProgramNotification[]; cursor?:string}>}
 */
export const enumerateRecipientProgramNotifications = params =>
  callJsonRPC(METHOD.enumerateRecipientProgramNotifications, params);

/**
 * @param {NotificationRecipient} params
 * @returns {Promise<any>}
 */
export const configureNotificationRecipient = params =>
  callJsonRPC(METHOD.configureNotificationRecipient, params, authedFetch);

/**
 * @typedef {object} EnumerateNotificationRecipientsParams
 * @property {string} [id]
 * @property {string} [organizationId]
 * @property {string} [siteId]
 * @property {number} [itemsPerPage]
 *
 * @param {EnumerateNotificationRecipientsParams} params
 * @returns {Promise<{items: NotificationRecipient[]}>}
 */
export const enumerateNotificationRecipients = params =>
  callJsonRPC(METHOD.enumerateNotificationRecipients, params, authedFetch);

/**
 *
 * @param {object} params
 * @param {string} params.recipientId
 * @returns {Promise<any>}
 */
export const deleteNotificationRecipient = params =>
  callJsonRPC(METHOD.deleteNotificationRecipient, params, authedFetch);

/**
 *
 * @param {object} params
 * @param {string} [params.cursor]
 * @param {number} [params.itemsPerPage]
 * @param {string} [params.label]
 * @param {string} [params.date] YYYY-MM-DD
 * @param {string} [params.time] HH:mm
 * @param {string} [params.programId]
 * @param {string} [params.status]
 * @param {string} [params.orgName]
 * @param {string} [params.rpnStatus]
 * @param {string} [params.email]
 * @param {string} [params.phoneNumber]
 * @param {string} [params.name]
 * @returns {Promise<{items:DeliveryReportNotification[]; cursor:string;}>}
 */
export const enumerateProgramNotificationsV3 = params =>
  callJsonRPC(METHOD.enumerateProgramNotificationsV3, params, authedFetch);

/**
 *
 * @param {object} [params]
 * @param {string} params.notificationId
 * @param {string} [params.status]
 * @param {string} [params.rpnStatus]
 * @param {string} [params.orgName]
 * @param {string} [params.email]
 * @param {string} [params.phoneNumber]
 * @param {string} [params.name]
 * @returns {Promise<{items:OrganizationProgramNotification[]; cursor?:string;}>}
 */
export const enumerateOrganizationProgramNotificationsV2 = params =>
  callJsonRPC(
    METHOD.enumerateOrganizationProgramNotificationsV2,
    params,
    authedFetch
  );

/**
 *
 * @param {object} params
 * @param {string} [params.organizationId]
 * @param {string} [params.programNotificationId]
 * @param {string} [params.name]
 * @param {string} [params.email]
 * @param {string} [params.phoneNumber]
 * @param {string} [params.status]
 * @returns {Promise<{items:any[]; cursor:string;}>}
 */
export const enumerateRecipientProgramNotificationsV3 = params =>
  callJsonRPC(
    METHOD.enumerateRecipientProgramNotificationsV3,
    params,
    authedFetch
  );

/**
 *
 * @param {string} method
 * @param {object} data
 * @param {any} fetch
 * @returns
 */
const callJsonRPC = (method, data, fetch = authedFetch) =>
  fetch(apiRoot, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      jsonrcp: "2.0",
      method,
      id: method,
      params: data
    })
  })
    .then(res => res.json())
    .then(({ result }) => result);

/**
 * @typedef {object} EnergyProgram
 * @property {string} programId
 * @property {'COINCIDENT_PEAK' | 'DEMAND_RESPONSE' | 'OPERATING_RESERVE' | 'FREQUENCY_RESPONSE'} programType
 * @property {'5CP' | '4CP' | '1CP' | 'DR' | 'OR' | 'FR'} programSubtype
 * @property {string} programScope
 * @property {string} programAdministrator
 * @property {string} system
 * @property {string} name
 * @property {string} nickname
 * @property {string} timezone
 * @property {string[]} regions
 * @property {string[]} [cpZoneName]
 * @property {{value: string, label: string}[]} [drZones]
 * @property {any} data
 *
 * @typedef {object} Site
 * @property {string} [siteId]
 * @property {boolean} [isActive]
 * @property {SiteInfo} [siteInfo]
 * @property {SiteConfig[]} [siteConfig]
 *
 * @typedef SiteInfo
 * @property {string} name
 * @property {string} ownerId
 * @property {SiteLocation} location
 *
 * @typedef SiteLocation
 * @property {string} streetAddress
 * @property {string} [unit]
 * @property {string} city
 * @property {string} state
 * @property {string} country
 * @property {string} postalCode
 *
 * @typedef {CPProgramResponseConfig | DRProgramResponseConfig} SiteConfig
 *
 * @typedef {object} CPProgramResponseConfig
 * @property {string} programId
 * @property {'MAXIMUM' | 'BALANCED' | 'MINIMUM'} responseBudgetCategory
 * @property {string} preferredResponseDuration
 * @property {string} maximumResponseDuration
 *
 * @typedef {object} DRProgramResponseConfig
 * @property {string} programId
 * @property {string} drZone
 *
 * @typedef {object} ProgramEvent
 * @property {string} [programEventId]
 * @property {string} [energyProgramId]
 * @property {string} [drZone]
 * @property {'HIGH' | 'MEDIUM' | 'LOW'} [cpConfidenceLevel]
 * @property {number} [cpThreshold]
 * @property {number} [cpPeakMWValue]
 * @property {number} [cpForecastedPeakDemand]
 * @property {'MAYBE' | 'NO_CALL' | 'CALLED' | 'CANCELLED'} status
 * @property {CPHour[] | DRInterval[]} eventIntervals
 * @property {number} [__eventCount]
 * @property {number} [__version]
 *
 * @typedef {object} CPHour
 * @property {string} timestamp
 * @property {number} rank
 * @property {boolean} isPriorityHour
 *
 * @typedef {object} DRInterval
 * @property {string} timestamp
 * @property {string} duration
 *
 * @typedef {object} MarketDataset
 * @property {string} id
 * @property {Dataset} internal
 * @property {Dataset} external
 * @property {Dataset} marketDataApi
 *
 * @typedef {object} Dataset
 * @property {{predictionSetName: string; branch: string; default: boolean}[]} [models]
 * @property {{[key: string]: string | string[]}} [cpMeasures]
 * @property {{uri: string; streamName: string;}[]} [datastreams]
 *
 * @typedef {object} ProgramNotification
 * @property {string} scheduleId
 * @property {string} [programNotificationId]
 * @property {{programEvents: string[]}} notificationScope
 * @property {string} schedule
 * @property {{[key: string]: MessageTemplate}} messageTemplates
 * @property {{key: string, value: string}[]} programBlobs
 * @property {string} [status]
 * @property {string} [label]
 * @property {{[key: string]: string}} [programBlobUrls]
 *
 * @typedef {object} MessageTemplate
 * @property {object} email
 * @property {string} email.subject
 * @property {string} email.body
 * @property {object} sms
 * @property {string} sms.body
 *
 * @typedef {object} NotificationRecipient
 * @property {string} [recipientId]
 * @property {string} organizationId
 * @property {string} name
 * @property {string[]} [channels]
 * @property {string} [email]
 * @property {string[]} [numberCountryCode]
 * @property {string} [phoneNumber]
 * @property {{siteId: string}[]} [siteSubscriptions]
 *
 * @typedef {object} DeliveryReportNotification
 * @property {string} notificationId
 * @property {string} notificationLabel
 * @property {string} notificationSchedule
 * @property {string} programId
 *
 * @typedef {object} OrganizationProgramNotification
 * @property {string} organizationId
 * @property {string} organizationName
 * @property {string} receivedRecipient
 * @property {string} totalRecipient
 * @property {string} status
 * @property {string} siteName
 * @property {SiteCall} siteCall
 * @property {SiteConfig} siteConfig
 * @property {string} siteId
 * @property {string} notificationId
 * @property {string} notificationLabel
 * @property {string} notificationSchedule
 *
 * @typedef {object} SiteCall
 * @property {string} siteCallId
 * @property {string} date
 * @property {string} eventWindowFrom
 * @property {string} eventWindowUntil
 * @property {string} peakWindowFrom
 * @property {string} peakWindowUntil
 * @property {string} responseAction
 * @property {string} responseMode
 * @property {string} responseWindowFrom
 * @property {string} responseWindowUntil
 *
 * @typedef {object} RecipientProgramNotification
 * @property {string} id
 * @property {string} recipientId
 * @property {string} programNotificationId
 * @property {{email: string; emailStatusDescription: string; sms: string}} deiveryStatus
 * @property {NotificationRecipient} recipient
 * @property {{
 *  email: {subject: string; content: string; emailMessageId: string};
 *  sms: {content: string; smsMessageId: string};
 * }} messages

 */
