import classnames from "classnames/dedupe";
import PropTypes from "prop-types";
import React from "react";

/**
 *
 * @param {object} props
 * @param {any} props.className
 * @param {boolean} props.isActive
 * @param {import("@/_services").OrgProfile} props.account
 * @param {(account: import("@/_services").OrgProfile) => any} props.onClick
 */
export const AccountListItem = ({ className, account, isActive, onClick }) => {
  const isDisabled = !account.orgProfileId;
  const itemClassName = classnames(
    "event flex-none",
    "px-4 py-3 m-2",
    "shadow-md border-2 border-solid rounded-md",
    "first:mt-0 last:mb-0",
    "cursor-pointer",
    className,
    {
      "bg-en-yellow-300 border-en-yellow-700": isActive,
      "bg-white border-en-gray-200 hover:bg-yellow-400":
        !isActive && !isDisabled,
      "bg-en-gray-200 border-en-gray-200": isDisabled
    }
  );
  return (
    <div
      onClick={() => !isDisabled && onClick(account)}
      className={itemClassName}
    >
      <div className="flex flex-row">
        <div className="flex flex-col items-start w-full">
          <div className="font-bold w-full truncate">{account.name}</div>
          <div className="w-full truncate text-sm my-1">
            {account?.address?.state}, {account?.address?.country}
          </div>
        </div>
      </div>
    </div>
  );
};

AccountListItem.propTypes = {
  className: PropTypes.any,
  account: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};

export default AccountListItem;
