import { authedFetch } from "@/_utils/authedFetch";

const apiRoot = process.env.REACT_APP_API_ROOT;

/**
 *
 * @param {string} programId
 * @param {object} deps
 * @param {import("@/_services").FetchFunction} [deps.fetch]
 * @returns {Promise<number>}
 */
export const getThreshold = async (programId, { fetch = authedFetch } = {}) => {
  return fetch(`${apiRoot}/data-streams/${programId}/threshold`, {
    method: "GET"
  })
    .then(res => res.json())
    .then(body => body.data);
};

/**
 *
 * @param {string} programId
 * @param {number} threshold
 * @param {object} deps
 * @param {import("@/_services").FetchFunction} [deps.fetch]
 * @returns {Promise<any>}
 */
export const updateThreshold = async (
  programId,
  threshold,
  { fetch = authedFetch } = {}
) => {
  return fetch(`${apiRoot}/data-streams/${programId}/threshold`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      data: {
        threshold
      }
    })
  }).then(res => res.json());
};
