export const PROGRAM_STATUSES = {
  MAYBE: {
    text: "Maybe",
    color: "bg-en-gray-200",
    availableActions: {
      Call: "CALLED",
      "No Call": "NO_CALL"
    }
  },
  CALLED: {
    text: "Called",
    color: "bg-en-yellow-700",
    availableActions: {
      Cancel: "CANCELLED"
    }
  },
  NO_CALL: {
    text: "No Call",
    color: "bg-en-gray-500",
    availableActions: {
      Maybe: "MAYBE",
      Call: "CALLED"
    }
  },
  CANCELLED: {
    text: "Cancelled",
    color: "bg-en-gray-800",
    availableActions: {
      Maybe: "MAYBE",
      Call: "CALLED"
    }
  }
};

export const getAvailableActions = status =>
  Object.keys(PROGRAM_STATUSES[status]?.availableActions || {});

export const getNewStatus = (currentStatus, action) =>
  PROGRAM_STATUSES[currentStatus]?.availableActions[action];
