import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, RadioGroup, Spinner } from "@enpowered/ui";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { InputAdornment } from "@mui/material";

/**
 *
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {(schedule: string) => void} props.onSubmit
 * @param {boolean} [props.isLoading]
 * @returns {JSX.Element}
 */
export const NotificationSchedulerModal = ({ isOpen, onClose, ...props }) => {
  return (
    <Modal isOpen={isOpen} size="md" className="[&_.modal-container]:w-[500px]">
      <div>
        <div className="flex justify-end">
          <FontAwesomeIcon
            className="text-xl"
            icon={faXmark}
            onClick={() => onClose()}
          />
        </div>
        <NotificationScheduler onClose={onClose} {...props} />
      </div>
    </Modal>
  );
};

/**
 *
 * @param {object} props
 * @param {() => void} props.onClose
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {(schedule: string) => void} props.onSubmit
 * @param {boolean} [props.isLoading]
 * @returns {JSX.Element}
 */
const NotificationScheduler = ({
  onClose,
  energyProgram,
  onSubmit,
  isLoading = false
}) => {
  const [scheduleType, setScheduleType] = useState("now");
  const [customDate, setCustomDate] = useState(DateTime.now());

  const onSchedule = () => {
    const schedule = scheduleType === "now" ? scheduleType : customDate.toISO();
    onSubmit && onSubmit(schedule);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-[auto_1fr] items-center gap-4">
        <label className="font-bold">Schedule for:</label>
        {
          //@ts-ignore
          <RadioGroup
            name="scheduleType"
            initialValue={scheduleType}
            onChange={e =>
              // @ts-ignore
              setScheduleType(e.target.value)
            }
            direction="horizontal"
            values={[
              { label: "Now", value: "now" },
              { label: "Custom", value: "custom" }
            ]}
            size="small"
          />
        }
        {scheduleType === "custom" && (
          <>
            <label className="font-bold">Custom Datetime:</label>
            <MobileDateTimePicker
              className="[&>div>input]:text-sm [&>div>input]:leading-5 [&>div>input]:py-0 [&>div>input]:px-2 [&>div>input]:min-h-8 [&>div>input]:placeholder:text-sm [&>div>input]:placeholder:leading-5"
              ampm={true}
              ampmInClock={true}
              value={customDate}
              onAccept={setCustomDate}
              onChange={() => {}}
              timezone={energyProgram.timezone}
              disablePast
              slotProps={{
                textField: {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        {DateTime.now()
                          .setZone(energyProgram.timezone)
                          .toFormat("ZZZZ")}
                      </InputAdornment>
                    )
                  }
                }
              }}
            />
          </>
        )}
      </div>
      <div>
        <p>
          You will be sending an alert to all{" "}
          {energyProgram.programAdministrator} {energyProgram.name} customers.{" "}
          {/* You will be sending an alert to all effected customers */}
          <span className="font-bold">
            This alert will be sent
            {scheduleType === "custom"
              ? ` on ${customDate.toFormat("LLLL d, 'at' h:mm a ZZZZ")}.`
              : ` now, ${DateTime.local().toFormat(
                  "LLLL d, 'at' h:mm a ZZZZ"
                )}.`}
          </span>
        </p>
      </div>
      <div className="flex justify-end gap-4">
        <Button
          size="narrow"
          theme="transparent"
          type="button"
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          size="narrow"
          theme="dark"
          type="button"
          onClick={() => onSchedule()}
          disabled={isLoading}
        >
          {isLoading ? <Spinner /> : "Schedule"}
        </Button>
      </div>
    </div>
  );
};

NotificationScheduler.propTypes = {
  onClose: PropTypes.func,
  energyProgram: PropTypes.object,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool
};

NotificationSchedulerModal.propTypes = {
  isOpen: PropTypes.bool,
  ...NotificationScheduler.propTypes
};
