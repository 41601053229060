import { IconButton } from "@/_components/IconButton";
import { RPN_STATUSES } from "@/delivery-reports/DeliveryReportsPage";
import { Button, Select, TextInput } from "@enpowered/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

const schema = yup.object().shape({
  notifications: yup.object().shape({
    label: yup.string(),
    date: yup.string(),
    time: yup.string()
  }),
  organizations: yup.object().shape({
    status: yup.string(),
    name: yup.string()
  }),
  recipients: yup.object().shape({
    status: yup.string(),
    name: yup.string(),
    email: yup.string(),
    phone: yup.string()
  }),
  energyPrograms: yup.object().shape({
    id: yup.string()
  })
});

/**
 *
 * @param {object} props
 * @param {boolean} [props.isOpen]
 * @param {() => void} props.onClose
 * @param {(filters: Partial<import("@/delivery-reports/DeliveryReportsPage").DeliveryReportsFilter>) => void} props.onFilter
 * @param {Partial<import("@/delivery-reports/DeliveryReportsPage").DeliveryReportsFilter>} [props.filters]
 * @param {import("@/_services").EnergyProgram[]} [props.energyPrograms]
 * @returns
 */
export const DeliveryReportsFilters = ({
  isOpen = false,
  onClose,
  onFilter,
  filters,
  energyPrograms = []
}) => {
  if (!isOpen) return null;

  const { register, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: filters
  });

  const onSubmit = data => {
    onFilter(data);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-en-gray-900/70 z-50">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="absolute top-0 right-0 h-full w-[500px] bg-white grid grid-rows-[auto_1fr_auto] gap-4 overflow-y-auto"
      >
        <div className="flex gap-4 items-center justify-between p-4">
          <div className="font-bold text-2xl">Filters</div>
          <IconButton icon="fa-close" onClick={onClose} />
        </div>

        <div className="flex flex-col gap-4 p-4">
          <div>
            <h2 className="font-bold border-b-2 border-en-gray-900 text-xl">
              Energy Programs
            </h2>
            <div className="grid grid-cols-[auto_1fr] gap-4 items-center py-4">
              <label className="font-bold">Name</label>
              <Select className="w-full" {...register("energyPrograms.id")}>
                <option value="">Select Energy Program ...</option>
                {energyPrograms.map(({ programId }) => (
                  <option key={programId} value={programId}>
                    {programId.toUpperCase()}
                  </option>
                ))}
              </Select>
            </div>
          </div>
          <div>
            <h2 className="font-bold border-b-2 border-en-gray-900 text-xl">
              Notifications
            </h2>
            <div className="grid grid-cols-[auto_1fr] gap-4 items-center py-4">
              <label className="font-bold">Label</label>
              <TextInput
                placeholder="Label"
                {...register("notifications.label")}
              />
              <label className="font-bold">Date</label>
              <Controller
                control={control}
                name="notifications.date"
                render={({ field }) => (
                  <MobileDatePicker
                    className="w-full [&>div>input]:text-sm [&>div>input]:leading-5 [&>div>input]:py-[4.35px] [&>div>input]:px-2 [&>div>input]:min-h-8 [&>div>input]:placeholder:text-sm [&>div>input]:placeholder:leading-5"
                    onChange={() => {}}
                    // value={date}
                    onAccept={value => {
                      if (!value) return;
                      field.onChange(value.toISO());
                    }}
                    value={field.value ? DateTime.fromISO(field.value) : null}
                  />
                )}
              />

              <label className="font-bold">Time</label>
              <Controller
                control={control}
                name="notifications.time"
                render={({ field }) => (
                  <MobileTimePicker
                    className="w-full [&>div>input]:text-sm [&>div>input]:leading-5 [&>div>input]:py-[4.35px] [&>div>input]:px-2 [&>div>input]:min-h-8 [&>div>input]:placeholder:text-sm [&>div>input]:placeholder:leading-5"
                    onChange={() => {}}
                    // value={date}
                    onAccept={value => {
                      if (!value) return;
                      field.onChange(value.toISO());
                    }}
                    value={field.value ? DateTime.fromISO(field.value) : null}
                  />
                )}
              />
            </div>
          </div>
          <div>
            <h2 className="font-bold border-b-2 border-en-gray-900 text-xl">
              Organizations
            </h2>
            <div className="grid grid-cols-[auto_1fr] gap-4 items-center py-4">
              <label className="font-bold">Status</label>
              <Select className="w-full" {...register("organizations.status")}>
                <option value="">Select status ...</option>
                <option value="Notified">Notified</option>
                <option value="Unnotified">Unnotified</option>
              </Select>
              <label className="font-bold">Name</label>
              <TextInput
                placeholder="Name"
                {...register("organizations.name")}
              />
              {/* <Controller
                control={control}
                name="organizations.id"
                render={({ field }) => (
                  <AutocompleteInput
                    autocompleteData={orgOptions}
                    value={
                      field.value
                        ? orgOptions.find(org => org.id === field.value)
                        : null
                    }
                    onSelected={selected => field.onChange(selected.id)}
                    onBlur={() => {}}
                    placeholder="Select organization"
                  />
                )}
              /> */}
            </div>
          </div>

          <div>
            <h2 className="font-bold border-b-2 border-en-gray-900 text-xl">
              Recipient
            </h2>
            <div className="grid grid-cols-[auto_1fr] gap-4 items-center py-4">
              <label className="font-bold">Delivery status</label>
              <Select className="w-full" {...register("recipients.status")}>
                <option value="">Select status ...</option>
                {RPN_STATUSES.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              <label className="font-bold">Name</label>
              <TextInput placeholder="Name" {...register("recipients.name")} />
              <label className="font-bold">Email</label>
              <TextInput
                placeholder="Email"
                {...register("recipients.email")}
              />
              <label className="font-bold">Phone number</label>
              <TextInput
                placeholder="Phone number"
                {...register("recipients.phone")}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-4 items-center justify-end sticky bottom-0 bg-white p-4">
          <Button theme="transparent" onClick={() => onFilter({})}>
            Reset
          </Button>
          <Button theme="dark" type="submit">
            Apply filters
          </Button>
        </div>
      </form>
    </div>
  );
};
