import { UserContext } from "@/_contexts/user";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const useLogout = () => {
  const navigate = useNavigate();

  const { clear } = useContext(UserContext);

  return () => {
    clear();
    localStorage.clear();
    navigate("/login");
  };
};
