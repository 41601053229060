import React from "react";
import { Modal } from "@enpowered/ui";
import { AccountForm } from "./AccountForm";
import PropTypes from "prop-types";

import { useCreateOrgProfile } from "@/_hooks";

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {() => any} props.onClose
 * @returns {JSX.Element}
 */
export const AddAccountModal = ({ isOpen, onClose }) => {
  const { mutate: createOrgProfile, isLoading, error } = useCreateOrgProfile(
    () => {
      onClose();
    }
  );

  return (
    <Modal isOpen={isOpen} size="lg">
      <AccountForm
        isNewAccount
        onSubmit={createOrgProfile}
        // @ts-ignore
        error={error}
        isLoading={isLoading}
        onCancel={onClose}
        title="Add Account Details"
      />
    </Modal>
  );
};

AddAccountModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};
