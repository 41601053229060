import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { Checkbox } from "@enpowered/ui";
import { EventStatus } from "./EventStatus";
import classNames from "classnames";
import { getTimeRangeFromIso } from "@/_utils/datetime.utils";

/**
 *
 * @param {object} props
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {import("@/_services").ProgramEvent[]} props.programEvents
 * @param {boolean} props.selected
 * @param {boolean} props.checked
 * @param {() => void} props.onSelect
 * @param {(value: boolean) => any} props.onCheck
 * @returns {JSX.Element}
 */

export const EventItem = ({
  energyProgram,
  programEvents,
  selected,
  checked,
  onSelect,
  onCheck
}) => {
  const isCPEvent = energyProgram.programType === "COINCIDENT_PEAK";
  const isDREvent = energyProgram.programType === "DEMAND_RESPONSE";

  const day = DateTime.fromISO(programEvents[0].eventIntervals[0].timestamp)
    .setZone(energyProgram?.timezone)
    .startOf("day");

  const peakWindow = isCPEvent
    ? getTimeRangeFromIso(
        programEvents[0].eventIntervals[0].timestamp,
        DateTime.fromISO(programEvents[0].eventIntervals.slice(-1)[0].timestamp)
          .plus({ hour: 1 })
          .toISO(),
        energyProgram.timezone
      )
    : "";

  const wrapperClass = classNames(
    "flex gap-4 p-2 items-center rounded-md shadow-md w-full border cursor-pointer"
  );

  const selectedClass = classNames(
    wrapperClass,
    "bg-en-yellow-200 border-en-yellow-500"
  );

  const normalClass = classNames(
    wrapperClass,
    "bg-white border-white",
    "hover:bg-en-yellow-200 hover:border-en-yellow-500"
  );

  return (
    <div
      className={selected ? selectedClass : normalClass}
      onClick={() => onSelect && onSelect()}
    >
      {isCPEvent && (
        <>
          <div className="flex-shrink-0 flex items-center">
            <Checkbox
              size="small"
              checked={checked}
              onChange={value => onCheck && onCheck(value)}
              name="select_cp_event"
            />
          </div>
          <div className="grid grid-cols-4 gap-x-4 gap-y-0 w-full items-center flex-grow">
            <span className="text-en-gray-800 text-sm">
              {day.toFormat("ccc, LLL d")}
            </span>
            <span className="text-en-gray-800 text-sm">Confidence</span>
            <span className="text-en-gray-800 text-sm">Forecast</span>
            <span className="text-en-gray-800 text-sm">Window</span>
            <EventStatus status={programEvents[0].status} />
            <span className="font-bold text-sm">
              {programEvents[0].cpConfidenceLevel || ""}
            </span>
            <span className="font-bold text-sm">
              {`${programEvents[0].cpForecastedPeakDemand.toLocaleString()} MW`}
            </span>
            <span className="font-bold text-sm">{peakWindow}</span>
          </div>
        </>
      )}

      {isDREvent && (
        <div
          className="grid gap-x-4 gap-y-0 w-full items-center flex-grow"
          style={{ gridTemplateColumns: "1fr 1fr" }}
        >
          <span className="text-en-gray-800 text-sm">Date</span>
          <span className="text-en-gray-800 text-sm">Zones</span>
          <span className="text-sm font-bold">
            {day.toFormat("ccc, LLL d")}
          </span>

          <p className="font-bold text-sm">
            {programEvents.map(({ drZone }) => drZone.split("-")[1]).join(", ")}
          </p>
        </div>
      )}
    </div>
  );
};

EventItem.propTypes = {
  energyProgram: PropTypes.object,
  event: PropTypes.object,
  selected: PropTypes.bool,
  checked: PropTypes.bool,
  onSelect: PropTypes.func,
  onCheck: PropTypes.func
};
