import { Button, Modal, Spinner, TextInput } from "@enpowered/ui";
import React, { useState } from "react";

/**
 *
 * @param {object} props
 * @param {() => void} props.onClose
 * @param {boolean} props.isOpen
 * @param {() => void} props.onConfirm
 * @param {boolean} [props.isLoading]
 * @param {string} props.title
 * @param {string} props.text
 * @param {string} [props.confirmText]
 * @param {string} [props.cancelButtonText]
 * @param {string} [props.confirmButtonText]
 * @returns
 */
export const ConfirmationModal = ({
  onClose,
  isOpen,
  onConfirm,
  isLoading = false,
  title,
  text,
  confirmText,
  cancelButtonText,
  confirmButtonText = "Confirm"
}) => {
  const [value, setValue] = useState("");

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      size="md"
      className="[&_.modal-container]:w-[500px]"
      onClose={onClose}
    >
      <div className="flex flex-col gap-4">
        <p>{text}</p>
        {!!confirmText && (
          <>
            <p>
              Type <span className="font-bold">{confirmText}</span> to confirm
            </p>
            <TextInput
              placeholder="cancel"
              value={value}
              onChange={e => setValue(e.target.value)}
            />
          </>
        )}

        <div className="flex justify-end gap-4">
          {!!cancelButtonText && (
            <Button
              size="narrow"
              theme="dark"
              disabled={
                confirmText ? value !== confirmText || isLoading : isLoading
              }
              onClick={onClose}
            >
              {cancelButtonText}
            </Button>
          )}
          <Button
            size="narrow"
            theme="dark"
            disabled={
              confirmText ? value !== confirmText || isLoading : isLoading
            }
            onClick={onConfirm}
          >
            {isLoading ? <Spinner /> : confirmButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
