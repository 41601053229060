import { DateTime } from "luxon";

/**
 *
 * @param {DateTime} date
 * @param {string} timezone
 * @returns {{
 *  dispatchWindowStart: string;
 *  dispatchWindowEnd: string;
 *  duration: string;
 * }}
 */
export const getDefaultDispatchWindow = (date, timezone) => {
  const year = date.year;
  const epochDate = date.toMillis();

  const summerWindowStart = date
    .toUTC()
    .set({ hour: 17 })
    .startOf("hour")
    .setZone(timezone);
  const summerWindowEnd = summerWindowStart.plus({ hours: 9 });
  const summerDuration = "PT9H";

  const winterWindowStart = date
    .toUTC()
    .set({ hour: 21 })
    .startOf("hour")
    .setZone(timezone);
  const winterWindowEnd = winterWindowStart.plus({ hours: 5 });
  const winterDuration = "PT5H";

  const ranges = [
    {
      start: DateTime.utc(year)
        .startOf("year")
        .toMillis(),
      end: DateTime.utc(year, 4)
        .endOf("month")
        .toMillis(),
      window: {
        dispatchWindowStart: winterWindowStart.toISO(),
        dispatchWindowEnd: winterWindowEnd.toISO(),
        duration: winterDuration
      }
    },
    {
      start: DateTime.utc(year, 5)
        .startOf("month")
        .toMillis(),
      end: DateTime.utc(year, 10)
        .endOf("month")
        .toMillis(),
      window: {
        dispatchWindowStart: summerWindowStart.toISO(),
        dispatchWindowEnd: summerWindowEnd.toISO(),
        duration: summerDuration
      }
    },
    {
      start: DateTime.utc(year, 11)
        .startOf("month")
        .toMillis(),
      end: DateTime.utc(year)
        .endOf("year")
        .toMillis(),
      window: {
        dispatchWindowStart: winterWindowStart.toISO(),
        dispatchWindowEnd: winterWindowEnd.toISO(),
        duration: winterDuration
      }
    }
  ];

  return ranges.find(
    range => epochDate >= range.start && epochDate <= range.end
  ).window;
};
