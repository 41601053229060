import React from "react";
// @ts-ignore
import pencilSVG from "@/assets/img/pencil.svg";
// @ts-ignore
import garbageSVG from "@/assets/img/garbage.svg";
import { SmallSVGButton } from "@/_components/SmallSVGButton";

/**
 *
 * @param {object} props
 * @param {import("@/_services").NotificationRecipient} props.recipient
 * @param {(recipient: import("@/_services").NotificationRecipient) => void} [props.onEdit]
 * @param {(recipient: import("@/_services").NotificationRecipient) => void} [props.onDelete]
 * @returns {JSX.Element}
 */
export const NotificationRecipientItem = ({ recipient, onEdit, onDelete }) => {
  return (
    <div
      className="py-1 border-b mb-1 grid grid-cols-[1fr_auto_auto] gap-2 items-center"
      style={{ gridTemplateColumns: "1fr auto auto" }}
    >
      <div>
        {!!recipient.name && <div className="font-bold">{recipient.name}</div>}
        <div>
          <span className="font-bold">email: </span>
          {recipient.email || "--"}
        </div>
        <div>
          <span className="font-bold">phone: </span>
          {recipient.phoneNumber || "--"}
        </div>
      </div>
      <SmallSVGButton
        className="w-10 h-10 flex justify-center items-center"
        src={pencilSVG}
        alt="Edit Recipient"
        onClick={() => onEdit && onEdit(recipient)}
      />
      <SmallSVGButton
        className="w-10 h-10 flex justify-center items-center"
        src={garbageSVG}
        alt="Remove Recipient"
        onClick={() => onDelete && onDelete(recipient)}
      />
    </div>
  );
};
