import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Routes from "./App.routes";
import { UserContextProvider } from "@/_contexts/user";
import { RoleContextProvider } from "@/_contexts/role";

/**
 *
 * @returns {JSX.Element}
 */
function App() {
  const queryClient = new QueryClient();
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <UserContextProvider>
        <RoleContextProvider>
          <QueryClientProvider client={queryClient}>
            <Routes />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </RoleContextProvider>
      </UserContextProvider>
    </LocalizationProvider>
  );
}

export default App;
