import { useQuery } from "react-query";
import { getInternalProgramMeasures } from "../_services";

/**
 *
 * @param {import("../_services").MarketDataset} marketDataset
 * @param {string} startDate
 * @param {string} endDate
 * @returns {import("react-query").UseQueryResult<import("../_services").ProgramMeasure[], any>}
 */
export const useGetProgramMeasures = (marketDataset, startDate, endDate) => {
  return useQuery(
    ["program-measures", marketDataset?.id || "", startDate, endDate],
    () => getInternalProgramMeasures(marketDataset, startDate, endDate)
  );
};
