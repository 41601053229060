import { Button } from "@enpowered/ui";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Reorder } from "framer-motion";

// @ts-ignore
import garbageSVG from "@/assets/img/garbage.svg";
// @ts-ignore
import pencilSVG from "@/assets/img/pencil.svg";
// @ts-ignore
import GripSVG from "@/assets/img/grip.svg";
import { SmallSVGButton } from "@/_components/SmallSVGButton";

/**
 *
 * @param {object} props
 * @param {import("@/_services").Site} props.site
 * @param {import("@/_services").EnergyProgram[]} props.energyPrograms
 * @param {() => void} props.onAdd
 * @param {(siteConfig: import("@/_services").SiteConfig) => void} props.onEdit
 * @param {(siteConfig: import("@/_services").SiteConfig) => void} props.onRemove
 * @param {(siteConfigs: import("@/_services").SiteConfig[]) => void} props.onReorder
 * @param {boolean} props.addProgramsEnabled
 * @param {boolean} props.showNoSubscriptionsMessage
 * @param {boolean} props.isLoading
 * @returns {JSX.Element}
 */
export const SiteConfigsListPanel = ({
  site,
  energyPrograms,
  onAdd,
  onEdit,
  onRemove,
  onReorder,
  addProgramsEnabled,
  showNoSubscriptionsMessage,
  isLoading
}) => {
  const [items, setItems] = useState(site?.siteConfig || []);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setItems(site?.siteConfig || []);
    const original = JSON.stringify(site?.siteConfig || []);
    const newOrder = JSON.stringify(items);
    setHasChanged(original !== newOrder);
  }, [site?.siteConfig]);

  const handleOrder = order => {
    setItems(order);

    const original = JSON.stringify(site.siteConfig);
    const newOrder = JSON.stringify(order);
    setHasChanged(original !== newOrder);
  };

  return (
    <div className="bg-white p-3 mb-4 w-full border-solid border-2 rounded-md border-en-gray-100">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="font-bold text-lg">Programs</h2>
        </div>
        <Button
          size="narrow"
          theme="dark"
          className="py-2"
          onClick={() => addProgramsEnabled && onAdd && onAdd()}
          disabled={!addProgramsEnabled}
        >
          Add Program
        </Button>
      </div>
      {!!items?.length && (
        <div className="pt-4 mt-4 border-t-2 border-en-gray-500">
          <Reorder.Group axis="y" values={items} onReorder={handleOrder}>
            {items.map(siteConfig => (
              <Reorder.Item key={siteConfig.programId} value={siteConfig}>
                <div
                  className="grid gap-2 items-center cursor-move"
                  style={{ gridTemplateColumns: "auto 1fr" }}
                >
                  <div className="">
                    <img src={GripSVG} width={10} />
                  </div>
                  <SiteConfigListItem
                    energyProgram={energyPrograms.find(
                      ({ programId }) => siteConfig.programId === programId
                    )}
                    siteConfig={siteConfig}
                    onEdit={onEdit}
                    onRemove={onRemove}
                  />
                </div>
              </Reorder.Item>
            ))}
          </Reorder.Group>
          {items.length > 1 && (
            <div className="flex justify-between items-center mt-2 pt-2 border-t-2 border-en-gray-200">
              <p className="text-sm">
                Move the items above to prioritize the energy program
              </p>
              {hasChanged && (
                <Button
                  size="narrow"
                  onClick={() => !isLoading && onReorder(items)}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span>
                      <i className="fa mx-4 fa-circle-notch fa-pulse text-white" />
                    </span>
                  ) : (
                    "Save"
                  )}
                </Button>
              )}
            </div>
          )}
        </div>
      )}

      {showNoSubscriptionsMessage && (
        <label className="block text-sm py-2 text-right text-red-800">
          This account is not subscribed to any programs.
          <br />
          To add a program, subscribe the account to that program first.
        </label>
      )}
    </div>
  );
};

SiteConfigsListPanel.propTypes = {
  programs: PropTypes.arrayOf(PropTypes.object),
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  orReorder: PropTypes.func,
  addProgramsEnabled: PropTypes.bool,
  showNoSubscriptionsMessage: PropTypes.bool,
  isLoading: PropTypes.bool
};

SiteConfigsListPanel.defaultProps = {
  addProgramsEnabled: true,
  showNoSubscriptionsMessage: false
};

/**
 *
 * @param {object} props
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {import("@/_services").SiteConfig} props.siteConfig
 * @param {(siteConfig: import("@/_services").SiteConfig) => void} props.onEdit
 * @param {(siteConfig: import("@/_services").SiteConfig) => void} props.onRemove
 * @returns {JSX.Element}
 */
export const SiteConfigListItem = ({
  energyProgram,
  siteConfig,
  onEdit,
  onRemove
}) => {
  return (
    <div className="site-config-list-item flex justify-between items-center py-1">
      <div>
        <b>{energyProgram?.system} -</b> {energyProgram?.nickname}
      </div>
      <div className="flex justify-end items-center gap-2">
        <SmallSVGButton
          src={pencilSVG}
          alt="Edit Site Program"
          onClick={() => {
            onEdit(siteConfig);
          }}
        />
        <SmallSVGButton
          src={garbageSVG}
          alt="Remove Site Program"
          onClick={() => {
            onRemove(siteConfig);
          }}
        />
      </div>
    </div>
  );
};

SiteConfigListItem.propTypes = {
  energyProgram: PropTypes.object,
  siteConfig: PropTypes.object,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func
};
