import { assert, sleep } from "../../../common";
import { getWindow } from "./window.util";

/**
 *
 * @param {string} scope
 * @param {{ hasZeroInstances: () => boolean }} tracker
 */
export const unloadMicrofrontendAssets = (scope, tracker) => {
  const mfName = `mf-${scope.toLocaleLowerCase()}-entry`;
  const mfScript = document.getElementById(mfName);
  if (!mfScript) {
    return;
  }
  const src = assert(
    mfScript.getAttribute("src"),
    `script #${mfName} must have attr [src]`
  );
  const lastSlash = src.lastIndexOf("/");
  const urlStart = src.slice(0, lastSlash);

  const maxAttempts = 3;
  /** @return {Promise<Element[]>} */
  async function attemptToRemoveAssets(attempt = 0) {
    const scripts = Array.from(
      document.querySelectorAll("head script")
    ).filter((script) => script.getAttribute("src")?.startsWith(urlStart));
    const links = Array.from(
      document.querySelectorAll("head link")
    ).filter((link) => link.getAttribute("href")?.startsWith(urlStart));

    if (links.length && tracker.hasZeroInstances()) {
      console.log("Removed assets", [...scripts, ...links]);
      scripts.forEach((script) => {
        script.remove();
      });
      links.forEach((link) => {
        link.remove();
      });
      return [...scripts, ...links];
    } else {
      return sleep(100).then(() => {
        if (attempt < maxAttempts) {
          return attemptToRemoveAssets(attempt + 1);
        }
        return [];
      });
    }
  }
  return sleep(100).then(() => attemptToRemoveAssets());
};

/**
 * Keeps track of how many running instances of a Microfrontend there are.
 *
 * By tracking, we are able to
 * @param {import("./controller.util").MicrofrontendController} $ctrl
 * @returns
 */
export const instanceTracker = ($ctrl) => {
  return {
    increment: () => {
      $ctrl.instances++;
    },
    decrement: () => {
      if ($ctrl.instances > 0) {
        $ctrl.instances--;
      }
    },
    hasZeroInstances: () => {
      const $window = getWindow();
      const scope = $window.enpowered?.[$ctrl.scope];
      return (
        Object.values(
          assert(scope, `window.enpowered.${$ctrl.scope} must exist`)
        ).reduce((sum, scope) => sum + scope.instances, 0) === 0
      );
    },
  };
};
