import { Select, Textarea, TextInput } from "@enpowered/ui";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import classNames from "classnames";
import { $getRoot, $getSelection } from "lexical";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { ResponseItem } from "./ResponseItem";

/**
 * @typedef {object} ResponseTemplate
 * @property {string} id
 * @property {string} action
 * @property {string} label
 * @property {{ value: string; label: string }} [mode]
 * @property {{subject: string; body: string}} email
 * @property {{body: string}} sms
 */

export const RESPONSE_ACTIONS = Object.freeze({
  STANDBY: {
    id: "STANDBY",
    label: "Standby",
    drModes: /** @type {{ value: string; label: string }[]}*/ ([]),
    cpModes: /** @type {{ value: string; label: string }[]}*/ ([])
  },
  RESPOND: {
    id: "RESPOND",
    label: "Respond",
    drModes: [
      { value: "DR", label: "DR" },
      { value: "DR_TEST", label: "DR Test" }
    ],
    cpModes: [
      { value: "CP_CONFIDENCE_ADEQUATE", label: "CP Confidence Adequate" }
    ]
  },
  DO_NOT_RESPOND: {
    id: "DO_NOT_RESPOND",
    label: "Do Not Respond",
    drModes: [
      { value: "DR_TEST_CANCELLED", label: "DR Test Cancelled" },
      { value: "NO_CALL", label: "No Call" }
    ],
    cpModes: [
      { value: "CP_CONFIDENCE_INADEQUATE", label: "CP Confidence Inadequate" },
      { value: "CP_CANCELLED", label: "CP Cancelled" },
      { value: "NO_CALL", label: "No Call" }
    ]
  }
});

/**
 * @param {object} props
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {object} props.notification
 * @param {(notification: Partial<import("@/_services").ProgramNotification>) => void} props.updateNotification
 * @param {boolean} [props.isEditable]
 * @returns {JSX.Element}
 */
export const NotificationTemplates = ({
  energyProgram,
  notification,
  updateNotification,
  isEditable = true
}) => {
  const isCP = energyProgram.programType === "COINCIDENT_PEAK";
  const isDR = energyProgram.programType === "DEMAND_RESPONSE";
  const modeType = isCP ? "cpModes" : isDR ? "drModes" : "";
  const responseModes = Object.values(RESPONSE_ACTIONS).reduce(
    (output, response) => {
      if (!response[modeType].length) {
        output.push({
          id: response.id,
          action: response.label,
          label: response.label,
          email: { subject: "", body: "" },
          sms: { body: "" }
        });
      } else {
        response[modeType].forEach(mode =>
          output.push({
            id: `${response.id}__${mode.value}`,
            action: response.label,
            label: `${response.label} - ${mode.label}`,
            mode,
            email: { subject: "", body: "" },
            sms: { body: "" }
          })
        );
      }

      return output;
    },
    []
  );

  const [selectedResponse, setSelectedResponse] = useState(
    /** @type {ResponseTemplate}*/ (null)
  );

  const { setValue, getValues, register } = useForm({
    defaultValues: notification?.messageTemplates || {}
  });

  const getAvailableResponseModes = () => {
    return responseModes.filter(({ id }) => !getValues(id));
  };

  const addedResponses = () =>
    Object.keys(getValues())
      // @ts-ignore
      .filter(key => !!getValues(key))
      .map(key => responseModes.find(({ id }) => id === key));

  /** @param {string} value */
  const addResponse = value => {
    // @ts-ignore
    setValue(value, {
      email: { subject: "", body: "" },
      sms: { body: "" }
    });

    updateNotification({ messageTemplates: getValues() });
  };

  const resize = () => {
    const container = /** @type {HTMLElement}*/ (document.querySelector(
      "#editor-container"
    ));

    if (!container) return;

    const height = container.offsetHeight;
    /** @type {HTMLElement}*/ (document.querySelector(
      "#content-editor"
    )).style.maxHeight = `${height}px`;
  };

  // Calculate max height of text editor
  useEffect(() => {
    window.addEventListener("resize", resize);

    if (Object.values(notification.messageTemplates).length) {
      setSelectedResponse(addedResponses()[0]);
    }

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <div className="grid grid-cols-[350px_1fr] h-full">
      <div className="grid grid-rows-[auto_1fr] gap-4 p-4 border-r h-full">
        {isEditable && (
          <div className="flex justify-between items-center">
            <Select value="" onChange={e => addResponse(e.target.value)}>
              <option value="">Select a template to add</option>
              {getAvailableResponseModes().map(response => (
                <option key={response.id} value={response.id}>
                  {response.label}
                </option>
              ))}
            </Select>
          </div>
        )}
        <div className="flex flex-col gap-4 h-full">
          {addedResponses().map(response => (
            <ResponseItem
              key={response.id}
              response={response}
              canDelete={isEditable}
              onDelete={responseId => {
                // @ts-ignore
                setValue(responseId, undefined);
                if (selectedResponse?.id === responseId) {
                  setSelectedResponse(null);
                }

                updateNotification({ messageTemplates: getValues() });
              }}
              onSelect={setSelectedResponse}
            />
          ))}
        </div>
      </div>
      <div className="p-4 h-full">
        {!!selectedResponse && (
          <div
            key={selectedResponse.id}
            className="h-full grid grid-rows-[auto_1fr]"
          >
            <h3 className="text-2xl">
              Template for{" "}
              <span className="font-bold">{`${selectedResponse.action}${
                selectedResponse.mode?.label
                  ? ` - ${selectedResponse.mode.label}`
                  : ""
              }`}</span>
            </h3>
            <div className="grid grid-cols-[4fr_3fr] gap-4 h-full mt-4">
              <div className="grid grid-rows-[auto_1fr] gap-4 h-full">
                <div>
                  <h4 className="font-bold">Email</h4>
                  <TextInput
                    placeholder="Email Subject"
                    disabled={!isEditable}
                    {...register(
                      // @ts-ignore
                      `${selectedResponse.id}.email.subject`,
                      {
                        onChange: () =>
                          updateNotification({ messageTemplates: getValues() })
                      }
                    )}
                  />
                </div>
                <LexicalComposer
                  initialConfig={{
                    namespace: "emailBody",
                    onError: console.error,
                    editorState: editor => {
                      editor.update(() => {
                        const parser = new DOMParser();
                        const dom = parser.parseFromString(
                          getValues(
                            // @ts-ignore
                            `${selectedResponse.id}.email.body`
                          )?.toString() || "",
                          "text/html"
                        );
                        const nodes = $generateNodesFromDOM(editor, dom);
                        $getRoot().select();
                        const selection = $getSelection();
                        selection.insertNodes(nodes);
                      });
                    }
                  }}
                >
                  <div id="editor-container" className="relative h-full">
                    <RichTextPlugin
                      contentEditable={
                        <ContentEditable
                          id="content-editor"
                          disabled={isEditable}
                          className={classNames(
                            "relative p-2 h-full max-h-full overflow-y-auto border rounded-md border-en-gray-600 hover:border-en-gray-800 focus:border-en-yellow-700 focus:outline-none",
                            {
                              "bg-en-gray-100": !isEditable
                            }
                          )}
                        />
                      }
                      placeholder={
                        <div className="absolute top-2 left-2 pointer-events-none">
                          Email Body
                        </div>
                      }
                      ErrorBoundary={LexicalErrorBoundary}
                    />
                    <HistoryPlugin />
                    <OnChangePlugin
                      onChange={(editorState, editor) => {
                        editorState.read(() => {
                          const htmlString = $generateHtmlFromNodes(
                            editor,
                            null
                          );
                          setValue(
                            `${selectedResponse.id}.email.body`,
                            htmlString
                          );

                          updateNotification({ messageTemplates: getValues() });
                        });
                      }}
                    />
                  </div>
                </LexicalComposer>
              </div>
              <div className="grid grid-rows-[auto_1fr] h-full">
                <h4 className="font-bold">SMS</h4>
                <Textarea
                  placeholder="SMS Body"
                  {...register(
                    // @ts-ignore
                    `${selectedResponse.id}.sms.body`,
                    {
                      onChange: () =>
                        updateNotification({ messageTemplates: getValues() })
                    }
                  )}
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

NotificationTemplates.propTypes = {
  energyProgram: PropTypes.object
};
