import { useResetTokens } from "@/_hooks";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const OAuthPage = () => {
  const [searchParams] = useSearchParams();
  const payload = searchParams.get("payload");
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const resetUserTokens = useResetTokens();

  useEffect(() => {
    try {
      const jsonText = atob(payload);
      const tokens = JSON.parse(jsonText);
      resetUserTokens(tokens).then(() => {
        navigate("/");
      });
    } catch (error) {
      console.error(error);
      setError(error);
    }
  }, [payload]);

  return (
    <div>
      <div className="spin-when-empty"></div>
      {error ? (
        <div>
          There was an error retrieving authentication information. Please
          contact the administrator.
        </div>
      ) : null}
    </div>
  );
};
