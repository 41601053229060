import React, { useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { Button, HelperText, Select, TextInput } from "@enpowered/ui";
import { phone as _phone } from "phone";
import Modal from "@/_components/Modal";

/**
 *
 * @param {NotificationRecipientFormParams & {isOpen: boolean}} props
 * @returns {JSX.Element}
 */
export const NotificationRecipientFormModal = ({ isOpen, ...props }) => {
  return (
    <Modal isOpen={isOpen} autoScroll={false} size="lg">
      <div>{isOpen && <NotificationRecipientForm {...props} />}</div>
    </Modal>
  );
};

const schema = Yup.object({
  recipientId: Yup.string(),
  organizationId: Yup.string(),
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Email is not correct")
    .required("Email is required"),
  phoneNumber: Yup.string()
    .when("channels", {
      is: channels => channels.includes("sms"),
      then: schema => schema.required("Phone number is required")
    })
    .test(
      "validPhoneNumber",
      "Phone number is incorrect",
      (value, { parent }) => {
        if (!value) return true;

        const { isValid } = _phone(value, {
          country: parent.numberCountryCode?.[0] || undefined
        });
        return isValid;
      }
    ),
  numberCountryCode: Yup.array(Yup.string())
    .min(0)
    .max(1)
    .test(
      "validPhoneNumber",
      "Phone number is incorrect",
      (value, { parent }) => {
        if (!value.length && !!parent.phoneNumber) return false;
        if (!value.length && !parent.phoneNumber) return true;

        const { isValid } = _phone(parent.phoneNumber, {
          country: value?.[0] || undefined
        });
        return isValid;
      }
    ),
  channels: Yup.array(Yup.string()),
  siteSubscriptions: Yup.array(Yup.string())
});

const phoneCodes = ["+1"];

/**
 * @typedef {object} NotificationRecipientFormParams
 * @property {import("@/_services").NotificationRecipient} [recipient]
 * @property {string} organizationId
 * @property {() => void} onCancel
 * @property {boolean} [isLoading]
 * @property {import("react-query").UseMutateFunction<import("@/_services").NotificationRecipient, unknown, import("@/_services").NotificationRecipient, unknown>} props.onSubmit
 * @property {import("@/_services").Site[]} sites
 * @property {any} [error]
 *
 * @param {NotificationRecipientFormParams} props
 * @returns {JSX.Element}
 */
export const NotificationRecipientForm = ({
  recipient,
  organizationId,
  onCancel,
  onSubmit: onSave,
  sites,
  isLoading = false,
  error
}) => {
  const phone = useMemo(() => _phone(recipient?.phoneNumber), [recipient]);

  const [countryCode, setCountryCode] = useState(
    phone.isValid ? phone.countryCode : phoneCodes[0]
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      recipientId: recipient?.recipientId || undefined,
      organizationId: recipient?.organizationId || organizationId,
      name: recipient?.name || "",
      email: recipient?.email || "",
      phoneNumber: phone.isValid
        ? phone.phoneNumber.replace(phone.countryCode, "")
        : recipient?.phoneNumber
        ? recipient?.phoneNumber.replace("+1", "")
        : "",
      numberCountryCode: phone.isValid
        ? [phone.countryIso2]
        : recipient?.numberCountryCode || [],
      channels: recipient?.channels || [],
      siteSubscriptions:
        recipient?.siteSubscriptions?.map(({ siteId }) => siteId) || []
    }
  });

  /**
   * @param {{
   *  channels?: string[];
   *  recipientId?: string;
   *  organizationId: string;
   *  name: string;
   *  email?: string;
   *  phoneNumber?: string;
   *  numberCountryCode?: string[];
   *  siteSubscriptions?: string[];
   * }} values
   * */
  const onSubmit = ({ phoneNumber, numberCountryCode, ...values }) => {
    if (!isValid) return;

    const { isValid: isPhoneValid, phoneNumber: phone } = _phone(
      phoneNumber || "",
      {
        country: numberCountryCode?.[0] || undefined
      }
    );

    onSave &&
      onSave({
        ...values,
        ...(isPhoneValid
          ? {
              numberCountryCode,
              phoneNumber: phone
            }
          : { numberCountryCode: [], phoneNumber: "" }),
        siteSubscriptions: values.siteSubscriptions.map(siteId => ({ siteId }))
      });
  };

  return (
    <div className="grid grid-rows-[auto-1fr]">
      <h3 className="font-bold text-xl mb-4">
        {recipient ? "Edit" : "Add"} Recipient
      </h3>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <input type="hidden" {...register("organizationId")} />
        <div className="flex flex-col gap-2">
          <label>Name:</label>
          <TextInput {...register("name")} />
          {errors.name?.message && (
            <HelperText valid={false}>{errors.name?.message}</HelperText>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label>Email:</label>
          <TextInput {...register("email")} />
          {errors.email?.message && (
            <HelperText valid={false}>{errors.email?.message}</HelperText>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <label>Phone number:</label>
          <div className="grid grid-cols-[auto_1fr] gap-2">
            <Select
              className="w-[60px]"
              value={countryCode}
              onChange={e => {
                setCountryCode(e.target.value);
              }}
            >
              {phoneCodes.map(code => (
                <option value={code} key={code}>
                  {code}
                </option>
              ))}
            </Select>
            <TextInput
              {...register("phoneNumber")}
              onChange={e => {
                if (e.target.value === "") {
                  setValue("numberCountryCode", []);
                  return;
                }

                const newPhone = _phone(`${countryCode}${e.target.value}`);
                setValue(
                  "numberCountryCode",
                  newPhone.isValid ? [newPhone.countryIso2] : []
                );
              }}
            />
          </div>
          {(errors.phoneNumber?.message ||
            errors.numberCountryCode?.message) && (
            <HelperText valid={false}>
              {errors.phoneNumber?.message || errors.numberCountryCode?.message}
            </HelperText>
          )}
        </div>

        <div className="grid grid-cols-[100px_1fr] gap-4 items-center">
          <label>Channels</label>
          <div className="grid grid-cols-[1fr_1fr] gap-4 items-center">
            <label className="flex gap-2 items-center">
              <input type="checkbox" value="email" {...register("channels")} />
              Email
            </label>
            <label className="flex gap-2 items-center">
              <input type="checkbox" value="sms" {...register("channels")} />
              SMS
            </label>
          </div>
          {errors.channels?.message && (
            <HelperText valid={false}>{errors.channels?.message}</HelperText>
          )}
        </div>

        <div className="flex gap-4 items-start w-ful">
          <div className="grid grid-cols-[100px_1fr] items-start w-full gap-4">
            <label>Site</label>
            <div className="grid grid-cols-[1fr_1fr] gap-4">
              {sites.map(site => (
                <label key={site.siteId} className="flex gap-2 items-center">
                  <input
                    type="checkbox"
                    value={site.siteId}
                    {...register("siteSubscriptions")}
                  />
                  {site.siteInfo.name}
                </label>
              ))}
            </div>
          </div>
          {errors.siteSubscriptions?.message && (
            <HelperText valid={false}>
              {errors.siteSubscriptions?.message}
            </HelperText>
          )}
        </div>

        <div className="grid grid-cols-[1fr_auto] gap-4 mt-4 items-center">
          <div>
            {error && (
              <HelperText valid={false}>{error.data.description}</HelperText>
            )}
          </div>
          <div className="flex justify-end gap-4">
            <Button
              theme="transparent"
              onClick={() => onCancel()}
              disabled={isLoading}
              type="button"
            >
              Cancel
            </Button>
            <Button type="submit" theme="dark" disabled={isLoading}>
              {isLoading ? (
                <span>
                  <i className="fa mx-4 fa-circle-notch fa-pulse text-white" />
                </span>
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
