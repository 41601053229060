import { useEffect, useState } from "react";

/**
 *
 * @param {import("../types").MicrofrontendEventBus} eventBus
 */
export const useChassisLocation = (eventBus) => {
  /**
   * @typedef {Partial<{ pathname: string, key: string, hash: string, search: string, state: string }>} ChassisLocation
   * @type {ReactState<?ChassisLocation>}
   */
  const [location, setLocation] = useState(
    /** @type {?ChassisLocation} */ (null)
  );
  useEffect(() => {
    /**
     *
     * @param {ChassisLocation} location
     */
    const onLocationChange = (location) => {
      setLocation(location);
    };
    eventBus?.on("location:change", onLocationChange);

    return () => eventBus?.off("location:change", onLocationChange);
  }, []);

  return location || { key: "init" };
};
