import PropTypes from "prop-types";
import React from "react";

/**
 *
 * @param {object} props
 * @param {{ given_name?: string, family_name?: string }} props.user
 * @returns {JSX.Element}
 */
export const Initials = ({ user }) => {
  const first = user?.given_name ? user?.given_name?.[0] : "";
  const last = user?.family_name ? user?.family_name?.[0] : "";
  const initials = (first + last || "--").toUpperCase();
  return (
    <div className="w-12 h-12 rounded-full mr-4 bg-en-gray-100 flex items-center justify-center font-bold text-gray-900">
      {initials}
    </div>
  );
};

Initials.propTypes = {
  user: PropTypes.shape({
    given_name: PropTypes.string,
    family_name: PropTypes.string
  })
};
