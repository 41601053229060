import { authedFetch } from "@/_utils/authedFetch";

const apiRoot = process.env.REACT_APP_API_ROOT;

/**
 *
 * @param {string} programId
 * @param {object} deps
 * @param {import("@/_services").FetchFunction} [deps.fetch]
 * @returns {Promise<{timestamp: string, value: string}[]>}
 */
export const getDefaultModelData = (programId, { fetch = authedFetch } = {}) =>
  fetch(`${apiRoot}/data-streams/models/${programId}/default`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(({ data }) => data);

/**
 *
 * @param {string} programId
 * @param {object} deps
 * @param {import("@/_services").FetchFunction} [deps.fetch]
 * @returns {Promise<{timestamp: string, value: string}[]>}
 */
export const getCustomModelData = (programId, { fetch = authedFetch } = {}) =>
  fetch(`${apiRoot}/data-streams/models/${programId}/custom`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(({ data }) => data);

/**
 *
 * @param {object} deps
 * @param {import("@/_services").FetchFunction} [deps.fetch]
 * @returns {Promise<{[key: string]: boolean}>}
 */
export const getExistingCustomModels = ({ fetch = authedFetch } = {}) =>
  fetch(`${apiRoot}/data-streams/models/existing`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(({ data }) => data);

/**
 *
 * @param {string} programId
 * @param {File} file
 * @param {object} deps
 * @param {import("@/_services").FetchFunction} [deps.fetch]
 * @returns {Promise<{timestamp: string, value: string}[]>}
 */
export const uploadCustomModel = (
  programId,
  file,
  { fetch = authedFetch } = {}
) => {
  const formdata = new FormData();
  formdata.append("data", file);

  return fetch(`${apiRoot}/data-streams/models/${programId}/custom`, {
    method: "POST",
    body: formdata
  })
    .then(res => res.json())
    .then(({ data }) => data);
};

/**
 *
 * @param {string} programId
 * @param {object} deps
 * @param {import("@/_services").FetchFunction} [deps.fetch]
 * @returns {Promise<object[]>}
 */
export const refreshModel = (programId, { fetch = authedFetch } = {}) => {
  return fetch(`${apiRoot}/data-streams/models/${programId}/refresh`, {
    method: "POST"
  })
    .then(res => res.json())
    .then(({ data }) => data);
};

/**
 *
 * @param {string} programId
 * @param {object} deps
 * @param {import("@/_services").FetchFunction} [deps.fetch]
 * @returns {Promise<object[]>}
 */
export const isModelRefreshing = (programId, { fetch = authedFetch } = {}) => {
  return fetch(`${apiRoot}/data-streams/models/${programId}/refresh`, {
    method: "GET"
  })
    .then(res => res.json())
    .then(({ data }) => data);
};
