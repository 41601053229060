import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
} from "chart.js";

import annotationPlugin from "chartjs-plugin-annotation";
import { Line } from "react-chartjs-2";

import "chartjs-adapter-luxon";
import { lineAnnotation } from "@/_utils/program-chart.utils";
import { programManagementPreviewChartOptions } from "@/program-management/utils/chart.utils";
import { LegendButton } from "@/_components/LegendButton";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  annotationPlugin
);
ChartJS.defaults.font.family = "Gothan, sans-serif";

const omitProps = props => obj =>
  Object.fromEntries(
    Object.entries(obj).filter(([prop]) => !~props.indexOf(prop))
  );

/**
 *
 * @param {object} props
 * @param {string} [props.id]
 * @param {number} props.threshold
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {{timestamp: string, value: number}[]} props.data
 * @param {string} props.height
 * @param {string} [props.width]
 * @returns {JSX.Element}
 */
export const ProgramManagementPreviewChart = ({
  id = "program-management-preview-chart",
  threshold,
  energyProgram,
  data,
  width = "99%",
  height
}) => {
  const ref = useRef(null);

  const [hidden, setHidden] = useState({});

  const dataset = {
    datasets: [
      {
        label: "Imported model data",
        data: data.map(item => ({ x: item.timestamp, y: item.value })),
        borderColor: "#FFD204",
        backgroundColor: "#FFD204",
        tension: 0.77,
        legendType: "dotted-line"
      }
    ]
  };

  const annotations = [
    ...(threshold && !hidden["Threshold"]
      ? [
          lineAnnotation(threshold),
          lineAnnotation(threshold + 10, "transparent")
        ]
      : [])
  ];

  const chartOptions = programManagementPreviewChartOptions({
    beginAtZero: false,
    xMaxTicksLimit: 8,
    yMaxTicksLimit: 0,
    displayLegend: false,
    annotations,
    timezone: energyProgram.timezone
  });

  return (
    <>
      <div className="relative" style={{ width, height }}>
        <Line
          id={id}
          ref={ref}
          options={chartOptions}
          data={dataset}
          plugins={[Filler]}
          height={height}
        />
      </div>

      <div className="w-full text-center font-normal">
        {[
          ...dataset.datasets,
          {
            label: "Threshold",
            backgroundColor: "#000000",
            legendType: "line",
            hidden: hidden["Threshold"]
          }
        ].map(row => (
          <div className="inline-block" key={row.label}>
            <LegendButton
              color={row.backgroundColor}
              // @ts-ignore
              type={row.legendType || "dotted-line"}
              hidden={hidden[row.label]}
              {...{
                onClick: () =>
                  setHidden({
                    ...omitProps([row.label])(hidden),
                    ...(hidden[row.label] ? {} : { [row.label]: true })
                  })
              }}
            >
              <span className="font-normal">{row.label}</span>
            </LegendButton>
          </div>
        ))}
      </div>
    </>
  );
};

ProgramManagementPreviewChart.props = {
  id: PropTypes.string,
  threshold: PropTypes.number,
  energyProgram: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.string,
  width: PropTypes.string
};
