import { Layout } from "@/_components/Layout";
import {
  useInfiniteProgramNotificationsV2,
  useQueryEnergyPrograms
} from "@/_hooks";
import { DeliveryReportsFilters } from "@/delivery-reports/components/DeliveryReportsFilters";
import { OrganizationsTable } from "@/delivery-reports/components/OrganizationsTable";
import { Button, Select, Spinner } from "@enpowered/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";

/**
 * @typedef {object} DeliveryReportsFilter
 * @property {object} notifications
 * @property {string} notifications.label
 * @property {string} notifications.date
 * @property {string} notifications.time
 * @property {object} organizations
 * @property {string} organizations.status
 * @property {string} organizations.name
 * @property {object} recipients
 * @property {string} recipients.status
 * @property {string} recipients.name
 * @property {string} recipients.email
 * @property {string} recipients.phone
 * @property {object} energyPrograms
 * @property {string} energyPrograms.id
 */

const filtersKey = "delivery-reports-filters";

export const RPN_STATUSES = [
  { value: "NONE_SENT", label: "Not Applicable" },
  { value: "DELIVERED", label: "Delivered" },
  { value: "NOT_DELIVERED", label: "Not Delivered" },
  { value: "SENT", label: "Sent" },
  { value: "PENDING", label: "Pending" }
];

export const DeliveryReportsPage = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState(
    /** @type {Partial<DeliveryReportsFilter>}*/ (null)
  );
  const [expandedNotifications, setExpandedNotifications] = useState(
    /** @type {string[]} */ ([])
  );
  const { ref, inView } = useInView();

  const {
    data: { items: energyPrograms } = { items: [] },
    isLoading: isLoadingEnergyPrograms
  } = useQueryEnergyPrograms({
    itemsPerPage: 100
  });

  const {
    data: deliveryReportNotifications,
    isLoading: isEnumerateOrganizationProgramNotificationLoading,
    isFetchingNextPage: isFetchingNextPageNotifications,
    fetchNextPage: getNextPageNotifications,
    hasNextPage,
    isError,
    error
  } = useInfiniteProgramNotificationsV2({
    programId: filters?.energyPrograms?.id || undefined,
    date: filters?.notifications?.date
      ? DateTime.fromISO(filters.notifications.date).toFormat("yyyy-MM-dd")
      : undefined,
    time: filters?.notifications?.time
      ? DateTime.fromISO(filters.notifications.time).toFormat("HH:mm")
      : undefined,
    label: filters?.notifications?.label || undefined,
    status: filters?.organizations?.status || undefined,
    orgName: filters?.organizations?.name || undefined,
    rpnStatus: filters?.recipients?.status || undefined,
    email: filters?.recipients?.email || undefined,
    phoneNumber: filters?.recipients?.phone || undefined,
    name: filters?.recipients?.name || undefined
  });

  const notifications = useMemo(
    () =>
      (deliveryReportNotifications?.pages || []).flatMap(page => page.items),
    [deliveryReportNotifications]
  );

  const filterChips = useMemo(() => {
    const chips = [];

    if (!filters) return chips;

    if (filters.notifications?.label) {
      chips.push({
        text: `Notification Label: ${filters.notifications.label}`,
        key: "notifications.label"
      });
    }
    if (filters.notifications?.date) {
      chips.push({
        text: `Notification Date: ${DateTime.fromISO(
          filters.notifications.date
        ).toFormat("MM/dd/yyyy")}`,
        key: "notifications.date"
      });
    }
    if (filters.notifications?.time) {
      chips.push({
        text: `Notification Time: ${DateTime.fromISO(
          filters.notifications.time
        ).toFormat("hh:mm a")}`,
        key: "notifications.time"
      });
    }

    if (filters.organizations?.status) {
      chips.push({
        text: `Org Status: ${filters.organizations.status}`,
        key: "organizations.status"
      });
    }

    if (filters.organizations?.name) {
      chips.push({
        text: `Org Name: ${filters.organizations?.name}`,
        key: "organizations.name"
      });
    }

    if (filters.recipients?.status) {
      chips.push({
        text: `Recipient Status: ${
          RPN_STATUSES.find(({ value }) => value === filters.recipients.status)
            ?.label
        }`,
        key: "recipients.status"
      });
    }

    if (filters.recipients?.name) {
      chips.push({
        text: `Recipient Name: ${filters.recipients.name}`,
        key: "recipients.name"
      });
    }

    if (filters.recipients?.email) {
      chips.push({
        text: `Recipient Email: ${filters.recipients.email}`,
        key: "recipients.email"
      });
    }

    if (filters.recipients?.phone) {
      chips.push({
        text: `Recipient Phone: ${filters.recipients.phone}`,
        key: "recipients.phone"
      });
    }

    if (filters.energyPrograms?.id) {
      chips.push({
        text: `Energy Program: ${filters.energyPrograms.id.toUpperCase()}`,
        key: "energyPrograms.id"
      });
    }
    return chips;
  }, [filters]);

  const onRemoveFilter =
    /** @param {string} key */
    key => {
      setFilters(oldFilters => {
        let copy = JSON.parse(JSON.stringify(oldFilters));
        _.unset(copy, key);
        return copy;
      });
      setExpandedNotifications([]);
    };

  const toggleNotificationExpanded = notificationId => {
    setExpandedNotifications(
      expandedNotifications.includes(notificationId)
        ? expandedNotifications.filter(id => id !== notificationId)
        : [...expandedNotifications, notificationId]
    );
  };

  const isLoading =
    isLoadingEnergyPrograms ||
    isEnumerateOrganizationProgramNotificationLoading;

  useEffect(() => {
    const storedFilters = localStorage.getItem(filtersKey);
    if (storedFilters) {
      setFilters(JSON.parse(storedFilters));
    }
  }, []);

  useEffect(() => {
    if (inView && hasNextPage) {
      getNextPageNotifications();
    }
  }, [getNextPageNotifications, inView, hasNextPage]);

  useEffect(() => {
    if (filters === null) return;

    localStorage.setItem(filtersKey, JSON.stringify(filters));
  }, [filters]);

  return (
    <Layout pageTitle="Delivery Reports">
      <DeliveryReportsFilters
        isOpen={showFilter}
        onClose={() => setShowFilter(false)}
        onFilter={
          /** @param {Partial<DeliveryReportsFilter>} filters*/
          filters => {
            setFilters(filters);
            setExpandedNotifications([]);
            setShowFilter(false);
          }
        }
        filters={filters || {}}
        energyPrograms={energyPrograms}
      />
      <div className="p-4 grid grid-rows-[auto_1fr] gap-4 items-center h-full">
        <div className="grid grid-cols-[1fr_auto] gap-4">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-bold fles justify-center gap-4">
              <span>Delivery Reports</span>{" "}
              {(isLoading || isFetchingNextPageNotifications) && (
                <Spinner size={12} />
              )}
            </h1>
            <div className="flex gap-2 items-center flex-wrap">
              {filterChips.map(({ text, key }) => (
                <span
                  key={key}
                  className="bg-en-gray-800 rounded-full pl-4 pr-2 py-1 text-white flex gap-2 items-center"
                >
                  <span>{text}</span>
                  <button
                    onClick={() => onRemoveFilter(key)}
                    className="rounded-full w-4 h-4 text-black bg-white text-xs flex items-center justify-center p-0"
                  >
                    X
                  </button>
                </span>
              ))}
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <Select>
              <option value="1">Date asc</option>
              <option value="2">Date Desc</option>
            </Select>
            <Button theme="dark" onClick={() => setShowFilter(true)}>
              <i className="fa-solid fa-sliders"></i>
            </Button>
          </div>
        </div>

        <div className="bg-white shadow-md p-4 h-full w-full">
          {!isLoading && notifications.length === 0 && (
            <div className="flex justify-center items-center h-full w-full">
              <p className="font-semibold">
                {"We haven't found any results with the selected filters."}
              </p>
            </div>
          )}
          {isError && (
            <div className="flex justify-center items-center h-full w-full">
              {error.message || "An error occurred. Please try again later."}
            </div>
          )}
          <div className="relative h-full w-full">
            <div className="notifications-container overflow-auto h-full w-full absolute top-0 left-0">
              {notifications.map((notification, index) => {
                const date = DateTime.fromISO(
                  notification.notificationSchedule
                ).toFormat("MM/dd/yyyy hh:mm a");
                return (
                  <div
                    key={index}
                    className="w-full relative border-b-2 border-en-gray-900"
                  >
                    <div
                      onClick={() =>
                        toggleNotificationExpanded(notification.notificationId)
                      }
                      className="cursor-pointer absolute top-0 z-[29] bg-white py-4 pr-4 w-full grid grid-cols-[auto_1fr_auto] items-center gap-4"
                    >
                      <FontAwesomeIcon
                        icon={
                          expandedNotifications.includes(
                            notification.notificationId
                          )
                            ? "caret-down"
                            : "caret-right"
                        }
                      />

                      <h1 className="text-2xl font-bold text-en-gray-900">
                        {notification.notificationLabel
                          ? notification.notificationLabel
                          : `No Label (ID: ${notification.notificationId})`}
                      </h1>
                      <div className="flex flex-col items-end">
                        <span className="font-semibold">
                          {notification.programId?.toUpperCase()}
                        </span>
                        <span>{date}</span>
                      </div>
                    </div>
                    <div
                      onClick={() =>
                        toggleNotificationExpanded(notification.notificationId)
                      }
                      className="cursor-pointer sticky top-0 z-[28] bg-en-yellow-200 py-4 pr-4 w-full grid grid-cols-[auto_1fr_auto] items-center gap-4"
                    >
                      <FontAwesomeIcon
                        icon={
                          expandedNotifications.includes(
                            notification.notificationId
                          )
                            ? "caret-down"
                            : "caret-right"
                        }
                      />
                      <h1 className="text-2xl font-bold text-en-gray-900">
                        {notification.notificationLabel
                          ? notification.notificationLabel
                          : `No Label (ID: ${notification.notificationId})`}
                      </h1>
                      <div className="flex flex-col items-end">
                        <span className="font-semibold">
                          {notification.programId?.toUpperCase()}
                        </span>
                        <span>{date}</span>
                      </div>
                    </div>
                    {expandedNotifications.includes(
                      notification.notificationId
                    ) && (
                      <OrganizationsTable
                        filters={filters}
                        notificationId={notification.notificationId}
                      />
                    )}
                  </div>
                );
              })}

              <div ref={ref} className="flex p-4 justify-center items-center">
                {isFetchingNextPageNotifications && <Spinner />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
