import { Spinner } from "@enpowered/ui";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { assert, ErrorBoundary } from "../../common";
import { getSecurityTokensForExternalPortal } from "./external.auth";

export * from "./external.auth";
export * from "./internal.auth";

const mfServerUrl =
  process.env.REACT_APP_MF_SERVER_ROOT || `http://localhost:32123`;

/**
 * @typedef {object} MicrofrontendApiRuntimeProps
 * @property {(credentials?: { email: string, password: string }) => Promise<{ accessToken: string, idToken: string, refreshToken: string }>} [getSecurityTokens]
 * @property {<TTokens>(tokens: TTokens) => Promise<TTokens>} [transformSecurityTokens]
 * @property {React.FC<{ onSubmit: (credentials?: any) => any }>} Login
 */

/**
 * Allows a Microfrontend to retrieve auth security tokens, that let it work directly with an EnPowered API service.
 * ONLY TO BE USED WHEN TESTING or in STORYBOOK!
 * 
 * @example
 * <AuthProvider
      manifests={[]}
      layout={{
        navItems: [],
        logout: () => console.log("logout")
      }}
    >
      {props => (
        <App
          {...props}
          Router={routerProps => (
            <InMemoryRouter {...routerProps} url="/settings/profile" />
          )}
        />
      )}
    </AuthProvider>
 * @type {
    React.FC<MicrofrontendApiRuntimeProps & 
    Partial<Omit<import("../../types").MicrofrontendMountProps, "user" | "fetch">> & 
    { 
        children: (props: Partial<import("../../types").MicrofrontendMountProps>) => JSX.Element, 
        fetch: { set: (f: ?FetchFunction) => FetchFunction, get: () => FetchFunction },
        [key: string]: any 
    }>}
 * 
 */
export const AuthProvider = ({
  getSecurityTokens,
  fetch,
  Login,
  transformSecurityTokens,
  children,
  ...props
}) => {
  const [user, setUser] = useState(
    /** @type {?import("../../types").EnpoweredUser} */ (null)
  );
  const logout = () => {
    localStorage.clear();
    setUser(null);
    fetch.set(null);
  };
  const [loading, setLoading] = useState(/** @type {boolean} */ (true));
  const [error, setError] = useState(/** @type {?string} */ (null));
  /** @param {{ accessToken: ?string, idToken: ?string, refreshToken: ?string }} tokens */
  const init = async (
    tokens = {
      accessToken: localStorage.getItem("accessToken"),
      idToken: null,
      refreshToken: null,
    }
  ) => {
    tokens = await assert(
      transformSecurityTokens,
      "transformSecurityTokens must be a function"
    )(tokens);
    if (!tokens?.accessToken) {
      return;
    }
    if (!(await isAccessTokenValid(tokens?.accessToken))) {
      return Promise.reject("InvalidToken");
    }
    fetch.set((uri, params) =>
      window.fetch(
        `${mfServerUrl}/proxy?url=${encodeURIComponent(uri.toString())}`,
        {
          ...params,
          headers: {
            ...params?.headers,
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      )
    );
    const user = await getCurrentUser({ fetch: fetch.get() });
    setUser(user);
  };
  /** @param {{ email: string, password: string }} credentials */
  const login = (credentials) => {
    setLoading(true);
    setError(null);
    return assert(
      getSecurityTokens,
      "getSecurityTokens must be a function"
    )(credentials)
      .then((tokens) => {
        return init(tokens);
      })
      .catch((error) => {
        setError(error.toString());
        logout();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    init()
      .catch(logout)
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const Component = useCallback(children, [user]);
  return (
    <>
      {user ? (
        <Component
          {...{
            ...props,
            user,
            fetch: fetch.get(),
            layout: {
              ...(props?.layout ? props?.layout : { navItems: [] }),
              logout,
            },
          }}
        />
      ) : (
        <div className="text-xl p-4 text-center block w-full">
          {loading ? (
            <Spinner size={24} />
          ) : (
            <>
              <div
                className={classNames("py-2", {
                  "bg-red-200 border border-red-500": error,
                })}
              >
                {error?.includes("Failed to fetch")
                  ? `No Proxy Server found running at ${mfServerUrl}`
                  : error?.includes("Bad Request")
                  ? `Invalid username or password`
                  : "No Security Tokens found."}
              </div>
              <div className="py-2">
                <Login onSubmit={(credentials) => login(credentials)} />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

AuthProvider.defaultProps = {
  getSecurityTokens: getSecurityTokensForExternalPortal,
  eventBus: { on: () => {}, off: () => {}, emit: () => {} },
  manifests: [],
  navigate: (...props) => console.log("navigate:", ...props),
  homedir: "/",
  ErrorBoundary: ({ children }) => (
    <ErrorBoundary Fallback={({ error }) => <div>Error: {error}</div>}>
      {children}
    </ErrorBoundary>
  ),
  layout: { logout: () => {}, navItems: [] },
  transformSecurityTokens: async (tokens) => tokens,
};

/**
 *
 * @param {string} accessToken
 * @returns {Promise<boolean>}
 */
async function isAccessTokenValid(accessToken) {
  const apiRootUrl = process.env.REACT_APP_API_ROOT;
  return fetch(
    `${mfServerUrl}/proxy?url=${encodeURIComponent(`${apiRootUrl}/auth/me`)}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).then((res) => (res.ok ? true : false));
}

/**
 *
 * @param {object} deps
 * @param {FetchFunction} deps.fetch
 * @returns {Promise<import("../../types").EnpoweredUser>}
 */
async function getCurrentUser({ fetch }) {
  const apiRootUrl = process.env.REACT_APP_API_ROOT;
  return fetch(`${apiRootUrl}/auth/me`, {
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) =>
      res.ok ? res.json() : Promise.reject(new Error(res.statusText))
    )
    .then((data) => {
      /** @type {import("../../types").EnpoweredUser} */
      const user = { id: data?.data?.id, ...data?.data?.attributes };
      return user;
    });
}
