import { RadioGroup, Spinner } from "@enpowered/ui";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

import { useGetProgramMeasures, useGetThreshold } from "@/_hooks";
import { getCPProgramInfo } from "@/_utils/coincident-peaks.utils";

import { PeakScenarioChart } from "./PeakScenarioChart";

/**
 *
 * @param {object} props
 * @param {import("@/_services").ProgramEvent[]} props.programEvents
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {import("@/_services").MarketDataset} props.marketDataset
 * @param {(image: string) => void} props.setChartImage
 * @returns {JSX.Element}
 */
export const NotificationChart = ({
  programEvents,
  energyProgram,
  marketDataset,
  setChartImage
}) => {
  const [activeModel, setActiveModel] = useState(
    marketDataset?.internal.models.find(model => model.default)
      ?.predictionSetName || null
  );

  const isCP = energyProgram.programType === "COINCIDENT_PEAK";
  const isDR = energyProgram.programType === "DEMAND_RESPONSE";

  const startDate = DateTime.fromISO(
    programEvents[0].eventIntervals[0].timestamp,
    { zone: energyProgram.timezone }
  ).startOf("day");
  const endDate = DateTime.fromISO(
    programEvents.slice(-1)[0].eventIntervals.slice(-1)[0].timestamp,
    { zone: energyProgram.timezone }
  )
    .plus({ hour: 1 })
    .endOf("day");

  const {
    data: programData = [],
    isLoading: isProgramMeasuresLoading
  } = useGetProgramMeasures(
    marketDataset,
    startDate.toUTC().toISO(),
    endDate.toUTC().toISO()
  );

  const {
    isLoading: isThresholdLoading,
    data: threshold = 0
  } = useGetThreshold(energyProgram.programId);

  const isLoading = isProgramMeasuresLoading || isThresholdLoading;

  const { enpoweredModels, groupedData } = useMemo(
    () => getCPProgramInfo(marketDataset, programData),
    [marketDataset, programData]
  );

  const chart = useMemo(() => {
    const onUpdateImage = chart => {
      const image = chart.toBase64Image();
      setChartImage(image);
      return Promise.resolve(true);
    };

    return isCP && !isLoading ? (
      <div className="border border-black rounded">
        <PeakScenarioChart
          groupedData={groupedData}
          timezone={energyProgram.timezone}
          modelNames={activeModel ? [activeModel] : []}
          threshold={Number(threshold || 0)}
          height="350px"
          width="100%"
          id={energyProgram?.programAdministrator}
          modalView
          onUpdateImage={onUpdateImage}
        />
      </div>
    ) : isCP && isLoading ? (
      // <Skeleton className="notification-chart-area" />
      <div> ... loading</div>
    ) : (
      <div
        className={`${
          isDR ? "notification-dr-area" : "notification-chart-area"
        }`}
      />
    );
  }, [
    isCP,
    isLoading,
    groupedData,
    energyProgram.timezone,
    energyProgram?.programAdministrator,
    activeModel,
    threshold,
    isDR,
    setChartImage
  ]);

  if (isLoading)
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spinner />
      </div>
    );

  return (
    <div className="grid grid-cols-[300px_1fr] h-full">
      <div className="border-r p-4 h-full justify-start">
        {
          // @ts-ignore
          <RadioGroup
            values={Object.keys(enpoweredModels).map(modelName => ({
              value: modelName,
              label: modelName
            }))}
            name="models"
            direction="vertical"
            size="small"
            // @ts-ignore
            onChange={e => setActiveModel(e.target.value)}
            initialValue={activeModel}
          />
        }
      </div>
      <div className="h-full p-4">{chart}</div>
    </div>
  );
};

NotificationChart.propTypes = {
  programEvents: PropTypes.array,
  energyProgram: PropTypes.object,
  marketDataset: PropTypes.object
};
