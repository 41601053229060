import classNames from "classnames/dedupe";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { ErrorBoundary, makeError } from "../../common";
import assert from "tiny-invariant";

import { loadMicrofrontend } from "./utils";

/**
 *
 * @typedef {object} MicrofrontendProps
 * @property {string} scope
 * @property {string} entry URL to remoteEntry.js file for Microfrontend
 * @property {string} module
 * @property {string} [url]
 * @property {string} [key]
 * @property {string} [id]
 * @property {any} [className]
 * @property {JSX.Element | (() => JSX.Element)} Loading displayed while Microfrontend is loading
 * @property {import("../../common").MicrofrontendErrorFallback} Fallback displayed if Microfrontend could not be loaded
 * @property {(href: string) => any} [navigate]
 * @property {import("../../types").MicroFrontendManifest[]} [manifests]
 * @property {import("../../types").EnpoweredUser} [user]
 * @property {(manifest: Pick<import("../../types").MicroFrontendManifest, "entry" | "scope" | "module">) => Promise<{ mount: (containerRef: string|HTMLElement, props: any) => () => {}, unmount: (containerRef: string|HTMLElement) => any }>} [loadMicrofrontend]
 */

/**
 * @type {React.FC<MicrofrontendProps>}
 */
export const Microfrontend = ({
  id,
  scope,
  entry,
  module,
  Loading,
  Fallback,
  className,
  loadMicrofrontend,
  ...props
}) => {
  const {
    isFetched: isMounted,
    isError,
    error,
    data: { mount } = {},
  } = useQuery(`microfrontend?entry=${entry}&module=${module}`, async () => {
    assert(loadMicrofrontend, "props.loadMicrofrontend must be a function");
    return loadMicrofrontend({ entry, scope, module });
  });

  const mfClassName = classNames(
    "microfrontend-container spin-when-empty",
    className
  );

  const containerId = `mount-${(id || scope).toLowerCase()}-container`;
  const [mfError, setMFError] = useState(/** @type {?Error} */ (null));
  const [retryCount, setRetryCount] = useState(0);

  /** @type {import("../../common").MicrofrontendErrorFallback} */
  const ErrorFallback = (errorProps) =>
    typeof Fallback === "function" ? (
      (<Fallback
        {...{
          containerId,
          scope,
          entry,
          module,
          ...props,
        }}
        {...errorProps}
        retry={() => setRetryCount(retryCount + 1)}
      />)
    ) : (
      (Fallback)
    );

  /** @type {React.FC<{ children: JSX.Element }>} */
  const _ErrorBoundary = ({ children }) => (
    <ErrorBoundary Fallback={ErrorFallback}>{children}</ErrorBoundary>
  );

  useEffect(() => {
    if (!isMounted || isError || typeof mount !== "function") {
      return;
    }
    /** @type {?(() => void)} */
    let unmount = null;
    try {
      unmount = mount(containerId, {
        ...props,
        ErrorBoundary: _ErrorBoundary,
      });
    } catch (error) {
      setMFError(
        makeError(
          "MountError",
          `Could not mount Microfrontend: ${scope} (${module})`,
          error
        )
      );
    }
    return () => {
      try {
        if (typeof unmount === "function") {
          console.log("unmount", scope);
          unmount();
        }
      } catch (err) {
        console.error(err);
        setMFError(
          makeError(
            "UnmountError",
            `Could not unmount Microfrontend: ${scope} (${module})`,
            error
          )
        );
      }
    };
  }, [isMounted, isError, entry, module, retryCount, props?.["user"]]);

  return isError ? (
    (<ErrorFallback
      error={
        error instanceof Error
          ? error
          : makeError(
              "UnknownError",
              typeof error === "string"
                ? error
                : `An error occurred in a microfrontend: ${error}`,
              error
            )
      }
    />)
  ) : mfError ? (
    (<ErrorFallback error={mfError} />)
  ) : !isMounted ? (
    (typeof Loading === "function" ? (
      <Loading />
    ) : (
      Loading
    ))
  ) : (
    (<div
      id={containerId}
      className={mfClassName}
      {...{ "data-mf-scope": scope, "data-mf-module": module }}
    ></div>)
  );
};

Microfrontend.defaultProps = {
  loadMicrofrontend,
};

Microfrontend.propTypes = {
  loadMicrofrontend: PropTypes.func,
};

export * from "./utils";
