import { Layout } from "@/_components/Layout";
import { ConnectionsList } from "@/usage-data/components/ConnectionsList";
import React from "react";
import { Link, Outlet } from "react-router-dom";

export const UsageDataPage = () => {
  return (
    <Layout pageTitle="Usage Data">
      <div className="grid grid-cols-[300px_1fr] h-full">
        <div>
          <ConnectionsList />
        </div>
        <div className="grid grid-rows-[auto_1fr] gap-4 p-4 max-h-full">
          <div className="flex justify-end">
            <Link
              to="/usage-data/new"
              className="ml-2 px-3 py-1 shadow-md border-2 border-solid border-gray-500 rounded-md font-bold text-lg bg-white hover:bg-yellow-400 flex gap-2 items-center"
            >
              <i className="fas fa-plus"></i>New Connection
            </Link>
          </div>
          <div className="relative">
            <div className="absolute top-0 left-0 w-full h-full overflow-auto">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
