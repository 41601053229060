import "./NotificationsPage.css";

import { Spinner } from "@enpowered/ui";
import { MobileDatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useMemo, useState } from "react";

import { Layout } from "@/_components/Layout";
import { useInfiniteProgramNotifications } from "@/_hooks";
// @ts-ignore
import { ReactComponent as CloseSVG } from "../assets/img/close.svg";

import { NotificationDetail } from "./components/NotificationDetail";
import { NotificationsList } from "./components/NotificationsList";

/**
 * @returns {JSX.Element}
 */
export const NotificationsPage = () => {
  const [date, setDate] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(
    /** @type {import("@/_services").ProgramNotification} */ (null)
  );

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    hasNextPage
  } = useInfiniteProgramNotifications({
    date: date ? date.toFormat("yyyy-MM-dd") : undefined
  });

  const allNotifications = useMemo(() => {
    const list = data?.pages.map(({ items }) => items).flat() || [];
    return list;
  }, [data?.pages]);

  useEffect(() => {
    if (!selectedNotification && !!allNotifications.length)
      setSelectedNotification(allNotifications[0]);
  }, [allNotifications, selectedNotification]);

  return (
    <Layout pageTitle="Notifications">
      <div className="noifications grid grid-cols-[300px_1fr] max-h-full h-full">
        <div className="p-4 grid grid-rows-[auto_1fr] gap-4 max-h-full">
          <div className="w-full pr-4 relative">
            <MobileDatePicker
              className="w-full [&>div>input]:text-sm [&>div>input]:leading-5 [&>div>input]:py-0 [&>div>input]:px-2 [&>div>input]:min-h-8 [&>div>input]:placeholder:text-sm [&>div>input]:placeholder:leading-5"
              onChange={() => {}}
              value={date}
              onAccept={value => {
                setDate(value);
              }}
            />
            {date && (
              <button
                className="absolute right-4 top-0 bottom-0 my-auto h-8 w-8"
                onClick={() => setDate(null)}
              >
                <CloseSVG />
              </button>
            )}
          </div>

          {isLoading && (
            <div className="w-full max-h-full flex items-center justify-center">
              <Spinner />
            </div>
          )}
          {!!data?.pages && data.pages.length > 0 && (
            <NotificationsList
              notifications={allNotifications}
              getMoreData={hasNextPage ? fetchNextPage : () => {}}
              isFetchingNextPage={isFetchingNextPage}
              onSelectNotification={setSelectedNotification}
              selectedNotficationId={
                selectedNotification?.programNotificationId
              }
              hasNextPage={hasNextPage}
            />
          )}
        </div>
        {!!selectedNotification && (
          <NotificationDetail
            key={`${selectedNotification.programNotificationId}-${selectedNotification.status}`}
            notification={selectedNotification || allNotifications[0]}
            onUpdate={setSelectedNotification}
          />
        )}
      </div>
    </Layout>
  );
};
