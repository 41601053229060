import { MicrofrontendContext } from "@enpowered/microfrontends";
import { Button, Modal } from "@enpowered/ui";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useMutation } from "react-query";

import { AccountForm } from "./AccountForm";

/**
 *
 * @param {object} props
 * @param {import("@/_services").OrgProfile} props.account
 * @param {(account: import("@/_services").OrgProfile) => Promise<any>} props.onSubmit
 * @param {boolean} [props.canEditCompanyAccount]
 * @param {{ modal?: any }} [props.defaults]
 * @returns
 */
export const AccountInfoPanel = ({
  account,
  onSubmit,
  canEditCompanyAccount,
  defaults = {}
}) => {
  const { eventBus } = useContext(MicrofrontendContext);
  const [modal, setModal] = useState(defaults?.modal);

  const { mutate: updateAccount, isLoading, error } = useMutation(
    /** @type {(account: import("@/_services").OrgProfile) => Promise<any>} */
    account => onSubmit(account),
    {
      onSuccess: () => {
        setModal(null);
        eventBus?.emit(
          "customer-administration:enpowered-account:change",
          account
        );
      }
    }
  );

  return (
    <div className="bg-white p-3 mb-4 w-full border-solid border-2 rounded-md border-en-gray-100 flex justify-between items-center">
      <div>
        <h2 className="font-bold text-lg">Organization</h2>
        <span>{account.name}</span>
      </div>
      {canEditCompanyAccount ? (
        <Button
          size="narrow"
          theme="dark"
          className="py-2"
          // @ts-ignore
          onClick={() => {
            setModal("AccountFormModal");
          }}
        >
          Edit Organization Details
        </Button>
      ) : null}

      <Modal isOpen={modal === "AccountFormModal"} size="lg">
        <AccountForm
          account={account}
          onSubmit={updateAccount}
          // @ts-ignore
          error={error}
          isNewAccount
          isLoading={isLoading}
          onCancel={() => setModal(null)}
          title="Edit Account Details"
        />
      </Modal>
    </div>
  );
};

AccountInfoPanel.propTypes = {
  account: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  canEditCompanyAccount: PropTypes.bool
};
