import { Button, Modal, ModalTitle } from "@enpowered/ui";
import React from "react";

import { DeliveryStatusChip } from "./DeliveryStatusChip";

/**
 *
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {{
 *  title: string;
 *  status: string;
 *  message: string;
 *  destination: string;
 *  content: string;
 * }} props.notification
 * @returns
 */
export const NotificationModal = ({ isOpen, onClose, notification }) => {
  return (
    <Modal size="full" isOpen={isOpen}>
      <div>
        <ModalTitle size="full" className="-mx-6 -mt-4">
          <>
            <div className="flex justify-start items-center gap-4">
              <span>{notification.title}</span>
              <DeliveryStatusChip
                text={notification.status}
                error={notification.status === "NOT_DELIVERED"}
              />
            </div>
            <Button
              onClick={onClose}
              size="narrow"
              theme="transparent"
              className={{
                "bg-en-yellow-700": false,
                "focus:outline-none": false,
                "px-2": true,
                "px-4": false
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.251"
                height="14.251"
                viewBox="0 0 14.251 14.251"
              >
                <rect
                  width="17.995"
                  height="2.159"
                  rx="1.08"
                  transform="translate(1.527) rotate(45)"
                  fill="currentColor"
                />
                <rect
                  data-name="Rectangle 146"
                  width="17.995"
                  height="2.159"
                  rx="1.08"
                  transform="translate(14.252 1.527) rotate(135)"
                  fill="currentColor"
                />
              </svg>
            </Button>
          </>
        </ModalTitle>
        {notification.status === "NOT_DELIVERED" && !!notification.message && (
          <div className="py-4 px-6 bg-red-600 text-white -mx-6">
            {notification.message}
          </div>
        )}
        <div className="flex flex-col gap-4 mt-4">
          <div>To: {notification.destination}</div>
          <div
            className="bg-white"
            dangerouslySetInnerHTML={{ __html: notification.content }}
          ></div>
        </div>
      </div>
    </Modal>
  );
};
