(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-query"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-query", "react-router-dom"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("react-query"), require("react-router-dom")) : factory(root["React"], root["ReactQuery"], root["ReactRouterDOM"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, function(__WEBPACK_EXTERNAL_MODULE__244__, __WEBPACK_EXTERNAL_MODULE__141__, __WEBPACK_EXTERNAL_MODULE__215__) {
return 