import React from "react";
import PropTypes from "prop-types";
import { CollapsibleCard } from "@enpowered/ui";
import { EventItem } from "./EventItem";
import { DateTime } from "luxon";

/**
 *
 * @param {import("@/_services").ProgramEvent[]} events
 * @returns {JSX.Element}
 */
const getTitle = events => {
  return (
    <div>
      <span className="font-bold">
        {`${events.length} Event${events.length > 1 ? "s " : ""}`}{" "}
      </span>{" "}
      - Click to Expand
    </div>
  );
};

/**
 *
 * @param {object} props
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {boolean} props.isOpen
 * @param {(programId: string) => void} props.onToggle
 * @param {import("@/_services").ProgramEvent[]} props.programEvents
 * @param {import("@/_services").ProgramEvent[]} props.selectedProgramEvents
 * @param {(programEvents: import("@/_services").ProgramEvent[]) => void} props.onSelectProgramEvent
 * @param {(programEvents: import("@/_services").ProgramEvent[], value: boolean) => void} props.onCheckProgramEvents
 * @param {import("@/_services").ProgramEvent[]} props.checkedProgramEvents
 * @returns {JSX.Element}
 */

export const EventGroup = ({
  energyProgram,
  isOpen,
  onToggle,
  programEvents,
  onSelectProgramEvent,
  onCheckProgramEvents,
  checkedProgramEvents,
  selectedProgramEvents
}) => {
  if (programEvents.length === 0) return null;

  const groups = Object.entries(
    programEvents.reduce((output, event) => {
      const key = DateTime.fromISO(event.eventIntervals[0].timestamp, {
        zone: energyProgram.timezone
      })
        .startOf("day")
        .toMillis();
      if (output[key]) output[key].push(event);
      else output[key] = [event];
      return output;
    }, {})
  )
    .sort(([a], [b]) => +a - +b)
    .map(([, event]) => event);

  const selectedProgramEventsIds = selectedProgramEvents.map(
    ({ programEventId }) => programEventId
  );

  return (
    <div>
      <h2 className="mb-2">
        <span className="font-bold">{energyProgram.programAdministrator}</span>
        {" - "}
        {energyProgram.name}
      </h2>
      <CollapsibleCard
        isOpen={isOpen}
        onClick={() => onToggle && onToggle(energyProgram.programId)}
        title={getTitle(programEvents)}
        className="w-full bg-en-gray-100 border border-en-gray-200"
      >
        <div className="flex flex-col gap-2">
          {groups.map(events => (
            <EventItem
              key={events[0].programEventId}
              energyProgram={energyProgram}
              // @ts-ignore
              programEvents={events}
              selected={selectedProgramEventsIds.includes(
                events[0].programEventId
              )}
              checked={
                !!checkedProgramEvents?.find(
                  e => e.programEventId === events[0].programEventId
                )
              }
              onSelect={() => onSelectProgramEvent(events)}
              onCheck={value => onCheckProgramEvents(events, value)}
            />
          ))}
        </div>
      </CollapsibleCard>
    </div>
  );
};

EventGroup.propTypes = {
  energyProgram: PropTypes.object,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  programEvents: PropTypes.arrayOf(PropTypes.object),
  seelctedProgramEvents: PropTypes.arrayOf(PropTypes.object),
  onSelectProgramEvent: PropTypes.func,
  onCheckProgramEvents: PropTypes.func,
  checkedProgramEvents: PropTypes.arrayOf(PropTypes.object)
};

EventGroup.defaultProps = {
  isOpen: false
};
