import {
  getCustomModelData,
  getDefaultModelData,
  getExistingCustomModels,
  isModelRefreshing,
  refreshModel,
  uploadCustomModel
} from "@/_services";
import { useMutation, useQuery, useQueryClient } from "react-query";

/**
 *
 * @param {string} energyProgramId
 * @returns {import("react-query").UseQueryResult<{timestamp: string, value: string}[], any>}
 */
export const useDefaultModelData = energyProgramId =>
  useQuery(["default-model-data", energyProgramId], () =>
    getDefaultModelData(energyProgramId)
  );

/**
 *
 * @param {string} energyProgramId
 * @param {{[key: string]: any}} options
 * @returns {import("react-query").UseQueryResult<{timestamp: string, value: string}[], any>}
 */
export const useCustomModelData = (energyProgramId, options = {}) =>
  useQuery(
    ["custom-model-data", energyProgramId],
    () => getCustomModelData(energyProgramId),
    options
  );

/**
 *
 * @returns {import("react-query").UseQueryResult<{[key: string]: boolean}, any>}
 */
export const useExistingCustomModels = () =>
  useQuery("existing-custom-models", () => getExistingCustomModels());

/**
 *
 * @param {(res: {timestamp: string, value: string}[], attributes: {programId: string, file: File}) => void} onSuccess
 * @returns {import("react-query").UseMutationResult<any[], { message: string, errors?: { detail: string }[] }, any, unknown>}
 */

export const useUploadCustomModel = (onSuccess = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    /**
     * @param {{programId: string, file: File}} props
     */
    props => uploadCustomModel(props.programId, props.file),
    {
      onSuccess: (res, attributes) => {
        queryClient.invalidateQueries(["custom-model-data"]);
        queryClient.invalidateQueries(["existing-custom-models"]);
        onSuccess && onSuccess(res, attributes);
      }
    }
  );
};

/**
 *
 * @param {(res: {id: string, jobName: string}[], programId: string) => void} onSuccess
 * @returns {import("react-query").UseMutationResult<any[], { message: string, errors?: { detail: string }[] }, any, unknown>}
 */
export const useRefreshModel = onSuccess =>
  useMutation(
    /**
     * @param {string} programId
     */
    programId => refreshModel(programId),
    {
      onSuccess: (res, programId) => onSuccess && onSuccess(res, programId)
    }
  );

/**
 *
 * @param {string} programId
 * @param {Omit<import("react-query").UseQueryOptions<any[], any, boolean, ["refreshing-model", string]>, "queryKey" | "queryFn">} [options]
 * @returns {import("react-query").UseQueryResult<boolean, any>}
 */
export const useIsModelRefreshing = (programId, options = {}) =>
  useQuery(
    ["refreshing-model", programId],
    () => isModelRefreshing(programId),
    options
  );
