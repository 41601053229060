import "chartjs-adapter-luxon";

import { Button, Skeleton, Spinner } from "@enpowered/ui";
import {
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { chartOptions, lineAnnotation } from "@/_utils/program-chart.utils";
import { LegendButton } from "@/_components/LegendButton";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  annotationPlugin
);
ChartJS.defaults.font.family = "Gothan, sans-serif";
ChartJS.defaults.backgroundColor = "#FFFFFF";

const modelColors = ["#FFD204", "#10E7E2", "#FF68A8", "#CA7CD8", "#3968CB"]; // Colors for the different models

const omitProps = props => obj =>
  Object.fromEntries(
    Object.entries(obj).filter(([prop]) => !~props.indexOf(prop))
  );

/**
 *
 * @param {object} props
 * @param {string} [props.id]
 * @param {object} props.groupedData
 * @param {string} props.timezone
 * @param {string[]} props.modelNames
 * @param {number} props.threshold
 * @param {string} props.height
 * @param {string} props.width
 * @param {boolean} [props.noLegend]
 * @param {boolean} [props.modalView]
 * @param {(chart: any) => any} [props.onUpdateImage]
 *
 * @returns {JSX.Element}
 */
export const PeakScenarioChart = ({
  id = "coincident-peaks-chart",
  groupedData,
  timezone,
  modelNames,
  threshold,
  height,
  width,
  noLegend,
  modalView,
  onUpdateImage
}) => {
  const chartRef = useRef(null);
  /**
   * @type {[{[key: string]: boolean}, any]} hiddenState
   */
  const [hidden, setHidden] = useState({ "Realtime Demand": true });
  const [isUpdating, setIsUpdating] = useState(false);

  const filteredData = groupedData.reduce((output, item) => {
    Object.entries(item).forEach(([key, value]) => {
      if (key !== "datetime") {
        const dataPoint = {
          x: DateTime.fromISO(item.datetime, { zone: timezone }).toISO(),
          y: value
        };

        if (output[key]) output[key].push(dataPoint);
        else output[key] = [dataPoint];
      }
    });

    return output;
  }, {});

  const data = {
    datasets: [
      {
        label: `Administrator Prediction`,
        data: hidden[`Administrator Prediction`]
          ? []
          : filteredData["administratorPredictions"],
        borderColor: "#bbb",
        backgroundColor: "#bbb",
        tension: 0.77,
        legendType: "dotted-line"
      },
      {
        label: `Realtime Demand`,
        data: hidden[`Realtime Demand`] ? [] : filteredData["demand"],
        borderColor: "red",
        backgroundColor: "red",
        tension: 0.77,
        legendType: "dot"
      },
      {
        label: `Hourly Demand`,
        data: hidden[`Hourly Demand`] ? [] : filteredData["demandAverage"],
        borderColor: "red",
        backgroundColor: "red",
        tension: 0.77,
        legendType: "dotted-line"
      },
      ...modelNames.map((modelName, index) => ({
        label: modelName,
        data: hidden[modelName] ? [] : filteredData[modelName],
        borderColor: modelColors[index],
        backgroundColor: modelColors[index],
        tension: 0.77,
        legendType: "dotted-line"
      }))
    ]
  };

  let annotations = [
    ...(threshold && !hidden["Threshold"]
      ? [
          lineAnnotation(threshold),
          lineAnnotation(threshold + 10, "transparent")
        ]
      : [])
  ];

  const options = chartOptions({
    beginAtZero: noLegend ? true : false,
    xMaxTicksLimit: modalView ? 7 : 4,
    yMaxTicksLimit: modalView ? 10 : 4,
    displayLegend: false,
    annotations
  });

  const updateImage = () => {
    setIsUpdating(true);
    onUpdateImage(chartRef.current).then(() => setIsUpdating(false));
  };

  return groupedData.length > 0 ? (
    <>
      <div style={{ width, height, position: "relative" }}>
        <Line
          id={id}
          ref={chartRef}
          options={options}
          data={data}
          plugins={[
            Filler,
            {
              id: "ChartBG",
              beforeDraw: chart => {
                const { ctx } = chart;
                ctx.save();
                ctx.globalCompositeOperation = "destination-over";
                ctx.fillStyle = "#FFFFFF";
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
              }
            }
          ]}
        />
      </div>

      <div className="w-full text-center font-normal">
        {[
          ...data.datasets,
          {
            label: "Threshold",
            backgroundColor: "#000000",
            legendType: "line",
            hidden: hidden["Threshold"]
          }
        ].map(row => (
          <div className="inline-block" key={row.label}>
            <LegendButton
              color={row.backgroundColor}
              // @ts-ignore
              type={row.legendType || "dotted-line"}
              hidden={hidden[row.label]}
              {...{
                onClick: () =>
                  setHidden({
                    ...omitProps([row.label])(hidden),
                    ...(hidden[row.label] ? {} : { [row.label]: true })
                  })
              }}
            >
              <span className="font-normal">{row.label}</span>
            </LegendButton>
          </div>
        ))}
      </div>

      {onUpdateImage && (
        <div className="flex justify-end p-2">
          <Button
            className="bg-black text-white update-image-btn"
            theme="none"
            // @ts-ignore
            onClick={updateImage}
            size="narrow"
            type="button"
          >
            {isUpdating ? <Spinner size="1rem" /> : "Update Image"}
          </Button>
        </div>
      )}
    </>
  ) : (
    <Skeleton {...{ style: { height } }} />
  );
};

PeakScenarioChart.propTypes = {
  groupedData: PropTypes.array,
  timezone: PropTypes.string,
  modelNames: PropTypes.array,
  threshold: PropTypes.number,
  id: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  noLegend: PropTypes.bool,
  modalView: PropTypes.bool,
  onUpdateImage: PropTypes.func
};
