import PropTypes from "prop-types";
import React from "react";

import { Modal } from "@enpowered/ui";

/**
 *
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {string} props.message
 * @param {() => any} props.onContinue
 * @param {() => any} props.onDiscard
 * @returns {JSX.Element}
 */
export const EditDiscardPromptModal = ({
  isOpen,
  message,
  onContinue,
  onDiscard
}) => {
  return (
    <Modal isOpen={isOpen} size="lg">
      <div className="flex flex-col">
        <div className="flex flex-col items-center justify-center">
          <label className="font-semibold mb-3">Warning!</label>
          <label className="text-center mb-3">{message || ""}</label>
        </div>
        <div className="self-end">
          <button
            className="m-1 py-1 px-2 rounded bg-green-300"
            onClick={onContinue}
          >
            Return to editing
          </button>
          <button
            className="m-1 py-1 px-2 rounded bg-red-300"
            onClick={onDiscard}
          >
            Discard
          </button>
        </div>
      </div>
    </Modal>
  );
};

EditDiscardPromptModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  next: PropTypes.object,
  promptCompleted: PropTypes.func,
  message: PropTypes.node
};
