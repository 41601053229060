import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useEnumerateUserProfiles } from "@/_hooks";
import { PortalUserListItem } from "@/users/components/PortalUserListItem";
import { PortalUserForm } from "@/users/components/PortalUserForm";

/**
 *
 * @param {object} props
 * @param {string} props.filterTerm
 * @param {string} props.portalUserId
 * @returns {JSX.Element}
 */
export const PortalUsersBody = ({ filterTerm, portalUserId }) => {
  const { isLoading, isFetched, data: users = [] } = useEnumerateUserProfiles({
    itemsPerPage: 60
  });

  const navigate = useNavigate();

  const [selectedPortalUser, setSelectedPortalUser] = useState(null);
  /**
   *
   * @param {import("@/_services").UserProfile} user
   */
  const selectPortalUser = user => {
    setSelectedPortalUser(user);
    navigate(`/users/${user.userId}`);
  };

  /**
   *
   * @param {string} term
   * @param {string[]} params
   * @returns {number}
   */
  const search = (term, params = []) => {
    return term
      ?.toLowerCase()
      ?.split(" ")
      ?.reduce((matchSum, chunk) => {
        const matchingParamsCount = params.filter(param =>
          param?.toLowerCase()?.includes(chunk)
        ).length;
        return matchingParamsCount ? matchSum + matchingParamsCount : matchSum;
      }, 0);
  };

  const filteredUsers = users
    .map(({ given_name = "", family_name = "", email, ...more }) => ({
      given_name,
      family_name,
      email,
      fullName: `${given_name} ${family_name}`,
      ...more,
      match: search(filterTerm, [given_name, family_name, email])
    }))
    .filter(({ match }) => match)
    .sort((a, b) => {
      if (a.fullName < b.fullName) {
        return -1;
      }
      if (a.fullName > b.fullName) {
        return 1;
      }
      return 0;
    });

  useEffect(() => {
    setSelectedPortalUser(users.find(user => user.userId === portalUserId));
    const listItem = document.getElementById(
      `portal-user-list-item-${portalUserId}`
    );
    if (listItem) {
      listItem.scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [portalUserId, users]);

  useEffect(() => {
    setSelectedPortalUser(null);
  }, [filterTerm]);

  return (
    <div
      className={`max-h-full ${
        filteredUsers.length ? "flex flex-row" : "flex-1"
      }`}
    >
      <div className="max-h-full bg-white overflow-y-auto custom-scroller border-2 rounded-md border-en-gray-100">
        <div className="spin-when-empty">
          {filteredUsers.length ? (
            filteredUsers.map(user => (
              <PortalUserListItem
                key={user.userId}
                selected={user.userId === portalUserId}
                portalUser={user}
                onClick={
                  /**
                   * @param {import("@/_services").UserProfile} user
                   */
                  async user => selectPortalUser(user)
                }
                {...{ id: `portal-user-list-item-${user.userId}` }}
              />
            ))
          ) : isFetched ? (
            <div className="w-full text-center text-xl p-4">
              No Portal Users
              {filterTerm !== "" ? " matching this search query" : ""} were
              found
            </div>
          ) : null}
        </div>
        {isLoading && <LoadingNotification />}
      </div>
      {/* customer form */}
      {selectedPortalUser && (
        <div className="ml-4 rounded-md border-2 border-en-gray-100 overflow-hidden self-start">
          <PortalUserForm onSubmit={() => {}} portalUser={selectedPortalUser} />
        </div>
      )}
    </div>
  );
};

PortalUsersBody.propTypes = {
  filterTerm: PropTypes.string,
  portalUserId: PropTypes.string
};

const LoadingNotification = () => {
  return (
    <div className="h-14 bg-yellow-200 w-full p-3 flex flex-row items-center justify-center">
      <span className="fa-2x mr-3">
        <i className="fa fa-circle-notch fa-pulse text-blue-500" />
      </span>
      <span>Loading...</span>
    </div>
  );
};
