import { useMutation, useQuery, useQueryClient } from "react-query";
import { getThreshold, updateThreshold } from "../_services";

/**
 *
 * @param {string} energyProgramId
 * @returns
 */
export const useGetThreshold = energyProgramId =>
  useQuery(
    ["threshold", energyProgramId],
    () => getThreshold(energyProgramId),
    {
      staleTime: 300000
    }
  );

/**
 * @param {(params: any) => void} success
 * @returns
 */
export const useSetThreshold = (success = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    /**
     * @param {object} params
     * @param {string} params.energyProgramId
     * @param {number} params.threshold
     */
    params => updateThreshold(params.energyProgramId, params.threshold),
    {
      onSuccess: (res, attributes) => {
        queryClient.invalidateQueries(["threshold"]);

        success && success({ ...res, ...attributes });
      }
    }
  );
};
