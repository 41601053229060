import { cn } from "@/_utils/cn";
import { DateTime } from "luxon";
import React from "react";
import { Link } from "react-router-dom";

/**
 *
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.organizationName
 * @param {string} props.status
 * @param {string} props.lastPull
 * @param {boolean} props.selected
 * @param {string} props.to
 * @returns
 */
export const ConnectionItem = ({
  label,
  organizationName,
  status,
  lastPull,
  selected,
  to
}) => {
  return (
    <Link
      to={to}
      className={cn(
        "shadow-md border-2 border-solid rounded-md p-2 block w-full",
        {
          "bg-en-yellow-300 border-en-yellow-700": selected,
          "bg-white border-en-gray-200 hover:bg-yellow-400": !selected
        }
      )}
    >
      <div className="mb-2">
        <span
          className={cn("py-1 px-2 text-xs rounded-md", {
            "bg-green-300 text-green-700": status === "AUTHORIZED",
            "bg-yellow-300 text-yellow-700": status === "PENDING",
            "bg-red-300 text-red-700": status === "EXPIRED"
          })}
        >
          {status}
        </span>
      </div>
      <div className="truncate font-semibold">{label}</div>
      <div className="truncate">{organizationName}</div>
      {lastPull
        ? `Last data pull: ${DateTime.fromISO(lastPull).toRelative({
            style: "long"
          })}`
        : "No data pulls"}
    </Link>
  );
};
