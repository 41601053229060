import { getIdToken, logout, refreshSession } from "@/_services";
import { handleHttpError } from "./handleHttpError";

// export const authedFetch = (uri, params = {}) =>
//   getIdToken().then(accessToken =>
//     fetch(uri, {
//       ...params,
//       headers: {
//         ...(params?.headers || {}),
//         Authorization: `Bearer ${accessToken}`
//       }
//     }).then(handleHttpError)
//   );

export const authedFetch = async (uri, params) => {
  const accessToken = await getIdToken();
  let request = await fetch(uri, {
    ...params,
    headers: {
      ...params?.headers,
      Authorization: `Bearer ${accessToken}`
    }
  });

  // Refresh token in case it is expired
  if ([403, 401].includes(request.status)) {
    try {
      await refreshSession();
      const newAccessToken = await getIdToken();

      request = await fetch(uri, {
        ...params,
        headers: {
          ...params?.headers,
          Authorization: `Bearer ${newAccessToken}`
        }
      });

      if ([403, 401, 400].includes(request.status)) {
        await logout();
      }
    } catch (e) {
      console.error(e.message);
      const tokenErrors = [
        "User not logged in",
        "Missing required parameter auth parameters.",
        "Invalid Refresh Token",
        "Refresh Token has expired"
      ];
      if (tokenErrors.includes(e.message)) {
        await logout();
      }
    }
  }
  return handleHttpError(request);
};
