import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

/**
 *
 * @param {object} props
 * @param {string} props.icon
 * @param {string} [props.value]
 * @param {"before" | "after"} [props.position]
 * @param {boolean} [props.disabled]
 * @param {React.MouseEventHandler<HTMLElement>} props.onClick
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
export const IconButton = ({
  icon,
  value,
  position = "after",
  disabled,
  onClick,
  className
}) => {
  return (
    <span
      className={classNames(className, {
        "": disabled,
        "cursor-pointer": !disabled
      })}
      onClick={onClick}
    >
      {value && position === "before" && value}
      <i
        className={classNames({
          [icon]: !!icon,
          fas: icon.startsWith("fa"),
          "material-symbols-outlined": !icon.startsWith("fa"),
          "text-gray-500": disabled,
          "text-black": !disabled,
          "mx-1": !!value
        })}
      >
        {!icon.startsWith("fa") && icon}
      </i>
      {value && position === "after" && value}
    </span>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  value: PropTypes.string,
  position: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string
};
