import { Button } from "@enpowered/ui";
import classNames from "classnames";
import React from "react";

/**
 *
 * @param {object} props
 * @param {string} props.color
 * @param {any} props.children
 * @param {boolean} [props.hidden]
 */
const DottedLineButton = ({ color, children, hidden, ...props }) => {
  return (
    <Button
      {...props}
      type="button"
      size="narrow"
      theme="none"
      title={`Click to ${hidden ? "show" : "hide"}`}
    >
      <div className="flex">
        <span className="inline-block">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            role="group"
            style={{ width: "24px", height: "24px", overflow: "visible" }}
          >
            <g fill="#67b7dc" style={{ pointerEvents: "none" }}>
              <g
                fill="#ffffff"
                fillOpacity="0"
                strokeOpacity="0"
                stroke="#010101"
              >
                <rect width="23" height="23"></rect>
              </g>
              <g>
                <g
                  fill="#67b7dc"
                  fillOpacity="0"
                  stroke={hidden ? "#bbb" : color}
                  strokeOpacity="1"
                  strokeWidth="3"
                  transform="translate(0,11.5)"
                >
                  <line x1="0" y1="0" x2="23" y2="0.00001"></line>
                </g>
                <g
                  fill="#67b7dc"
                  stroke={color}
                  transform="translate(11.5,11.5)"
                >
                  <g>
                    <g stroke="#ffffff" strokeWidth="0" fill={color}>
                      <circle r="3"></circle>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
        <span className={classNames({ "text-gray-400 line-through": hidden })}>
          {children}
        </span>
      </div>
    </Button>
  );
};

/**
 *
 * @param {object} props
 * @param {string} props.color
 * @param {any} props.children
 * @param {boolean} [props.hidden]
 */
const LineButton = ({ color, children, hidden, ...props }) => {
  return (
    <Button
      {...props}
      size="narrow"
      theme="none"
      title={`Click to ${hidden ? "show" : "hide"}`}
    >
      <div className="flex">
        <span className="inline-block">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            role="group"
            style={{ width: "24px", height: "24px", overflow: "visible" }}
          >
            <g fill="#a367dc" style={{ pointerEvents: "none" }}>
              <g
                fill="#ffffff"
                fillOpacity="0"
                strokeOpacity="0"
                stroke={color}
              >
                <rect width="23" height="23"></rect>
              </g>
              <g>
                <g
                  fill="#a367dc"
                  fillOpacity="0"
                  stroke={hidden ? "#bbb" : color}
                  strokeOpacity="1"
                  strokeWidth="3"
                  transform="translate(0,11.5)"
                >
                  <line x1="0" y1="0" x2="23" y2="0.00001"></line>
                </g>
              </g>
            </g>
          </svg>
        </span>
        <span className={classNames({ "text-gray-400 line-through": hidden })}>
          {children}
        </span>
      </div>
    </Button>
  );
};

/**
 *
 * @param {object} props
 * @param {string} props.color
 * @param {any} props.children
 * @param {boolean} [props.hidden]
 */
const DotButton = ({ color, children, hidden, ...props }) => {
  return (
    <Button
      {...props}
      size="narrow"
      theme="none"
      title={`Click to ${hidden ? "show" : "hide"}`}
    >
      <div className="flex">
        <span className="inline-block">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            role="group"
            style={{ width: "24px", height: "24px", overflow: "visible" }}
          >
            <g fill="#6794dc" style={{ pointerEvents: "none" }}>
              <g
                fill="#ffffff"
                fillOpacity="0"
                strokeOpacity="0"
                stroke="#000000"
              >
                <rect width="23" height="23"></rect>
              </g>
              <g>
                <g
                  fill="#6794dc"
                  fillOpacity="0"
                  stroke={color}
                  strokeOpacity="1"
                  strokeWidth="0"
                  transform="translate(0,11.5)"
                >
                  <line x1="0" y1="0" x2="23" y2="0.00001"></line>
                </g>
                <g
                  fill="#6794dc"
                  stroke={color}
                  transform="translate(11.5,11.5)"
                >
                  <g>
                    <g stroke="#ffffff" fill={color} strokeWidth="0">
                      <circle r="3"></circle>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
        <span className={classNames({ "text-gray-400 line-through": hidden })}>
          {children}
        </span>
      </div>
    </Button>
  );
};

/**
 *
 * @param {object} props
 * @param {"dotted-line" | "dot" | "line"} props.type
 * @param {string} props.color
 * @param {any} props.children
 * @param {boolean} [props.hidden]
 */
export const LegendButton = ({ type, color, children, ...props }) => {
  return (
    {
      "dotted-line": () => (
        <DottedLineButton {...props} color={color}>
          {children}
        </DottedLineButton>
      ),
      line: () => (
        <LineButton {...props} color={color}>
          {children}
        </LineButton>
      ),
      dot: () => (
        <DotButton {...props} color={color}>
          {children}
        </DotButton>
      )
    }[type] || (() => null)
  )();
};
