import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { EnergyProgramItem } from "@/program-management/components/EnergyProgramItem";

/**
 * @param {object} props
 * @param {import("@/_services").EnergyProgram[]} props.energyPrograms
 * @param {import("@/_services").EnergyProgram} props.selectedEnergyProgram
 * @param {(energyPRogram: import("@/_services").EnergyProgram) => any} props.onSelectenergyProgram
 * @param {{[key: string]: boolean}} props.existingCustomModels
 * @returns {JSX.Element}
 */
export const EnergyProgramsList = ({
  energyPrograms,
  selectedEnergyProgram,
  onSelectenergyProgram,
  existingCustomModels
}) => {
  const [managedModels, setManagedModels] = useState([]);
  const [automatedModels, setAutomatedModels] = useState([]);

  useEffect(() => {
    if (!energyPrograms.length) return;

    setManagedModels(
      energyPrograms.filter(program => existingCustomModels[program.programId])
    );

    setAutomatedModels(
      energyPrograms.filter(program => !existingCustomModels[program.programId])
    );
  }, [energyPrograms, existingCustomModels]);

  return (
    <div className="">
      {!!managedModels.length && (
        <>
          <div className="text-en-gray-300 mb-2 text-xs">
            Actively Managed Models
          </div>
          <div className="border-b border-en-gray-300 flex flex-col gap-4 pb-4 mb-4">
            {managedModels.map(item => (
              <EnergyProgramItem
                key={item.programId}
                energyProgram={item}
                selected={item.programId === selectedEnergyProgram?.programId}
                onSelect={onSelectenergyProgram}
                hasCustomModel={true}
              />
            ))}
          </div>
        </>
      )}

      {!!automatedModels.length && (
        <div className="flex flex-col gap-4">
          {automatedModels.map(item => (
            <EnergyProgramItem
              key={item.programId}
              energyProgram={item}
              selected={item.programId === selectedEnergyProgram?.programId}
              onSelect={onSelectenergyProgram}
            />
          ))}
        </div>
      )}
    </div>
  );
};

EnergyProgramsList.props = {
  energyPrograms: PropTypes.arrayOf(PropTypes.object),
  selectedEnergyProgram: PropTypes.object,
  onSelectEnergyProgram: PropTypes.func
};
