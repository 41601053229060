import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

/**
 *
 * @param {object} props
 * @param {any} props.title
 * @param {any} [props.children]
 * @param {any} [props.className]
 * @returns
 */
export const InfoPanel = ({ title, children, className }) => {
  const wrapperClass = classNames(
    "bg-white p-1 mb-2 w-full",
    "rounded-md border-solid border-2 border-en-gray-100",
    className
  );

  const titleClass = classNames(
    "flex justify-between items-center",
    "mx-2 py-2 font-bold text-lg"
  );

  return (
    <div className={wrapperClass}>
      {/* title */}
      <div className={titleClass}>{title}</div>
      <hr />
      <div className={classNames("mt-2 mx-2")}>
        <div>{children}</div>
      </div>
    </div>
  );
};

InfoPanel.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.any
};
