import React, { useEffect, useState } from "react";
import { AltLayout, Button, Spinner } from "@enpowered/ui";

const useBaseHref = () => {
  const [baseHref, setBaseHref] = useState("/");

  useEffect(() => {
    const base = document.querySelector("head base");
    const href = base?.getAttribute("href");
    setBaseHref(href || baseHref);
  }, []);

  return baseHref;
};

/**
 *
 * @param {object} props
 * @param {string} [props.apiRoot]
 * @returns {JSX.Element}
 */
export const LoginPage = ({ apiRoot = process.env.REACT_APP_API_ROOT }) => {
  const baseHref = useBaseHref();
  const googleLoginInitURL = `${apiRoot}/auth/oauth/google?redirectUrl=${location.origin}${baseHref}oauth`;
  const [loading, setLoading] = useState(false);

  return (
    <AltLayout
      pageTitle=""
      items={[]}
      userName={""}
      Link={null}
      logout={() => {}}
    >
      <>
        <div className="h-full flex-grow flex flex-col items-center justify-center bg-gray-200">
          <div className="py-16">
            <Button
              as="a"
              className="cursor-pointer"
              onClick={() => setLoading(true)}
              {...{ href: googleLoginInitURL }}
            >
              Login with Google{loading ? <Spinner size={12} /> : null}
            </Button>
          </div>
          <p className="text-center text-gray-500 text-xs">
            A dad once said &quot;With great power comes great
            responsibility&quot;
          </p>
        </div>
      </>
    </AltLayout>
  );
};
