/**
 *
 * @param {string} name
 * @param {string} message
 * @param {any} [info]
 * @returns {Error & { info?: any }}
 */
export const makeError = (name, message, info) => {
  /** @type {Error & { info?: any }} */
  const err = new Error(message);
  err.name = name;
  if (info) {
    err["info"] = info;
  }
  return err;
};
