import { responsiveSizes } from "@/_utils/constants";

import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const modalSizeClass = {
  xs: "max-w-xs",
  sm: "max-w-sm",
  md: "max-w-md",
  lg: "max-w-lg"
};

export const Modal = ({ isOpen, size, children, autoScroll = true }) => {
  const modalClassName = classNames({
    "opacity-0 pointer-events-none": !isOpen,
    "modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-20": true
  });

  const containerClassName = classNames(
    modalSizeClass[size],
    "modal-container bg-white w-11/12  mx-auto my-4 rounded shadow-lg z-50 max-h-full",
    {
      "overflow-y-auto": autoScroll
    }
  );

  return (
    <div className={modalClassName}>
      <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

      <div className={containerClassName}>
        <div className="modal-content py-4 text-left px-6">{children}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(responsiveSizes),
  children: PropTypes.element.isRequired,
  autoScroll: PropTypes.bool
};

export default Modal;
