import { assert } from "../../../common";
import { getMicrofrontendController } from "./controller.util";
import {
  instanceTracker,
  unloadMicrofrontendAssets,
} from "./garbage-collector.util";

/**
 * Registers a microfrontend's scope and module in the window, returning a controller
 *
 * @param {string} scope
 * @param {string} module
 * @param {object} options
 * @param {(containerRef: string|HTMLElement, props: Partial<import("../../../types").MicrofrontendMountProps>) => () => any} [options.mount]
 * @param {(containerRef: string|HTMLElement) => any} [options.unmount]
 * @returns {import("./controller.util").MicrofrontendController}
 */
export const registerMicrofrontendEntry = (
  scope,
  module,
  { mount, unmount } = {}
) => {
  const $ctrl = getMicrofrontendController(scope, module);
  $ctrl.tracker = $ctrl.tracker || instanceTracker($ctrl);
  /** @param {() => any} unmount */
  const runUnmountFn = (unmount) => {
    $ctrl.tracker?.decrement();
    if ($ctrl.tracker?.hasZeroInstances()) {
      unloadMicrofrontendAssets(scope, $ctrl.tracker);
    }
    return unmount();
  };
  /** @param {HTMLElement | string} ref */
  const getHTMLElement = (ref) =>
    ref instanceof HTMLElement ? ref : document.getElementById(ref);
  $ctrl.mount = (containerRef, props) => {
    $ctrl.tracker?.increment();
    const unmount = assert(mount, "mount fn must exist")(containerRef, props);
    const container = getHTMLElement(containerRef);
    const eventBus = props?.eventBus;
    eventBus?.emit("mf:mount", {
      container,
      scope,
      module,
    });
    return () => {
      eventBus?.emit("mf:unmount", {
        container,
        scope,
        module,
      });
      return runUnmountFn(unmount);
    };
  };
  $ctrl.unmount = (containerRef) => {
    return runUnmountFn(() =>
      assert(unmount, "unmount fn must exist")(containerRef)
    );
  };
  return $ctrl;
};
