import { useContext } from "react";

import { canMatchPermissions } from "../components";
import { MicrofrontendContext } from "../contexts";

/**
 * Does the current logged in user, have the right permissions to perform an action?
 *
 * Must be used within the `MicrofrontendContext`
 *
 * @example
 * // to find out if the current user has permissions to create a user
 *
 * const hasPermissions = usePermissions();
 * const canAddUser = hasPermissions(["manage:/users"]);
 * return canAddUser ? <button>Add User</button> : null;
 */
export const usePermissions = () => {
  const { user } = useContext(MicrofrontendContext);
  /**
   * @param {(`read-only:${string}` | `manage:${string}`)[]} permissions
   * @param {string[]} [excludedObjectPaths] because sometimes, you want to enforce /users/:userId but not /users/me
   */
  return (permissions, excludedObjectPaths = []) =>
    user
      ? canMatchPermissions(
          {
            ...user,
            permissions: user?.permissions?.filter(
              (p) =>
                !excludedObjectPaths?.length ||
                !excludedObjectPaths?.some(
                  (excludedObjectPath) => p.objectPath === excludedObjectPath
                )
            ),
          },
          permissions
        )
      : false;
};
