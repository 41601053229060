import { faChevronRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

/**
 *
 * @param {object} props
 * @param {import("./NotificationTemplates").ResponseTemplate} [props.response]
 * @param {(id: string) => void} [props.onDelete]
 * @param {(response: import("./NotificationTemplates").ResponseTemplate) => void} [props.onSelect]
 * @param {boolean} [props.isSelected]
 * @param {boolean} [props.canDelete]
 * @returns {JSX.Element}
 */
export const ResponseItem = ({
  response,
  onDelete,
  onSelect,
  isSelected = false,
  canDelete = true
}) => {
  const onClickDelete = () => {
    if (
      window.confirm(
        `Are you sure that you want to delete the template for ${response.label}?`
      )
    ) {
      onDelete && onDelete(response.id);
    }
  };

  return (
    <div
      className={classNames(
        "border rounded-md p-4 hover:border-gray-800 grid  gap-4 items-center cursor-pointer",
        {
          "border-gray-800": isSelected,
          "grid-cols-[auto_1fr]": canDelete,
          "grid-cols-1": !canDelete
        }
      )}
    >
      {canDelete && (
        <button
          className="w-7 h-7 flex items-center justify-center rounded-full text-en-gray-800 bg-transparent hover:bg-en-gray-800 hover:text-white transition-colors"
          onClick={onClickDelete}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
      )}

      <div
        className="grid grid-cols-[1fr_auto] gap-4 items-center"
        onClick={() => onSelect && onSelect(response)}
      >
        <div>
          <div className="text-xs font-bold">{response.action}</div>
          {response.mode?.label && (
            <div className="text-sm">{response.mode.label}</div>
          )}
        </div>

        <button className="w-7 h-7 flex items-center justify-center rounded-full text-en-gray-800 bg-transparent hover:bg-en-gray-800 hover:text-white transition-colors">
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

ResponseItem.propTypes = {
  response: PropTypes.object,
  onDelete: PropTypes.func
};
