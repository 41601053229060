import invariant from "tiny-invariant";

/**
 * Given a condition/value, ensure it is truthy, else throw an error
 * @template TOptional
 * @param {TOptional} condition
 * @param {string | (() => string)} [message]
 * @returns {NonNullable<TOptional>}
 * @throws {Error}
 */
export const assert = (condition, message) => {
  invariant(
    condition,
    typeof message === "string"
      ? `AssertError: ${message}`
      : message || `AssertError: condition must be truthy`
  );
  /** @type {any} */
  const _condition = condition;
  return _condition;
};
