import React from "react";
import { ConnectionItem } from "./ConnectionItem";
import { DateTime } from "luxon";
import { Input } from "@/_components/ui/input";
import { useParams } from "react-router-dom";

const data = [
  {
    id: "1",
    label: "Label 1",
    organizationName: "Organization 1",
    status: "AUTHORIZED",
    lastPull: DateTime.now()
      .minus({ days: 1 })
      .toISO()
  },
  {
    id: "2",
    label: "A very Looooooooong Looooooooong Looooooooong Looooooooong label",
    organizationName: "Organization 2",
    status: "PENDING",
    lastPull: undefined
  },
  {
    id: "3",
    label: "A very Looooooooong Looooooooong Looooooooong Looooooooong label",
    organizationName: "Organization 2",
    status: "EXPIRED",
    lastPull: DateTime.now()
      .minus({ months: 1 })
      .toISO()
  }
];

export const ConnectionsList = () => {
  const { id } = useParams();
  return (
    <div className="grid grid-rows-[auto+1fr] gap-4 p-4 h-full">
      <div>
        <Input placeholder="Search..." />
      </div>
      <div className="relative">
        <div className="grid grid-cols-1 auto-rows-min gap-2 absolute top-0 left-0 w-full h-full overflow-auto">
          {data.map(item => (
            <ConnectionItem
              key={item.id}
              label={item.label}
              organizationName={item.organizationName}
              status={item.status}
              lastPull={item.lastPull}
              selected={item.id === id}
              to={`/usage-data/${item.id}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
