import { Initials } from "@/users/components/Initials";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

/**
 *
 * @param {object} props
 * @param {any} props.className
 * @param {boolean} props.selected
 * @param {object} props.portalUser
 * @param {(user: { given_name: string, family_name: string, email: string }) => Promise<any>} props.onClick
 * @returns
 */
export const PortalUserListItem = ({
  className,
  portalUser,
  selected,
  onClick = async () => {},
  ...props
}) => {
  const _className = classnames(
    "event flex-none",
    "p-2 border-b-4",
    "hover:border-en-gray-200 hover:bg-en-yellow-400",
    "cursor-pointer",
    className,
    {
      "bg-en-yellow-300 border-en-yellow-700": selected,
      "border-white": !selected
    }
  );
  return (
    <div
      {...props}
      className={_className}
      onClick={() =>
        onClick(/** @type {import("@/_services").UserProfile}*/ (portalUser))
      }
    >
      <div className="flex flex-row">
        <Initials user={portalUser} />
        <div className="flex flex-col items-start">
          <div>
            {portalUser.given_name} {portalUser.family_name}
          </div>
          <div>{portalUser.email}</div>
        </div>
      </div>
    </div>
  );
};

PortalUserListItem.propTypes = {
  className: PropTypes.any,
  portalUser: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func
};
