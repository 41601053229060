import { AltLayout as EnAltLayout } from "@enpowered/ui";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { menuNav } from "../App.routes";
import { UserContext } from "@/_contexts/user";
import { useAbsolutePath, useLogout } from "@/_hooks";

/**
 *
 * @param {object} props
 * @param {string} props.pageTitle
 * @param {any} props.children
 * @returns {JSX.Element}
 */
export const Layout = ({ pageTitle, children }) => {
  const { user } = useContext(UserContext);

  /* const navItems = useSideNavItems({
    user,
    getMicrofrontendManifests: () => getMicrofrontendManifests([])
  }); */
  const navigate = useNavigate();
  const logout = useLogout();
  const getAbsolutePath = useAbsolutePath();

  const isUrlActive = url => {
    return window.location.pathname === getAbsolutePath(url);
  };

  return (
    <EnAltLayout
      pageTitle={pageTitle}
      logout={logout}
      userName={`${user?.firstName || user?.email}`}
      isUrlActive={isUrlActive}
      Link={({ to, children, ...props }) => (
        <a
          href={to}
          onClick={e => {
            e.preventDefault();
            navigate(to);
          }}
          {...props}
        >
          {children}
        </a>
      )}
      // @ts-ignore
      items={menuNav.map(item => ({
        name: item.text,
        url: item.url,
        Icon: item.icon,
        disabled: item.disabled
      }))}
    >
      {children}
    </EnAltLayout>
  );
};
