import React, { useContext } from "react";
import { ErrorBoundary } from "../../common";
import { MicrofrontendContext } from "../../contexts";
import { Microfrontend } from "../Microfrontend";
import { getMicrofrontendSlotsByName } from "../MicrofrontendScreen";

/**
 *
 * @typedef {object} MicrofrontendSlotProps
 * @property {string} name
 * @property {string} [id]
 * @property {(slots: MicrofrontendSlot[]) => MicrofrontendSlot[]} [transform]
 * @property {import("../../common").MicrofrontendErrorFallback<{ slot?: MicrofrontendSlot }>} [Fallback]
 * @property {JSX.Element | ((props: { slot?: MicrofrontendSlot }) => JSX.Element)} [Loading]
 * @property {any} [className]
 */

/**
 * @type {React.FC<MicrofrontendSlotProps>}
 */
export const MicrofrontendSlot = ({
  id,
  name,
  transform,
  Fallback,
  Loading,
  className,
  ...props
}) => {
  const { manifests, user, ...contextProps } = useContext(MicrofrontendContext);

  const slots = getMicrofrontendSlotsByName(manifests, name, {
    user,
  });

  // @ts-ignore
  const transformedSlots = transform(slots);

  return (
    <>
      {transformedSlots.map((slot, index) => (
        <ErrorBoundary
          key={`slot-${slot.scope}-${slot.module}-${id}-${index}`}
          Fallback={
            typeof Fallback === "function"
              ? (errorProps) => (
                  <Fallback slot={slot} {...props} {...errorProps} />
                )
              : Fallback || (() => <></>)
          }
        >
          <Microfrontend
            {...{ manifests, user, ...slot, ...contextProps, ...props }}
            Fallback={
              typeof Fallback === "function"
                ? (errorProps) => (
                    <Fallback slot={slot} {...props} {...errorProps} />
                  )
                : Fallback || (() => <></>)
            }
            Loading={
              typeof Loading === "function"
                ? () => <Loading slot={slot} />
                : Loading || (() => <></>)
            }
            entry={slot.entry}
            module={slot.module}
            scope={slot.scope}
            className={className}
            id={`slot-${slot.scope}-${id}-${index}`}
          />
        </ErrorBoundary>
      ))}
    </>
  );
};

MicrofrontendSlot.defaultProps = {
  transform: (slots) => slots,
  Fallback: () => <div>Sorry, there was an error loading this component</div>,
  Loading: () => <div>...loading...</div>,
};

/**
 * @typedef {import("../../types").MicrofrontendCustomSlotOptions & { [key: string]: any }} MicrofrontendSlot
 */
