import { pathToRegexp } from "path-to-regexp";

/**
 *
 * @param {string} route1
 * @param {string} route2
 * @returns {boolean}
 */
export const canMatchRoute = (route1, route2) => {
  const route1Regex = pathToRegexp(route1.replace(/\/\*+/g, "/:params*"), []);
  const route2Regex = pathToRegexp(route2.replace(/\/\*+/g, "/:params*"), []);
  return route1Regex.test(route2) || route2Regex.test(route1);
};

/**
 *
 * @param {string} permission1
 * @param {string} permission2
 * @returns {boolean}
 */
const canMatchPermission = (permission1, permission2) => {
  const route1Regex = pathToRegexp(
    permission1.replace(/\/\*\*+/g, "/:params*").replace(/\/\*/g, "/:params"),
    []
  );
  const route2Regex = pathToRegexp(
    permission2.replace(/\/\*\*+/g, "/:params*").replace(/\/\*/g, "/:params"),
    []
  );
  return route1Regex.test(permission2) || route2Regex.test(permission1);
};

/**
 *
 * @param {string} [hostA]
 * @param {string} [hostB]
 * @returns {boolean}
 */
export const canMatchHost = (hostA, hostB) => {
  return !hostA || !hostB || hostA === hostB;
};

/**
 *
 * @param {import("../../../types").EnpoweredUser} [user]
 * @param {import("../../../types").MicrofrontendPermission[]} [permissions]
 * @returns {boolean}
 */
export const canMatchPermissions = (user, permissions) => {
  if (!user || !permissions) return false;

  return permissions?.every((permission) => {
    const [action, ...objectPaths] = permission.split(":");
    const objectPath = objectPaths.join(":");
    return user?.permissions?.some((p) => {
      const objectPathsMatching =
        p.objectPath === objectPath ||
        canMatchPermission(p.objectPath, objectPath);
      const actionsMatching =
        p.action === action ||
        (action === "read-only" && p.action === "manage");
      return objectPathsMatching && actionsMatching;
    });
  });
};

/**
 * @template {readonly string[]} TKeys
 * @param {TKeys} keys
 */
export const selectProps = (keys) =>
  /**
   * @template {{ [key in TKeys[number]]: any }} TObj
   * @param {TObj} obj
   * @return {{ [key in TKeys[number]]: TObj extends Record<key, infer TValue> ? TValue : never }}
   */
  (obj) => {
    /** @type {any} */
    const ret = Object.fromEntries(
      Object.entries(obj).filter(
        /** @param {[any, any]} prop */
        ([key]) => keys.includes(key)
      )
    );
    return ret;
  };

/**
 *
 * @param {import("../../../types").MicroFrontendManifest[]} manifests
 * @param {string} name
 * @param {object} [options]
 * @param {string} [options.host]
 * @param {import("../../../types").EnpoweredUser} [options.user]
 * @param {(permission: import("../../../types").MicrofrontendPermission) => import("../../../types").MicrofrontendPermission} [options.transformPermission] use this to replace keywords in permissions such as :companyAccountId or :userId
 * @returns {{ module: string, entry: string, scope: string, [key: string]: any }[]} slots matching the current location url
 */
export const getMicrofrontendSlotsByName = (manifests, name, options) =>
  manifests
    ?.reduce(
      (arr, manifest) => [
        ...arr,
        ...(manifest.slots[name]?.map((slot) => ({
          ...selectProps(["entry", "scope", "module", "auth"])(manifest),
          ...(typeof slot === "string" ? { slot } : slot),
        })) || []),
      ],
      /** @type {any[]} */
      ([])
    )
    .filter(
      /** @param {import("../../../types").MicrofrontendCustomSlotOptions} props */
      ({ host: routeHost }) => canMatchHost(routeHost, options?.host)
    )
    .filter(
      /** @param {import("../../../types").MicrofrontendCustomSlotOptions} props */
      ({ auth }) =>
        !auth?.required ||
        canMatchPermissions(
          options?.user,
          auth?.permissions?.map((p) =>
            typeof options?.transformPermission === "function"
              ? options?.transformPermission(p)
              : p
          ) || []
        )
    );

/**
 *
 * @param {import("../../../types").MicroFrontendManifest[]} manifests
 * @param {string} url
 * @param {object} [options]
 * @param {string} [options.host]
 * @param {import("../../../types").EnpoweredUser} [options.user]
 * @param {(permission: import("../../../types").MicrofrontendPermission) => import("../../../types").MicrofrontendPermission} [options.transformPermission] use this to replace keywords in permissions such as :companyAccountId or :userId
 * @returns {MicrofrontendSlotMatchingRoute[]} slots matching the current location url
 */
export const getMicrofrontendSlotsMatchingLocation = (
  manifests,
  url,
  options
) =>
  manifests
    ?.reduce(
      /** @param {any[]} arr */
      (arr, manifest) =>
        arr.concat(
          manifest.slots.routes?.map((route) => ({
            ...selectProps(["entry", "scope", "module", "auth"])(manifest),
            ...(typeof route === "string" ? { route } : route),
          })) || []
        ),
      []
    )
    .filter(
      /** @param {import("../../../types").MicroFrontendManifest & import("../../../types").MicrofrontendRouteOptions} props */
      ({ route, host: routeHost }) =>
        canMatchRoute(route, url) && canMatchHost(routeHost, options?.host)
    )
    .filter(
      /** @param {import("../../../types").MicrofrontendCustomSlotOptions} props */
      ({ auth }) =>
        !auth?.required ||
        canMatchPermissions(
          options?.user,
          auth?.permissions?.map(
            typeof options?.transformPermission === "function"
              ? options?.transformPermission
              : (p) => p
          ) || []
        )
    );

/**
 * @typedef {object} MicrofrontendSlotMatchingRoute
 * @property {string} entry
 * @property {string} scope
 * @property {string} module
 * @property {string} route
 * @property {string} [host]
 */
