import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "@enpowered/ui";

/**
 *
 * @param {object} props
 * @param {boolean} props.isLoading
 * @param {import("@/_services").NotificationRecipient[]} props.recipients
 * @returns {JSX.Element}
 */
export const NotificationRecipientsListPanel = ({ recipients, isLoading }) => {
  return (
    <div className="bg-white p-3 mb-4 w-full border-solid border-2 rounded-md border-en-gray-100">
      <div className="flex justify-between items-center h-[33.75px]">
        <div>
          <h2 className="font-bold text-lg">Recipients</h2>
        </div>
      </div>
      <div className="grid pt-4 mt-4 border-t-2 border-en-gray-500">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {recipients?.map(recipient => (
              <NotificationRecipientItem
                key={recipient.recipientId}
                recipient={recipient}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

/**
 *
 * @param {object} props
 * @param {import("@/_services").NotificationRecipient} props.recipient
 * @returns {JSX.Element}
 */
export const NotificationRecipientItem = ({ recipient }) => {
  return (
    <div className="py-1 border-b mb-1">
      {!!recipient.name && <div className="font-bold">{recipient.name} </div>}
      <div>
        <span className="font-bold">email: </span>
        {recipient.email}
      </div>
      <div>
        <span className="font-bold">phone: </span>
        {recipient.phoneNumber}
      </div>
    </div>
  );
};

NotificationRecipientsListPanel.propTypes = {
  recipients: PropTypes.array,
  isLoading: PropTypes.bool
};
