/**
 * @typedef {object} MicroFrontendManifest
 * @property {string} scope A value, unique to every Microfrontend, that describes it e.g. "Payments"
 * @property {string} module A file entry point to the default component exposed by the Microfrontend.
 *
 * A Microfrontend can expose one or more components via modules.
 * @property {string} entry URL to a remoteEntry.js file that represents the URL entry point for the Microfrontend
 * @property {{ consumes: string[] } & { [key: string]: MicrofrontendEvent }} events
 * Events are either consumed or exposed by a Microfrontend.
 *
 * The "consumes" prop contains a list of events that are consumed by the current Microfrontend
 * All other props in this object, contain events that are emitted by the current Microfrontend
 * @property {MicrofrontendAuth} auth
 *
 * They facilitate communication among Microfrontends.
 * @property {{ routes?: MicrofrontendRoute[] } & { [name: string]: MicrofrontendCustomSlot[] }} slots
 * @property {string} createdOn
 * @property {string} manifestId
 */

/**
 * @typedef {object} MicrofrontendRouteOptions
 * @property {string} route A pattern that describes a route covered by the Microfrontend.
 * https://npmjs.com/package/path-to-regexp
 * e.g.
 * - "payments/*"
 * - "payments/:paymentId/receipts"
 * @property {string} [module] When given, this module is used just for this route
 * @property {string} [host] This field can help a Microfrontend to target a specific host e.g. "internal-portal", helping with routing conflicts.
 * @property {MicrofrontendAuth} [auth]
 *
 * @typedef {string | MicrofrontendRouteOptions} MicrofrontendRoute
 */

/**
 * @typedef {object} MicrofrontendCustomSlotOptions
 * @property {string} [title] A human-readable name for this slot
 * @property {string} module Exposes the component that fills this slot
 * @property {string} [host] Helps to target a specific host for this slot
 * @property {boolean} [defines] Whether or not, the current Microfrontend defines the slot (is the host for the Slot)
 * @property {MicrofrontendAuth} [auth]
 *
 * @typedef {string | (MicrofrontendCustomSlotOptions & { [key: string]: any })} MicrofrontendCustomSlot
 */

/**
 * @typedef {object} MicrofrontendEventOptions
 * @property {(string|object)[]} examples Documentation for the Event
 *
 * @typedef {MicrofrontendEventOptions & { [key:string]: any }} MicrofrontendEvent
 */

/**
 * @typedef {object} MicrofrontendAuthOptions Authentication and Authorization options for Microfrontends
 * @property {boolean} [required] Whether or not the Microfrontend demands that the user be authenticated
 * @property {MicrofrontendPermission[]} [permissions] A list of permissions that User must have to load this Microfrontend
 *
 * Permissions populating this list should follow Least Privilege Access principle
 *
 * @typedef {`${"read-only"|"manage"}:${string}`} MicrofrontendPermission
 * @typedef {MicrofrontendAuthOptions & { [key:string]: any }} MicrofrontendAuth
 */

/**
 * @typedef {object} MicrofrontendMountProps
 * @property {string} homedir
 * @property {(href: string) => any} navigate
 * @property {MicroFrontendManifest[]} manifests
 * @property {(input: RequestInfo, init?: RequestInit) => Promise<Response>} fetch
 * @property {EnpoweredUser} [user]
 * @property {MicrofrontendEventBus} [eventBus]
 * @property {MicrofrontendLayout} layout
 * @property {(endpoint: string) => <TMessage>(message: TMessage) => Promise<any>} [rpcClient]
 * @property {(props: { children: any }) => JSX.Element} ErrorBoundary with a Fallback component, given by the chassis
 */

/**
 * @typedef {object} MicrofrontendEventBus
 * @property {(event: string, ...args: any) => any} emit
 * @property {(event: string, fn: (...args: any) => any) => any} on
 * @property {(event: string, fn?: (...args: any) => any) => any} off
 */

/**
 * @typedef {object} MicrofrontendNavItem
 * @property {string} text
 * @property {string | JSX.Element | (() => JSX.Element)} [icon]
 * @property {string} url
 * @property {boolean} [disabled]
 * @property {string} [type]
 * @property {string} [className]
 * @property {MicrofrontendNavItem[]} [children]
 */

/**
 * @typedef {object} MicrofrontendLayout
 * @property {MicrofrontendNavItem[]} navItems
 * @property {() => any} [logout]
 */

/**
 * @template {string} TScope
 * @template {string} TModule
 * @typedef {{ [scope in TScope]: { [module in TModule]: import("../components/Microfrontend/utils/controller.util").MicrofrontendController } }} MicrofrontendWindowRecord
 */

/**
 * @typedef {object} EnpoweredUser
 * @property {string} id
 * @property {string} email
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} phoneNumber
 * @property {{ [notificationChannel: string]: any }} notificationPreferences
 * @property {EnpoweredOrganization} organization
 * @property {EnpoweredOrganization[]} organizations
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {EnpoweredUserPermission[]} permissions
 */

/**
 * @typedef {object} EnpoweredUserPermission
 * @property {"read-only"|"manage"} action
 * @property {string} objectPath
 */

/**
 * @typedef {object} EnpoweredOrganization
 * @property {string} id
 * @property {string} name
 * @property {string} role
 * @property {{ locationSlug: string, locationType: string, label: string }[]} [locations]
 * @property {{ [service: string]: EnpoweredServiceSubscription }} [serviceSubscriptions]
 * @property {EnpoweredAddress} [billingAddress]
 * @property {EnpoweredAddress} [businessAddress]
 */

/**
 * @typedef {object} EnpoweredServiceSubscription
 * @property {string} serviceCode
 * @property {string} activeSince
 * @property {boolean} willExpire
 * @property {string} [dateWillExpire]
 */

/**
 * @typedef {object} EnpoweredAddress
 * @property {string} addressLine
 * @property {string} areaCode
 * @property {string} city
 * @property {string} country
 * @property {string} state
 */

export const __types__ = {};
