import { authedFetch } from "@/_utils/authedFetch";

const apiRoot = process.env.REACT_APP_API_ROOT;

/**
 *
 * @param {import("./energy-program-operations.service").MarketDataset} marketDataset
 * @param {string} startDate
 * @param {string} endDate
 * @param {object} deps
 * @param {import("@/_services").FetchFunction} [deps.fetch]
 * @returns {Promise<import("@/_services").ProgramMeasure[]>}
 */
export const getInternalProgramMeasures = (
  marketDataset,
  startDate,
  endDate,
  { fetch = authedFetch } = {}
) =>
  marketDataset
    ? Promise.resolve(marketDataset)
        .then(
          dataset =>
            dataset.internal?.datastreams?.map(datastream => datastream.uri) ||
            []
        )
        .then(dataSteamUris =>
          Promise.all(
            dataSteamUris.map(stream =>
              fetch(
                `${stream.replace(
                  "https://apis.getenpowered.com",
                  apiRoot
                )}/data?startDate=${encodeURI(startDate)}&endDate=${encodeURI(
                  endDate
                )}`
              ).then(res => res.json())
            )
          )
        )
        .then(([{ data }]) => data)
    : Promise.resolve();

/**
 *
 * @param {{ data: { external: { datastreams: { uri: string }[] } } }} energyProgram
 * @param {string} startDate
 * @param {string} endDate
 * @param {object} deps
 * @param {import("@/_services").FetchFunction} [deps.fetch]
 * @returns {Promise<import("@/_services").ProgramMeasure[]>}
 */
export const getExternalProgramMeasures = (
  energyProgram,
  startDate,
  endDate,
  { fetch = authedFetch } = {}
) =>
  energyProgram
    ? Promise.resolve(energyProgram)
        .then(
          program =>
            program?.data?.external?.datastreams?.map(
              datastream => datastream.uri
            ) || []
        )
        .then(dataSteamUris =>
          Promise.all(
            dataSteamUris.map(stream =>
              fetch(
                `${stream.replace(
                  "https://apis.getenpowered.com",
                  apiRoot
                )}/data?startDate=${encodeURI(startDate)}&endDate=${encodeURI(
                  endDate
                )}`
              ).then(res => res.json())
            )
          )
        )
        .then(([{ data }]) => data)
    : Promise.resolve();
