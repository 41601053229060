import { PROGRAM_STATUSES } from "@/_utils/energy-programs.utils";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

/** @param {'MAYBE' | 'NO_CALL' | 'CALLED' | 'CANCELLED'} status */
const getProgramStatusColor = status => PROGRAM_STATUSES[status]?.color;

/**
 *
 * @param {object} props
 * @param {'MAYBE' | 'NO_CALL' | 'CALLED' | 'CANCELLED'} props.status
 * @returns {JSX.Element}
 */
export const EventStatus = ({ status }) => {
  const statusClass = classnames(
    {
      "text-white":
        ["bg-green-700", "bg-en-gray-500", "bg-en-gray-800"].indexOf(
          getProgramStatusColor(status)
        ) > -1
    },
    getProgramStatusColor(status),
    "py-2 px-4 rounded font-bold text-xs whitespace-nowrap text-center"
  );
  return <span className={statusClass}>{PROGRAM_STATUSES[status]?.text}</span>;
};

EventStatus.propTypes = { status: PropTypes.string };
