import React, { useEffect, useState } from "react";
import { SearchInput, Spinner } from "@enpowered/ui";

import { Layout } from "@/_components/Layout";
import { useExistingCustomModels, useQueryEnergyPrograms } from "@/_hooks";
import { EnergyProgramsList } from "./components/EnergyProgramsList";
import { EnergyProgramDetail } from "./components/EnergyProgramDetail";

/**
 * @returns {JSX.Element}
 */
// eslint-disable-next-line no-unused-vars
export const ProgramManagementPage = () => {
  const [selectedEnergyProgram, setSelectedEnergyProgram] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredEnergyPrograms, setFilteredEnergyPrograms] = useState([]);

  const {
    data: { items: energyPrograms } = { items: [] },
    isLoading: isEnergyProgramsLoading
  } = useQueryEnergyPrograms({ isActive: true, itemsPerPage: 100 });

  const {
    data: existingCustomModels = {},
    isLoading: isExistingCustomModelsLoading,
    refetch: refetchExistingCustomModels
  } = useExistingCustomModels();

  const isLoading = isEnergyProgramsLoading || isExistingCustomModelsLoading;

  useEffect(() => {
    if (isLoading) return;

    if (searchText === "") {
      setFilteredEnergyPrograms(
        energyPrograms.filter(
          energyProgram =>
            energyProgram.programType === "COINCIDENT_PEAK" &&
            !energyProgram.nickname.endsWith("TEST")
        )
      );
      return;
    }

    setFilteredEnergyPrograms(
      energyPrograms.filter(
        energyProgram =>
          energyProgram.programType === "COINCIDENT_PEAK" &&
          `${energyProgram.programAdministrator} - ${energyProgram.nickname}`
            .toLocaleLowerCase()
            .indexOf(searchText.toLocaleLowerCase()) > -1
      )
    );
  }, [searchText, energyPrograms, isLoading]);

  return (
    <Layout pageTitle="Program Management">
      <div className="w-full h-full px-20 py-4">
        <div className="flex justify-between items-center">
          <h1 className="font-bold text-3xl">Program Management</h1>
        </div>

        <div
          className="bg-white flex justify-start items-start mt-4 shadow-md rounded"
          style={{ height: "calc(100% - 5rem)" }}
        >
          <div
            className="h-full flex-shrink-0 pt-4 grid grid-rows-4 gap-4 border-en-gray-100 border-r-2 max-h-full overflow-hidden"
            style={{ width: 300, gridTemplateRows: "auto 1fr" }}
          >
            <div className="pr-4 pl-2 flex-shrink-0">
              <SearchInput
                // @ts-ignore
                onChange={e => setSearchText(e.target.value)}
                value={searchText}
                name="search"
                placeholder="Search for program"
                ref={null}
              />
            </div>
            <div className="h-full flex-shrink-0 px-4 pb-4 flex gap-4 flex-col overflow-y-auto max-h-full custom-scroller">
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <EnergyProgramsList
                  key={searchText}
                  energyPrograms={filteredEnergyPrograms}
                  existingCustomModels={existingCustomModels}
                  selectedEnergyProgram={selectedEnergyProgram}
                  onSelectenergyProgram={setSelectedEnergyProgram}
                />
              )}
            </div>
          </div>
          <div className="flex-grow flex flex-col h-full max-h-full">
            {!selectedEnergyProgram && (
              <div className="text-center text-xl text-en-gray-300 font-bold mt-8 mx-8">
                Click a program on the left to show the details here
              </div>
            )}

            {selectedEnergyProgram && (
              <EnergyProgramDetail
                key={selectedEnergyProgram.programId}
                energyProgram={selectedEnergyProgram}
                hasCustomModelData={
                  existingCustomModels[selectedEnergyProgram.programId]
                }
                onSaveSuccess={() => {
                  refetchExistingCustomModels();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};
