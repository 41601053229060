import React from "react";
import { ErrorBoundary } from "../common";

/** @type {import("../types").MicrofrontendMountProps} */
const defaults = {
  homedir: "/",

  navigate: () => {},

  manifests: [],

  fetch: (input, init) => window.fetch(input, init),

  user: undefined,

  eventBus: undefined,

  layout: {
    navItems: [],
    logout: () => {},
  },

  ErrorBoundary: ({ children }) => (
    <ErrorBoundary Fallback={() => <>An Error occurred</>}>{children}</ErrorBoundary>
  ),
};

export const MicrofrontendContext = React.createContext(defaults);
