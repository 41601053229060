import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Spinner } from "@enpowered/ui";

import { useEnumerateOrgProfiles } from "@/_hooks";
import { Layout } from "@/_components/Layout";
import { EditDiscardPromptModal } from "@/organizations/components/EditDiscardPromptModal";
import { SearchInput } from "@/_components/SearchInput";
import AccountListItem from "@/organizations/components/AccountListItem";
import { AddAccountModal } from "@/organizations/components/AddAccountModal";
import { ActionButton } from "@/_components/ActionButton";
import { AccountInfoPanel } from "@/organizations/components/AccountInfoPanel";
import { NotificationRecipientsPanel } from "@/organizations/components/NotificationRecipientsPanel";
import { UsersListPanel } from "@/organizations/components/UsersListPanel";
import { SitesListPanel } from "@/sites/components/SitesListPanel";

/**
 *
 * @returns {JSX.Element}
 */
export const OrganizationsPage = () => {
  const { companyAccountId } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [activeAccount, setActiveAccount] = useState(
    /** @type {import("@/_services").OrgProfile}*/ (null)
  );
  const [modal, setModal] = useState(null);

  const isModalOpen = type => modal === type || modal?.type === type;

  const {
    data: { items: orgProfiles } = { items: [] },
    isLoading
  } = useEnumerateOrgProfiles({ itemsPerPage: 1000 });

  useEffect(() => {
    if (activeAccount) {
      const _activeAccount = orgProfiles.find(
        account => account.orgProfileId === activeAccount.orgProfileId
      );
      if (_activeAccount) {
        setActiveAccount(_activeAccount);
      }
    }
  }, [orgProfiles]);

  useEffect(() => {
    if (!isLoading) {
      const _activeAccount = orgProfiles.find(
        account => account.orgProfileId === companyAccountId
      );
      setActiveAccount(_activeAccount);
    }
  }, [companyAccountId, isLoading]);

  const filteredAccounts = orgProfiles
    .filter(({ name }) =>
      name?.toLowerCase().includes(searchTerm?.toLowerCase())
    )
    .sort((a, b) => (a?.name || "").localeCompare(b?.name));

  const isInEditMode = modal === "EditDiscardPromptModal";

  const completeEditDiscardPrompt = () => {
    setModal(null);
  };

  return (
    <Layout pageTitle="Accounts">
      <>
        <AddAccountModal
          isOpen={isModalOpen("AddAccountModal")}
          onClose={() => setModal(null)}
        />
        <EditDiscardPromptModal
          isOpen={isModalOpen("EditDiscardPromptModal")}
          onContinue={completeEditDiscardPrompt}
          onDiscard={completeEditDiscardPrompt}
          message="Navigating away from this customer will discard your changes! Are you sure you want to continue?"
        />
        <div className="h-full flex flex-col overflow-y-hide">
          <div className="w-full flex flex-row justify-between">
            <div className="flex flex-col mt-4 mb-4 ml-2 mr-auto">
              <SearchInput
                placeholder="Search for an account"
                value={searchTerm}
                onChange={setSearchTerm}
              />
            </div>
            <div className="flex items-center mr-2 py-4">
              <ActionButton
                text="Add Account"
                onClick={() => setModal("AddAccountModal")}
              />
            </div>
          </div>
          <div className="max-h-full min-h-0 p-2 pt-0 items-start grid grid-cols-[300px_1fr]">
            <div className="max-h-full flex-shrink-0 bg-transparent overflow-y-auto rounded custom-scroller">
              {isLoading ? (
                <Spinner size={15} />
              ) : (
                filteredAccounts.map(account => (
                  <AccountListItem
                    isActive={
                      account?.orgProfileId === activeAccount?.orgProfileId
                    }
                    key={account.orgProfileId}
                    account={account}
                    onClick={account => {
                      if (account && account !== activeAccount) {
                        if (isInEditMode) {
                          setModal("EditDiscardPromptModal");
                        } else {
                          setActiveAccount(account);
                          navigate(`/organizations/${account.orgProfileId}`);
                        }
                      }
                    }}
                  />
                ))
              )}
            </div>

            {activeAccount && (
              <div
                className="grid grid-cols-[1fr_1fr] items-start h-full w-full ml-1 pr-1 pb-1 bg-transparent overflow-auto custom-scroller"
                style={{ minHeight: "calc(100vh - 122px)" }}
              >
                <div className="flex flex-col ml-4 flex-shrink-0 items-center">
                  <AccountInfoPanel
                    account={activeAccount}
                    onSubmit={
                      async organization => {
                        console.log(organization);
                        return organization;
                      }
                      // account =>
                      //   updateEnpoweredAccount(account?.orgProfileId, account)
                      // }
                    }
                  />
                  <SitesListPanel account={activeAccount} />
                  <NotificationRecipientsPanel
                    orgProfileId={activeAccount.orgProfileId}
                  />
                </div>
                <div className="flex flex-col ml-4 flex-shrink-0 items-center">
                  <UsersListPanel accountId={activeAccount.orgProfileId} />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </Layout>
  );
};
