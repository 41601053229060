import { DateTime } from "luxon";

export const getHighestEnpoweredPeak = (
  enpoweredPeakEstimate,
  administratorPeakEstimate
) => {
  if (!enpoweredPeakEstimate) {
    return administratorPeakEstimate ? administratorPeakEstimate.value : null;
  } else if (enpoweredPeakEstimate.value) {
    return enpoweredPeakEstimate.value;
  } else {
    const modelName = Object.keys(enpoweredPeakEstimate)
      .filter(key => key !== "datetime")
      .reduce((maxPeakModel, currentModel) => {
        const maxVal = Math.max(
          enpoweredPeakEstimate[maxPeakModel].maxHour.value,
          enpoweredPeakEstimate[currentModel].maxHour.value
        );
        return maxVal === enpoweredPeakEstimate[currentModel].maxHour.value
          ? currentModel
          : maxPeakModel;
      });
    return {
      ...enpoweredPeakEstimate[modelName],
      name: modelName
    };
  }
};

export const groupDataObjByTime = dataObj => {
  const group = {};
  Object.entries(dataObj).map(([dataKey, data]) => {
    if (data) {
      return data.map(data => {
        const datetime = DateTime.fromISO(data?.datetime, {
          setZone: true
        });
        const dateString = datetime.toFormat("yyyy-MM-dd HH:mm:ss");
        group[dateString] = {
          ...group[dateString],
          [dataKey]: data?.value,
          datetime: datetime.toISO()
        };
      });
    }
  });
  return Object.values(group).sort((a, b) =>
    a.datetime > b.datetime ? 1 : -1
  );
};

/**
 *
 * @param {array} data
 * @param {string[]} streamKeys
 * @param {number} hashIndex
 * @returns {any[]}
 */
const _getArrayCPMeasure = (data, streamKeys, hashIndex) => {
  const objectToBeGrouped = {};
  streamKeys.forEach(streamKey => {
    const measureName = streamKey.split("#")[hashIndex];
    objectToBeGrouped[measureName] = _getCPMeasure(data, streamKey);
  });

  return groupDataObjByTime(objectToBeGrouped);
};

/**
 *
 * @param {array} data
 * @param {string} streamKey
 * @returns {any[]}
 */
const _getCPMeasure = (data, streamKey) => {
  return (data || [])
    .filter(streamItem => streamItem[streamKey])
    .map(filteredItem => ({
      datetime: filteredItem.timestamp,
      value: filteredItem[streamKey]
    }));
};

/**
 *
 * @param {array} data
 * @param {import("@/_services").MarketDataset} marketDataset
 * @param {string} measure
 * @param {number} hashIndex
 * @returns
 */
export const getInternalCPMeasure = (
  data,
  marketDataset,
  measure,
  hashIndex = 1
) =>
  Array.isArray(marketDataset?.internal.cpMeasures[measure])
    ? _getArrayCPMeasure(
        data,
        /** @type {string[]}*/ (marketDataset?.internal.cpMeasures[measure]),
        hashIndex
      )
    : _getCPMeasure(
        data,
        /** @type {string}*/ (marketDataset?.internal.cpMeasures[measure])
      );

/**
 *
 * @param {array} data
 * @param {{ data: { external: { cpMeasures: { [key: string]: any } } } }} energyProgram
 * @param {string} measure
 * @param {number} hashIndex
 * @returns
 */
export const getExternalCPMeasure = (
  data,
  energyProgram,
  measure,
  hashIndex = 1
) =>
  Array.isArray(energyProgram.data.external.cpMeasures[measure])
    ? _getArrayCPMeasure(
        data,
        energyProgram.data.external.cpMeasures[measure],
        hashIndex
      )
    : _getCPMeasure(data, energyProgram.data.external.cpMeasures[measure]);

/**
 *
 * @param {{ maxHour: { datetime: string } }} estimatedPeak
 * @param {{ timezone: string }} energyProgram
 * @returns {{readablePeakWindow: string, readablePeakHour: string, peakHour: DateTime & { invalid?: boolean } }}
 */
export const getDailyPeakWindow = (estimatedPeak, energyProgram) => {
  /** @type {DateTime & { invalid?: boolean }} */
  const peakHour = DateTime.fromISO(estimatedPeak?.maxHour?.datetime).setZone(
    energyProgram?.timezone
  );

  const readablePeakWindow = !peakHour.invalid
    ? `${peakHour
        .minus({ hour: 1 })
        .toFormat(
          `h${[0, 10, 11, 12, 22, 23].includes(peakHour.hour) ? "a" : ""}`
        )} - ${peakHour.plus({ hour: 2 }).toFormat("h a")}`
    : "";

  const readablePeakHour = !peakHour.invalid
    ? `${peakHour.toFormat(
        `h${[11, 23].includes(peakHour.hour) ? "a" : ""}`
      )} - ${peakHour.plus({ hour: 1 }).toFormat("h a")}`
    : "";

  return {
    readablePeakWindow,
    readablePeakHour,
    peakHour
  };
};

/**
 *
 * @param {import("@/_services").MarketDataset} marketDataset
 * @param {array} programData
 * @param {object} deps
 * @param {(data: any[], marketDataset: import("@/_services").MarketDataset, measure: string, hashIndex?: number) => any[]} [deps.getCPMeasure]
 * @returns
 */
export const getCPProgramInfo = (
  marketDataset,
  programData,
  { getCPMeasure = getInternalCPMeasure } = {}
) => {
  if (marketDataset && programData) {
    const demand = getCPMeasure(programData, marketDataset, "demand");

    const demandAverage = getCPMeasure(
      programData,
      marketDataset,
      "demandAverage"
    );

    const administratorPredictions = getCPMeasure(
      programData,
      marketDataset,
      "administratorPredictions"
    );

    const enpoweredPredictions = getCPMeasure(
      programData,
      marketDataset,
      "enpoweredPredictions"
    );

    const enpoweredModels = marketDataset?.internal?.models.reduce(
      (predictionSetNames, model) => {
        predictionSetNames[model.predictionSetName] = getCPMeasure(
          programData,
          marketDataset,
          model.predictionSetName
        );
        return predictionSetNames;
      },
      {}
    );

    const groupedData = groupDataObjByTime({
      demand,
      demandAverage,
      administratorPredictions,
      enpoweredPredictions,
      ...enpoweredModels
    });

    const enpoweredPeakEstimate = getCPMeasure(
      programData,
      marketDataset,
      "enpoweredPeakEstimate",
      1
    );

    const administratorPeakEstimate = getCPMeasure(
      programData,
      marketDataset,
      "administratorPeakEstimate"
    );

    const estimatedPeak = getHighestEnpoweredPeak(
      enpoweredPeakEstimate[0],
      administratorPeakEstimate[0]
    );

    return {
      demand,
      demandAverage,
      administratorPredictions,
      enpoweredPredictions,
      enpoweredModels,
      groupedData,
      enpoweredPeakEstimate,
      administratorPeakEstimate,
      estimatedPeak
    };
  }

  return {
    demand: [],
    demandAverage: [],
    administratorPredictions: [],
    enpoweredPredictions: [],
    enpoweredModels: {},
    groupedData: [],
    enpoweredPeakEstimate: [],
    administratorPeakEstimate: [],
    estimatedPeak: {}
  };
};
