import { AutocompleteInput, Button, TextInput } from "@enpowered/ui";
import classNames from "classnames/dedupe";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PROVINCES, US_STATES } from "@/_utils/constants";
import Modal from "@/_components/Modal";

const validationSchema = yup.object({
  name: yup.string().required("Site name is required."),
  ownerId: yup.string().required(),
  location: yup.object({
    streetAddress: yup.string().required("Address is required."),
    unit: yup.string(),
    city: yup.string().required("City is required."),
    state: yup
      .object()
      .nullable()
      .required("Province / State is required."),
    country: yup
      .object()
      .nullable()
      .required("Country is required."),
    postalCode: yup.string().required("Zip / Postal code is required.")
  })
});

const countries = ["Canada", "United States"].map(country => ({
  label: country
}));

/**
 *
 * @param {object} props
 * @param {import("@/_services").Site} props.site
 * @param {(site: import("@/_services").Site) => void} props.onSubmit
 * @param {() => void} props.onCancel
 * @param {any} props.error
 * @param {boolean} props.isLoading
 * @param {object} props.account
 * @returns
 */
export const SiteForm = ({
  site,
  onSubmit,
  onCancel,
  error,
  isLoading,
  account
}) => {
  const [country, setCountry] = useState("");
  const [states, setStates] = useState([]);

  const fieldClass = classNames(
    "sm:mb-0 appearance-none rounded w-full text-gray-700 leading-tight",
    {
      "mb-2": true
    }
  );

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: site?.siteInfo
      ? {
          ...site.siteInfo,
          location: {
            ...site.siteInfo.location,
            state: { label: site.siteInfo.location?.state || "" },
            country: { label: site.siteInfo.location?.country || "" }
          }
        }
      : {
          name: "",
          ownerId: account.orgProfileId,
          location: {
            streetAddress: "",
            unit: "",
            city: "",
            state: null,
            country: null,
            postalCode: ""
          }
        }
  });

  const handleCancel = e => {
    e.preventDefault();
    onCancel && onCancel();
  };

  const submitChanges = ({ name, ownerId, location }) => {
    const params = {
      siteId: site?.siteId,
      siteInfo: {
        name,
        ownerId,
        location: {
          ...location,
          country: location.country.label,
          state: location.state.label
        }
      }
    };

    onSubmit(params);
  };

  useEffect(() => {
    setStates(
      (country === "Canada"
        ? PROVINCES
        : country === "United States"
        ? US_STATES
        : []
      ).map(state => ({ label: state.label }))
    );
  }, [country]);

  return (
    <div>
      <h2 className="text-xl font-bold">{site ? "Edit Site" : "Add a Site"}</h2>
      <form onSubmit={handleSubmit(submitChanges)}>
        <div className="px-4">
          <div className="mt-4">
            <p className="text-lg font-bold">Site Name</p>
            <TextInput
              className={fieldClass}
              name="name"
              // @ts-ignore
              type="text"
              placeholder="Site Name"
              valid={!errors?.name}
              {...register("name")}
            />
            {!!errors?.name && (
              <span className="text-xs text-en-red">
                {errors?.name.message}
              </span>
            )}
          </div>
          <input
            type="hidden"
            name="ownerId"
            value={account.orgProfileId}
            {...register("ownerId")}
          />
          <p className="text-lg font-bold mt-4">Address</p>
          <div
            className="grid gap-2"
            style={{
              gridTemplateRows: "repeat(4, auto)"
            }}
          >
            <div>
              <TextInput
                className={fieldClass}
                name="location.streetAddress"
                // @ts-ignore
                type="text"
                placeholder="Address"
                valid={!errors?.location?.streetAddress}
                {...register("location.streetAddress")}
              />
              {!!errors?.location?.streetAddress && (
                <span className="text-xs text-en-red">
                  {errors?.location?.streetAddress.message}
                </span>
              )}
            </div>

            <div className="flex justify-btween gap-4">
              <div>
                <TextInput
                  className={fieldClass}
                  name="location.unit"
                  // @ts-ignore
                  type="text"
                  placeholder="Unit"
                  valid={!errors?.location?.unit}
                  {...register("location.unit")}
                />
                {!!errors?.location?.unit && (
                  <span className="text-xs text-en-red">
                    {errors?.location?.unit.message}
                  </span>
                )}
              </div>
              <div>
                <TextInput
                  className={fieldClass}
                  name="location.city"
                  // @ts-ignore
                  type="text"
                  placeholder="City"
                  valid={!errors?.location?.city}
                  {...register("location.city")}
                />
                {!!errors?.location?.city && (
                  <span className="text-xs text-en-red">
                    {errors?.location?.city.message}
                  </span>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <TextInput
                  className={fieldClass}
                  name="location.postalCode"
                  // @ts-ignore
                  type="text"
                  placeholder="Zip/Postal Code"
                  valid={!errors?.location?.postalCode}
                  {...register("location.postalCode")}
                />
                {!!errors?.location?.postalCode && (
                  <span className="text-xs text-en-red">
                    {errors?.location?.postalCode.message}
                  </span>
                )}
              </div>
              <div className={fieldClass}>
                <Controller
                  name="location.country"
                  control={control}
                  render={({ field }) => (
                    <AutocompleteInput
                      id="autocomplete-country"
                      name={field.name}
                      placeholder="Country"
                      value={field.value || null}
                      autocompleteData={countries}
                      valid={!errors?.location?.country}
                      onSelected={value => {
                        setValue("location.country", value);
                        setCountry(value.label);
                      }}
                      onBlur={() => {}}
                    />
                  )}
                />

                {!!errors?.location?.country && (
                  <span className="text-xs text-en-red">
                    {errors?.location?.country.message}
                  </span>
                )}
              </div>
            </div>
            <div>
              <div
                className="py-2 sm:mb-0"
                style={{ width: "calc(50% - 0.5rem)" }}
              >
                <Controller
                  name="location.state"
                  control={control}
                  render={({ field }) => (
                    <AutocompleteInput
                      id="autocomplete-state"
                      name={field.name}
                      placeholder={
                        country === "Canada"
                          ? "Province"
                          : country === "United States"
                          ? "State"
                          : "Province / State"
                      }
                      value={field.value || null}
                      autocompleteData={states}
                      valid={!errors?.location?.state}
                      onSelected={value => {
                        setValue("location.state", value);
                      }}
                      onBlur={() => {}}
                    />
                  )}
                />

                {!!errors?.location?.state && (
                  <span className="text-xs text-en-red">
                    {errors?.location?.state.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          {error && (
            <label className="block text-sm py-2 text-center text-red-800">
              {error?.errors?.[0]?.detail}
            </label>
          )}
        </div>
        <div className="flex justify-end gap-4 mt-4">
          <Button
            theme="transparent"
            onClick={handleCancel}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button type="submit" theme="dark" disabled={isLoading}>
            {isLoading ? (
              <span>
                <i className="fa mx-4 fa-circle-notch fa-pulse text-white" />
              </span>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

SiteForm.propTypes = {
  site: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  error: PropTypes.object,
  isLoading: PropTypes.bool,
  account: PropTypes.object.isRequired
};

export const SiteFormModal = ({ isOpen, ...props }) => {
  return (
    <Modal isOpen={isOpen} autoScroll={false}>
      <div>
        {isOpen && (
          // @ts-ignore
          <SiteForm {...props} />
        )}
      </div>
    </Modal>
  );
};

SiteFormModal.propTypes = {
  isOpen: PropTypes.bool,
  ...SiteForm.propTypes
};
