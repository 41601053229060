import React, { useEffect, useState } from "react";
import { usePermissions } from "@enpowered/microfrontends";
import { Button, PhoneInput, TextInputWithValidation } from "@enpowered/ui";
import classNames from "classnames";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Initials } from "@/users/components/Initials";

const defaultPortalUser = {
  given_name: "",
  family_name: "",
  email: "",
  password: "",
  phone_number: "",
  groups: []
};

const validationSchema = isNewUser =>
  Yup.object({
    given_name: Yup.string().required("First name is required."),
    family_name: Yup.string().required("Last name is required."),
    email: Yup.string()
      .email("Invalid email address.")
      .required("Email is required."),
    password: isNewUser && Yup.string().required("Password is required."),
    phone_number: Yup.string()
      .optional()
      .nullable()
      .matches(/^(\+\d{1,3})?\d{8,}$/i, "Unrecognized Phone Number pattern")
  });

export const PortalUserForm = ({
  portalUser,
  onSubmit,
  isNewUser,
  isLoading,
  error,
  closeForm
}) => {
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    // if a use selects a different person from the list we want to set editable to false. (also prob should submit?)
    if (editable) {
      setEditable(false);
    }
  }, [portalUser]);

  const submitChanges = args => {
    onSubmit(args, {
      onSuccess: () => {
        setEditable(false);
        closeForm && closeForm();
      }
    });
  };

  const toggleEdit = () => {
    setEditable(!editable);
  };

  portalUser = portalUser || defaultPortalUser;

  const hasPermissions = usePermissions();
  const canAddUser = hasPermissions(["manage:/users"]);
  const canEditUser = hasPermissions(
    [`manage:/users/${portalUser?.id}`],
    ["/users/me"]
  );

  const isEditable = editable || (isNewUser && canAddUser);

  const fieldClass = classNames(
    "py-2 sm:mb-0 appearance-none rounded w-full text-gray-700 leading-tight",
    {
      "input-disabled-clean": true,
      "border-white": !isEditable
    }
  );

  const handleCancel = e => {
    e.preventDefault();
    setEditable(false);
    closeForm && closeForm();
  };

  const getErrors = errors => {
    // TextInputWithValidation needs taht 'errors' has a 'message' property
    return Object.keys(errors).reduce((output, key) => {
      output[key] = {
        message: errors[key]
      };
      return output;
    }, {});
  };

  return (
    <div className="bg-white pb-4 overflow-y-auto">
      {isNewUser && <h2 className="px-4 pt-4 text-lg font-bold">Add a User</h2>}
      <Formik
        validationSchema={validationSchema(isNewUser)}
        initialValues={portalUser}
        enableReinitialize
        onSubmit={submitChanges}
      >
        {({
          handleSubmit,
          errors,
          touched,
          validateField,
          setFieldValue,
          setFieldTouched,
          values
        }) => {
          return (
            <Form className="px-4 pt-4" onSubmit={handleSubmit}>
              {!isNewUser && (
                <div className="mb-4">
                  <Initials user={portalUser} />
                </div>
              )}
              <div>
                <TextInputWithValidation
                  className={fieldClass}
                  name="given_name"
                  // @ts-ignore
                  type="text"
                  placeholder="First Name"
                  disabled={!isEditable}
                  value={values.given_name}
                  errors={getErrors(errors)}
                  onChange={e => {
                    setFieldTouched("given_name", true);
                    validateField("given_name");
                    setFieldValue("given_name", e.target.value);
                  }}
                />
              </div>

              <div>
                <TextInputWithValidation
                  className={fieldClass}
                  name="family_name"
                  placeholder="Last Name"
                  // @ts-ignore
                  type="text"
                  disabled={!isEditable}
                  errors={getErrors(errors)}
                  value={values.family_name}
                  onChange={e => {
                    setFieldTouched("family_name", true);
                    validateField("family_name");
                    setFieldValue("family_name", e.target.value);
                  }}
                />
              </div>
              <div>
                <TextInputWithValidation
                  className={fieldClass}
                  name="email"
                  // @ts-ignore
                  type="email"
                  placeholder="Email"
                  disabled={!isEditable}
                  value={values.email}
                  errors={getErrors(errors)}
                  onChange={e => {
                    setFieldTouched("email", true);
                    validateField("email");
                    setFieldValue("email", e.target.value);
                  }}
                />
              </div>
              {isNewUser && (
                <div>
                  <TextInputWithValidation
                    className={fieldClass}
                    name="password"
                    // @ts-ignore
                    type="password"
                    placeholder="Password"
                    disabled={!isEditable}
                    value={values.password}
                    errors={getErrors(errors)}
                    onChange={e => {
                      setFieldTouched("password", true);
                      validateField("password");
                      setFieldValue("password", e.target.value);
                    }}
                  />
                </div>
              )}
              <div>
                <PhoneInput
                  className="pt-2 input-disabled-clean"
                  // @ts-ignore
                  errorText={touched.phone_number ? errors.phone_number : ""}
                  onChange={value => {
                    setFieldTouched("phone_number", true);
                    validateField("phone_number");
                    setFieldValue("phone_number", value);
                  }}
                  value={values.phone_number}
                  disabled={!isEditable}
                />
              </div>
              {error && (
                <label className="block text-sm py-2 text-center text-red-800">
                  {error?.errors?.[0]?.detail}
                </label>
              )}

              {canEditUser ? (
                <div className="flex justify-between pt-4">
                  {isEditable ? (
                    <>
                      <div>
                        <Button
                          // @ts-ignore
                          onClick={handleCancel}
                          theme="none"
                          size="narrow"
                          className="bg-white border border-en-gray-500 mr-4"
                          disabled={isLoading}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button
                          type="submit"
                          theme="dark"
                          size="narrow"
                          className="border"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <span>
                              <i className="fa mx-4 fa-circle-notch fa-pulse text-white" />
                            </span>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <Button
                      theme="dark"
                      size="narrow"
                      className="border ml-auto block"
                      // @ts-ignore
                      onClick={toggleEdit}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              ) : null}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

PortalUserForm.propTypes = {
  portalUser: PropTypes.object,
  onSubmit: PropTypes.func,
  isNewUser: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  closeForm: PropTypes.func
};
