import React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { makeError } from "../../common";

import { Microfrontend } from "../Microfrontend";
import { getMicrofrontendSlotsMatchingLocation } from "./utils";

/**
 * Top-level component, that loads Microfrontends,
 * based on the routes declared in their manifest
 *
 * @param {MicrofrontendScreenProps & import("../../types").MicrofrontendMountProps} props
 */
export const MicrofrontendScreen = ({
  host,
  getMicrofrontendManifests,
  Loading,
  Fallback,
  navigate,
  Microfrontend,
  ...props
}) => {
  const location = useLocation();
  const url = location.pathname;
  const { data: manifests, isLoading, isError, error } = useQuery(
    "manifests",
    () => getMicrofrontendManifests()
  );
  const [targetMF] =
    getMicrofrontendSlotsMatchingLocation(manifests || [], url, {
      host,
      user: props?.user,
    }) || [];

  /** @type {import("../../common").MicrofrontendErrorFallback} */
  const ErrorFallback = (errorProps) =>
    typeof Fallback === "function" ? (
      <Fallback {...props} {...errorProps} />
    ) : (
      Fallback
    );

  return isError ? (
    <ErrorFallback
      error={
        error instanceof Error
          ? error
          : makeError(
              "UnknownError",
              typeof error === "string"
                ? error
                : `An error occurred in a microfrontend: ${error}`,
              error
            )
      }
    />
  ) : isLoading ? (
    typeof Loading === "function" ? (
      <Loading />
    ) : (
      Loading
    )
  ) : !targetMF && manifests?.length ? (
    <ErrorFallback
      error={makeError(
        "NoRouteMatchFound",
        `No microfrontend was found capable of handling the route: ${url}`
      )}
    />
  ) : (
    <Microfrontend
      {...props}
      url={url}
      entry={targetMF.entry}
      scope={targetMF.scope}
      module={targetMF.module}
      manifests={manifests}
      navigate={navigate}
      Loading={Loading}
      Fallback={Fallback}
    />
  );
};

MicrofrontendScreen.defaultProps = {
  Microfrontend,
};

/**
 * @typedef {object} MicrofrontendScreenProps
 * @property {string} [host] a name used to filter for slots built specifically for the current chassis e.g. "external-portal" or "internal-portal"
 * @property {() => Promise<import("../../types").MicroFrontendManifest[]>} getMicrofrontendManifests
 * @property {JSX.Element | (() => JSX.Element)} Loading displayed while Microfrontend is loading
 * @property {import("../../common").MicrofrontendErrorFallback} Fallback displayed if Microfrontend could not be loaded
 * @property {Microfrontend} Microfrontend
 */

export * from "./utils";
