import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

export const SmallSVGButton = ({ src, onClick, className, alt, text }) => (
  <button
    className={classNames(
      "border-2 border-solid border-gray-800 rounded-md hover:bg-yellow-400",
      className
    )}
    onClick={onClick}
  >
    <img src={src} alt={alt ? alt : ""} />
    {text && <span>{text}</span>}
  </button>
);

SmallSVGButton.propTypes = {
  src: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  alt: PropTypes.string,
  text: PropTypes.string
};
