import { Autocomplete } from "@/_components/autocomplete";
import { ConfirmationModal } from "@/_components/ConfirmationModal";
import { Input } from "@/_components/ui/input";
import { useEnumerateAllOrgProfiles } from "@/_hooks";
import { Button, HelperText, Modal, Spinner } from "@enpowered/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { TriangleAlert } from "lucide-react";
import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

const schema = yup.object().shape({
  organizationName: yup.string().required("Organization is required"),
  organizationId: yup.string(),
  label: yup.string(),
  utilityId: yup.string(),
  utilityName: yup.string(),
  historicalUsage: yup
    .number()
    .min(0)
    .required("Historical usage is required"),
  ongoingUsage: yup
    .number()
    .min(0)
    .required("Ongoing usage is required"),
  historicalBills: yup
    .number()
    .min(0)
    .required("Historical bills is required"),
  ongoingBills: yup
    .number()
    .min(0)
    .required("Ongoing bills is required"),
  sendEmail: yup.boolean(),
  email: yup
    .string()
    .email("Email is wrong")
    .when("sendEmail", {
      is: true,
      then: schema => schema.required("Email is required")
    })
});

const utilities = [
  { value: "1", label: "Utility 1" },
  { value: "2", label: "Utility 2" },
  { value: "3", label: "Utility 3" },
  { value: "4", label: "Utility 4" },
  { value: "5", label: "Utility 5" },
  { value: "6", label: "Utility 6" }
];

const modal = {
  NONE: "none",
  ORGANIZATION: "organization",
  EMAIL: "email",
  LINK: "link",
  SAVED: "saved"
};

export const NewConnectionPage = () => {
  const [formData, setFormData] = useState(/** @type {any}*/ ({}));
  const [showModal, setShowModal] = useState(modal.NONE);
  const navigate = useNavigate();

  const {
    data: orgProfilesData = [],
    isLoading: isEnumerateOrgProfilesLoading
  } = useEnumerateAllOrgProfiles();

  const orgProfiles = useMemo(
    () =>
      orgProfilesData.map(({ orgProfileId, name }) => ({
        value: orgProfileId,
        label: name
      })),
    [orgProfilesData]
  );

  const isLoading = isEnumerateOrgProfilesLoading;

  const {
    handleSubmit,
    control,
    setValue,
    register,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      organizationId: undefined,
      historicalUsage: 1,
      ongoingUsage: 10,
      historicalBills: 1,
      ongoingBills: 10,
      sendEmail: false
    }
  });

  const onSubmit = data => {
    setFormData(data);
    if (!data.organizationId) {
      setShowModal(modal.ORGANIZATION);
    } else if (data.sendEmail) {
      setShowModal(modal.EMAIL);
    } else {
      sendData(data);
    }
  };

  const sendData = (data = formData) => {
    if (!data.sendEmail) {
      setShowModal(modal.LINK);
    } else {
      setShowModal(modal.SAVED);
    }
  };

  const resetForm = () => {
    navigate("/usage-data");
  };

  return (
    <div className="px-4 pb-4">
      <ConfirmationModal
        title="Unknown organization"
        text={`${formData.organizationName} is not a known organization. Do you want to continue?`}
        isOpen={showModal === modal.ORGANIZATION}
        onClose={() => {
          setShowModal(modal.NONE);
        }}
        onConfirm={() => {
          setShowModal(modal.NONE);
          if (formData.linkOrEmail === "email") {
            setShowModal(modal.EMAIL);
          } else {
            sendData();
          }
        }}
        confirmButtonText="Yes"
        cancelButtonText="No"
      />

      <ConfirmationModal
        title="Send email"
        text={`Are you sure you want to send a connection request to ${watch(
          "email"
        )}?`}
        isOpen={showModal === modal.EMAIL}
        onClose={() => {
          setShowModal(modal.NONE);
        }}
        onConfirm={() => {
          setShowModal(modal.NONE);
          sendData();
        }}
        cancelButtonText="Cancel"
        confirmButtonText="Send"
      />

      <ConfirmationModal
        title="Email has been sent"
        text={`A connection request has been sent to ${watch("email")}`}
        isOpen={showModal === modal.SAVED}
        onClose={() => {
          resetForm();
          setShowModal(modal.NONE);
        }}
        onConfirm={() => {
          resetForm();
          setShowModal(modal.NONE);
        }}
        confirmButtonText="OK"
      />

      <Modal
        isOpen={showModal === modal.LINK}
        title="Generated link"
        size="md"
        className="[&_.modal-container]:w-[500px]"
        onClose={() => {
          resetForm();
          setShowModal(modal.NONE);
        }}
      >
        <div className="flex flex-col gap-4">
          <p>Send this link to your contact</p>
          <Input
            placeholder="Link"
            disabled={true}
            value="https://portal.getenpwoered.com/autorize?id=123"
          />
          <div>
            <Button
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(
                  "https://portal.getenpwoered.com/autorize?id=123"
                );
              }}
            >
              Copy link
            </Button>
          </div>
        </div>
      </Modal>

      <div className="bg-white shadow-md rounded p-4">
        <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
          <h1 className="font-bold text-2xl sticky top-0 bg-white z-10">
            New Connection
          </h1>
          {isLoading ? (
            <div className="h-full w-full flex justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <div className="grid gap-4">
              <div className="grid gap-4">
                <label>Organization</label>
                <div>
                  <Controller
                    name="organizationName"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        items={
                          !field.value
                            ? orgProfiles
                            : orgProfiles.filter(
                                ({ label }) =>
                                  label
                                    .toLowerCase()
                                    .indexOf(field.value.toLowerCase()) > -1
                              )
                        }
                        selectedValue={watch("organizationId")}
                        placeholder="Organization Name"
                        onSelectedValueChange={value => {
                          setValue("organizationId", value);
                        }}
                        searchValue={field.value}
                        onSearchValueChange={(value, withSelectedItem) => {
                          if (!withSelectedItem) {
                            setValue("organizationId", undefined);
                          }
                          field.onChange({ target: { value } });
                        }}
                        resetOnBlur={false}
                      />
                    )}
                  />
                  {!watch("organizationId") && !!watch("organizationName") && (
                    <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                      <TriangleAlert className="h-4 w-4" />
                      <span>This organization does not exist</span>
                    </span>
                  )}
                  <HelperText valid={false}>
                    {errors.organizationName?.message}
                  </HelperText>
                </div>
              </div>

              <div className="grid gap-4">
                <label>Label</label>
                <div>
                  <Input placeholder="Label" {...register("label")} />
                  <HelperText valid={false}>{errors.label?.message}</HelperText>
                </div>
              </div>

              <div className="grid gap-4">
                <label>Utility</label>
                <div>
                  <Controller
                    name="utilityId"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        items={
                          !field.value
                            ? utilities
                            : utilities.filter(
                                ({ label }) =>
                                  label
                                    .toLowerCase()
                                    .indexOf(field.value.toLowerCase()) > -1
                              )
                        }
                        selectedValue={field.value}
                        placeholder="Utility Name"
                        onSelectedValueChange={value => {
                          setValue("utilityId", value);
                        }}
                        searchValue={field.value}
                        onSearchValueChange={value => {
                          field.onChange({ target: { value } });
                        }}
                      />
                    )}
                  />
                  <HelperText valid={false}>
                    {errors.utilityId?.message}
                  </HelperText>
                </div>
              </div>

              <div className="font-semibold text-xl">Scope</div>
              <div className="grid grid-cols-[auto_1fr_auto_1fr] gap-4 items-start">
                <label className="leading-[33.75px]">Historical Usage</label>
                <div className="w-full">
                  <div className="grid grid-cols-[100px_1fr] items-center gap-2">
                    <Input
                      type="number"
                      {...register("historicalUsage")}
                      // @ts-ignore
                      min={0}
                    />
                    Years
                  </div>
                  {watch("historicalUsage") == 0 && (
                    <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                      <TriangleAlert className="h-4 w-4" />
                      <span>Historical usage will not be collected</span>
                    </span>
                  )}
                  {watch("historicalUsage") > 2 && (
                    <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                      <TriangleAlert className="h-4 w-4" />
                      <span>
                        Some utilities may not provide this much historical
                        usage data
                      </span>
                    </span>
                  )}
                  <HelperText valid={false}>
                    {errors.historicalUsage?.message}
                  </HelperText>
                </div>

                <label className="leading-[33.75px]">Ongoing Usage</label>
                <div className="w-full">
                  <div className="grid grid-cols-[100px_1fr] items-center gap-2">
                    <Input
                      type="number"
                      {...register("ongoingUsage")}
                      // @ts-ignore
                      min={0}
                    />
                    Years
                  </div>
                  {watch("ongoingUsage") == 0 && (
                    <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                      <TriangleAlert className="h-4 w-4" />
                      <span>
                        Usage data will not be pulled on an ongoing basis
                      </span>
                    </span>
                  )}
                  <HelperText valid={false}>
                    {errors.ongoingUsage?.message}
                  </HelperText>
                </div>

                <label className="leading-[33.75px]">Historical Bills</label>
                <div className="w-full">
                  <div className="grid grid-cols-[100px_1fr] items-center gap-2">
                    <Input
                      type="number"
                      {...register("historicalBills")}
                      // @ts-ignore
                      min={0}
                    />
                    Years
                  </div>
                  {watch("historicalBills") == 0 && (
                    <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                      <TriangleAlert className="h-4 w-4" />
                      <span>Historical bills will not be collected</span>
                    </span>
                  )}
                  {watch("historicalBills") > 2 && (
                    <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                      <TriangleAlert className="h-4 w-4" />
                      <span>
                        Some utilities may not provide this much historical
                        billing data
                      </span>
                    </span>
                  )}
                  <HelperText valid={false}>
                    {errors.historicalBills?.message}
                  </HelperText>
                </div>

                <label className="leading-[33.75px]">Ongoing Bills</label>
                <div className="w-full">
                  <div className="grid grid-cols-[100px_1fr] items-center gap-2">
                    <Input
                      type="number"
                      {...register("ongoingBills")}
                      // @ts-ignore
                      min={0}
                    />
                    Years
                  </div>
                  {watch("ongoingBills") == 0 && (
                    <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                      <TriangleAlert className="h-4 w-4" />
                      <span>
                        Billing data will not be pulled on an ongoing basis
                      </span>
                    </span>
                  )}
                  <HelperText valid={false}>
                    {errors.ongoingBills?.message}
                  </HelperText>
                </div>
              </div>

              <label className="flex justify-start items-center gap-2">
                <input type="checkbox" {...register("sendEmail")} /> Send email
              </label>

              {watch("sendEmail") && (
                <div className="grid gap-4">
                  <label>Contact&apos;s email</label>
                  <div>
                    <Input
                      type="email"
                      placeholder="Email"
                      {...register("email")}
                    />
                    <HelperText valid={false}>
                      {errors.email?.message}
                    </HelperText>
                  </div>
                </div>
              )}

              <div className="flex justify-end items-center gap-4">
                <Button size="standard" theme="transparent" type="button">
                  Cancel
                </Button>
                <Button size="standard" theme="dark" type="submit">
                  Initiate connection
                </Button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
